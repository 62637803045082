import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import Section from '../components/Section';
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { useState } from 'react';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PasswordChangePanel } from '../components/PasswordChangePanel';
import { TextField } from '@fluentui/react/lib/TextField';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { useAdministrationAPI } from '../AdministrationAPI';

interface Properties {
    processError: (error: any) => void;
}

export default function SecurityPanel(properties: Properties) {
    const { t } = useTranslation();
    const { updateUser } = useAdministrationAPI();
    const [authentication, setAuthentication] = useAtom(authenticationAtom) as any;
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [mfaDialogOpen, setMFADialogOpen] = useState(false);
    const [password, setPassword] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);
    const [mobileNumber, setMobileNumber] = useState();
    const [editedMobileNumber, setEditedMobileNumber] = useState();

    const togglePasswordDialogOpen = () => {
        setPasswordDialogOpen(!passwordDialogOpen);
    }
    const toggleMFADialogOpen = () => {
        setEditedMobileNumber(authentication.mobileNumber);
        setMFADialogOpen(!mfaDialogOpen);
    }
    const isPhoneNumberValid = (): boolean => {
        return isPossiblePhoneNumber(editedMobileNumber || '');
    }

    return <>
        <div className="displayFlex flexDirectionColumn gapL width600">
            <Section title={t('settingsPanel.securityPanel.password')}>
                <div className='displayFlex justifyContentEnd marginTopL'>
                    <DefaultButton text={t('global.change')} onClick={togglePasswordDialogOpen} />
                </div>
            </Section>
            <Section title={t('settingsPanel.securityPanel.mfa')}>
                <div className='displayFlex justifyContentEnd marginTopL'>
                    <DefaultButton text={t('global.change')} onClick={toggleMFADialogOpen} />
                </div>
                <Text className="fontWeightBold" variant='medium'>{t('settingsPanel.securityPanel.mobileNumber')}</Text>
                <Text variant='medium'>{authentication.mobileNumber}</Text>
            </Section>
        </div>
        <Dialog hidden={!passwordDialogOpen}
            onDismiss={togglePasswordDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.securityPanel.changePassword'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <PasswordChangePanel setPassword={setPassword} setPasswordValid={setPasswordValid}></PasswordChangePanel>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.change')} disabled={!isPasswordValid} onClick={() => {
                    togglePasswordDialogOpen();
                }} />
                <DefaultButton onClick={togglePasswordDialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={!mfaDialogOpen}
            onDismiss={toggleMFADialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.securityPanel.changeMobileNumber'),
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <TextField label={t('settingsPanel.securityPanel.mobileNumber')} value={editedMobileNumber} onChange={(e: any, value: any) => setEditedMobileNumber(value)}
                errorMessage={isPhoneNumberValid() ? undefined : t('settingsPanel.securityPanel.mobileNumberNotValid')}
            />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!isPhoneNumberValid()} text={t('global.change')} onClick={async () => {
                    try {
                        const newAuthentication = { ...authentication, mobileNumber: editedMobileNumber };

                        await updateUser(newAuthentication.customer, newAuthentication);

                        setAuthentication(newAuthentication);
                        toggleMFADialogOpen();
                    } catch (error) {
                        properties.processError(error);
                    }
                }} />
                <DefaultButton onClick={toggleMFADialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}