import React, { useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import L, { Map } from 'leaflet'
import { Marker, useMap } from 'react-leaflet'
import { PieChart } from 'react-minimal-pie-chart'

interface Properties {
    superclusterRef: { [key: string]: any; } | undefined;
    options: any;
    data: any;
}

const iconPieChart = (item: any, options: any, mapContext: Map) => {
    const getSizePieChart = () => {
        if (mapContext.getZoom() <= 10) {
            return 30;
        } else if (mapContext.getZoom() <= 18) {
            return 40;
        } else {
            return 30;
        }
    }

    const getDataPieChart = () => {
        return options.colorMapping.map((mapping: any) => {
            return {
                value: item.entries.filter((entry: any) => entry[options.fieldName] === mapping.value).length,
                color: mapping.color,
            }
        });
    }

    return L.divIcon({
        className: 'marker-pie-chart',
        html: ReactDOMServer.renderToString(
            <div style={{
                width: (getSizePieChart() + 8) * 2,
                height: (getSizePieChart() + 8) * 2,
                transform: 'translate(-50%, -50%)'
            }}>
                <PieChart
                    data={getDataPieChart()}
                    radius={getSizePieChart()}
                    center={[getSizePieChart() + 8, getSizePieChart() + 8]}
                    viewBoxSize={[(getSizePieChart() + 8) * 2, (getSizePieChart() + 8) * 2]}
                    lineWidth={70}
                    label={({ dataEntry }) => dataEntry.value}
                    labelPosition={100 - 70 / 2}
                    labelStyle={(index) => ({
                        fill: 'white',
                        fontSize: '16px',
                        fontFamily: 'Lato',
                    })}
                />
            </div>
        )
    })
}

const clusterByLatLon = (points: any) => {
    // TODO Move to cluster

    return Object.values(points.reduce((map: any, point: any) => {
        const key = `${point.lat}-${point.lon}`;

        map[key] = map[key] || { lat: point.lat, lon: point.lon, entries: [] };

        map[key].entries.push(point);

        return map;
    }, {}));
}

const RenderMarkerSuperCluster = (properties: Properties) => {
    const mapContext = useMap();
    const [groups] = useState(clusterByLatLon(properties.data))

    return (
        <>
            {groups && groups.map((item: any, index: number) => {
                if (mapContext.getZoom() >= 12/*item.properties.cluster*/) {
                    return (
                        <Marker
                            key={index}
                            position={[item.lat, item.lon]}
                            icon={iconPieChart(item, properties.options, mapContext)}
                        />
                    )
                } else {
                    return (
                        <Marker
                            key={index}
                            position={[item.lat, item.lon]}
                            icon={L.icon({
                                iconUrl: 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png',
                                iconSize: [38, 40],
                            })}
                        />
                    )
                }
            })}
        </>
    )
}

export default React.memo(RenderMarkerSuperCluster)