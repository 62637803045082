import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useCallback, useRef, useState } from "react";
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { makeStyles } from '@fluentui/react-components';
import { useLocation, useNavigate } from "react-router-dom";
import { ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { useTranslation } from "react-i18next";
import { Overlay } from "@fluentui/react";
import i18next from "i18next";
import { userAPI } from "../UserAPI";
import { configuration } from "../Configuration";

interface Properties {
  showHome?: boolean,
  showSidePanel?: boolean,
  showMapItem?: boolean,
  showTabs?: boolean,
  toggleSidePanel?: any,
  title?: string,
  selectTab?: any,
  selectedTab?: string,
}

const useStyles = makeStyles({
  settings: {
    marginBottom: '10px',
  },
  flex: {
    display: 'flex',
  },
  container: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  end: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
    marginLeft: '20px',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
  },
});

export enum Tabs {
  product = 'product',
  company = 'company',
  security = 'security',
}

function Header(properties: Properties) {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [showCallout, setShowCallout] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [locale, setLocale] = useState(i18next.resolvedLanguage);
  const [showLocalePopup, setShowLocalePopup] = useState(false);
  const onHideContextualMenu = useCallback(() => setShowCallout(false), []);
  const linkRef = useRef(null);
  const [authentication, setAuthentication] = useAtom(authenticationAtom) as any;

  const menuItems: IContextualMenuItem[] = [{
    key: 'settings',
    iconProps: { iconName: 'Settings' },
    text: 'Settings',
    onClick: () => navigate('/settings'),
  }];

  if (authentication && authentication.customer === '4712') {
    menuItems.push({
      key: 'settings',
      iconProps: { iconName: 'Repair' },
      text: 'Administration',
      onClick: () => navigate('/administration'),
    });
  }

  menuItems.push({
    key: 'divider_1',
    itemType: ContextualMenuItemType.Divider,
  });
  menuItems.push({
    key: 'logout',
    iconProps: { iconName: 'Leave' },
    text: 'Log out',
    onClick: () => {
      setAuthentication(null);
      userAPI.logout();
      navigate('/');
    },
  });

  const selectTab = (newTab: Tabs) => {
    if (properties.selectTab) {
      properties.selectTab(newTab);
    }
  }

  const mapLocaleToCountryCode = (locale: string | undefined) => {
    switch (locale) {
      case 'de': return 'de';
      case 'en': return 'gb';
    }
  }

  return <div className="header">
    <div onClick={() => navigate('/')}>
      <img className='logoImage' src="/logo.png" alt="logo"></img>
    </div>
    {properties.showHome
      ? <IconButton iconProps={{ iconName: 'Home' }} title="Home" ariaLabel="Home" onClick={() => navigate("/chatbot")} />
      : <></>}
    {properties.showTabs
      ?
      <div className="flexGrow1 displayFlex justifyContentCenter">
        <div className='headerTabs'>
          <div className={'headerTab' + (properties.selectedTab === Tabs.product ? ' selected' : '')} onClick={() => { selectTab(Tabs.product) }}>
            {t('product')}
          </div>
          <div className={'headerTab' + (properties.selectedTab === Tabs.security ? ' selected' : '')} onClick={() => { selectTab(Tabs.security) }}>
            {t('security')}
          </div>
          <div className={'headerTab' + (properties.selectedTab === Tabs.company ? ' selected' : '')} onClick={() => { selectTab(Tabs.company) }}>
            {t('company')}
          </div>
        </div>
      </div>
      :
      <div className='flexGrow1'></div>
    }
    <div className="hideOnSmall alignItemsCenter gapM">
      <div className="hoverHand">
        <span className={`fi fi-${mapLocaleToCountryCode(locale)}`} onClick={() => { setShowLocalePopup(true) }}></span>
        <div className="positionRelative">
          {showLocalePopup && (
            <>
              <div className={`popupContent ${showLocalePopup ? '' : 'hidden'}`}>
                <div className={`flex hoverHand`} onClick={() => {
                  setShowLocalePopup(false);
                  setLocale('de');
                  i18n.changeLanguage("de");
                }}>
                  <span className="fi fi-de" ></span><span className="marginLeftS">Deutsch</span>
                </div>
                <div className="marginTopS flex hoverHand" onClick={() => {
                  setShowLocalePopup(false)
                  setLocale('en');
                  i18n.changeLanguage("en");
                }}>
                  <span className="fi fi-gb  marginRighS" ></span><span className="marginLeftS">English</span>
                </div>
              </div>
              <div
                className="popupOverlay"
                onClick={() => { setShowLocalePopup(false) }}
              >
                <Overlay />
              </div>
            </>
          )
          }
        </div>
      </div>
      <PrimaryButton text={t('header.login')} onClick={() => navigate('/login')} />
      {configuration.mode === 'test'
        ?
        <PrimaryButton text={t('header.signUp')} onClick={() => navigate('/signup')} />
        : <></>}
    </div>
  </div>
}

export default Header;
