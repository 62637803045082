import './App.sass';
import Header from './Header';
import { ChatBot } from './ChatBot';

export default function ChatBotPage() {
  return (
    <div className="App">
      <Header showSidePanel={true} showMapItem={true}></Header>
      <ChatBot></ChatBot>
    </div>
  );
}
