import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { useState } from "react";
import InfoList from "./InfoList";
import { LegalPerson} from "@marc.gille-sepehri/tri-model";

interface Properties {
  legalPerson?: LegalPerson;
}

export default function LegalPersonProperties(properties: Properties) {
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: 'General',
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    }
  ];


  return (
    <div>
      <div>
        <Text variant="medium">Unternehmen</Text>
      </div>
      <div>
        <Text variant="xxLarge"></Text>
      </div>
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div>
            </div>
          </div>
        }
      })()}
    </div>
  );
}
