import { Area, EntityQuery, Filter, Query } from '@marc.gille-sepehri/tri-model';
import '../../App.sass';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { userAPI } from '../../UserAPI';
import InfoList from '../InfoList';

class Properties {
    object: any;
}

const numberFormat = new Intl.NumberFormat('de');

export default function SocioDemographicsProperties(properties: Properties) {
    const { t } = useTranslation();
    const [ageProfileChartOptions, setAgeProfileChartOptions] = useState() as any;
    const [ageProfileSeries, setAgeProfileSeries] = useState() as any;
    const [area, setArea] = useState() as any;

    useEffect(() => {
        const process = async () => {
            const areas = await userAPI.query(new Query(new EntityQuery(Area.type, [], undefined,
                Filter.latLonContainedInBoundingBoxFilter(0.5 * (properties.object.minLat + properties.object.maxLat),
                    0.5 * (properties.object.minLon + properties.object.maxLon)))));

            if (areas.length > 0) {
                setArea(areas[0])
            }
        }

        process();
    }, [properties]);

    useEffect(() => {
        const call = async () => {
            const ageProfile = [
                {
                    ageLimit: '< 6',
                    count: 477,
                    count2040: 530,
                },
                {
                    ageLimit: '6 - 11',
                    count: 387,
                    count2040: 900,
                },
                {
                    ageLimit: '12-17',
                    count: 332,
                    count2040: 900,
                },
                {
                    ageLimit: '18-24',
                    count: 1187,
                    count2040: 900,
                },
                {
                    ageLimit: '25-34',
                    count: 2454,
                    count2040: 900,
                },
                {
                    ageLimit: '25-44',
                    count: 1353,
                    count2040: 1100,
                },
                {
                    ageLimit: '45-54',
                    count: 930,
                    count2040: 730,
                },
                {
                    ageLimit: '55-64',
                    count: 777,
                    count2040: 733,
                },
                {
                    ageLimit: '64-74',
                    count: 531,
                    count2040: 640,
                },
                {
                    ageLimit: '> 74',
                    count: 412,
                    count2040: 710,
                },
            ];

            console.log(ageProfile);

            setAgeProfileSeries([{
                name: 'Altersprofil',
                data: ageProfile.map((entry: any) => entry.count),
            }, {
                name: 'Prognose 2040',
                data: ageProfile.map((entry: any) => entry.count2040),
            }]);

            setAgeProfileChartOptions({
                chart: {
                    height: 300,
                    type: 'bar'
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#649aaa', '#576071'],
                stroke: {
                    curve: 'smooth',
                    width: [1, 1],
                },
                xaxis: {
                    //type: 'datetime',
                    categories: ageProfile.map((entry: any) => entry.ageLimit)
                },
                yaxis: [{
                    seriesName: 'Altersprofil',
                    min: 0,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00288E'
                    },
                    labels: {
                        style: {
                            colors: '#00288E',
                        },
                        formatter: (val: number) => {
                            return val.toFixed(1)
                        },
                    },
                    title: {
                        div: 'Altersprofil',
                        style: {
                            color: '#00288E',
                        }
                    }
                }, {
                    seriesName: 'Prognose 2040',
                    opposite: true,
                    min: 0,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#576071'
                    },
                    labels: {
                        style: {
                            colors: '#576071',
                        },
                        formatter: (val: number) => {
                            return val.toFixed(1)
                        },
                    },
                    title: {
                        div: 'Prognose 2040',
                        style: {
                            color: '#576071',
                        }
                    }
                }],
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            })
        };

        call();
    }, []);

    return <div>
        <div className="marginTopM headerL">Bevölkerung im Umkreis von 1km²</div>
        <div className="marginTopM">
            <InfoList list={[{
                label: 'Bevölkerung',
                value: area ? numberFormat.format(area.population) : '-',
            },]}></InfoList>
        </div>
        <div className="marginTopM headerL">Altersprofil</div>
        {ageProfileChartOptions && ageProfileSeries && ageProfileSeries.length && ageProfileSeries[0].data.length > 0
            ?
            <Chart options={ageProfileChartOptions} series={ageProfileSeries} type="bar" height="250" />
            :
            <div className='marginTopM'>{t('global.noInformationAvailable')}</div>
        }
        <div className="marginTopS headerL">Bevölkerungsbilanz</div>
        <div className="marginTopM displayFlex alignItemsBaseline gapS">
            <div className="divXXL fontWeightBold colorPrimaryLight">6.800</div><div className="divXL colorPrimaryLight">Wanderungssaldo</div>
        </div>
        <div className="marginTopS displayFlex alignItemsBaseline gapS">
            <div className="divXL fontWeightBold colorPrimary">21.420</div><div className="divL colorPrimary">Geburten</div>
        </div>
        <div className="marginTopS displayFlex alignItemsBaseline gapS">
            <div className="divXL fontWeightBold colorPrimary">90.900</div><div className="divL colorPrimary">Zuzüge</div>
        </div>
        <div className="marginTopS displayFlex alignItemsBaseline gapS">
            <div className="divXL fontWeightBold colorPrimary">84.100</div><div className="divL colorPrimary">Abzüge</div>
        </div>
    </div >;
}