import React from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

const useStyles = makeStyles({
  avatar: {
    width: '40px',
    height: '40px',
    ...shorthands.borderRadius('50%'),
    backgroundColor: 'transparent',
  },
  avatarImage: {
    width: '100%',
  }
});

const iconClass = mergeStyles({
  fontSize: 30,
  height: 30,
  width: 30,
  color: 'lightgrey'
});

export const BotAvatar = () => {
  const styles = useStyles();

  return (
    <FontIcon aria-label="Lightbulb" iconName="Lightbulb" className={iconClass} />
    // <div className="react-chatbot-kit-chat-bot-avatar">
    //   <div className="react-chatbot-kit-chat-bot-avatar-container">
    //     <div className={styles.avatar}>
    //       <img src="./icons/logo.png" alt="avatar" className={styles.avatarImage} />
    //     </div>
    //   </div>
    // </div>
  );
};