import "./css/editor.sass"
import { useEffect, useRef, useState } from 'react'
import paper, { view, Tool, Path, Raster, Point } from 'paper';
import ImageUploading from 'react-images-uploading';
import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { userAPI } from "../UserAPI";
import { useAdministrationAPI } from "../AdministrationAPI";
import { Asset, getConvexPolygonArea, Space } from "@marc.gille-sepehri/tri-model";
import { useDrop } from "react-dnd";
import { getAssetIcon } from "../components/Icon";

interface Properties {
    floor: any;
    floorChanged?: (floor: any) => void
    spaces?: any[],
    spacesChanged?: () => void,
    assets?: any[];
    assetsChanged?: () => void,
    selectedSpaceIndex?: any;
    selectedSpaceIndexChanged?: (space: any) => void;
}

const scaleFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export function FloorplanEditor(properties: Properties) {
    const { t } = useTranslation();
    const canvasRef = useRef() as any;
    const distanceInputRef = useRef() as any;
    const [images, setImages] = useState([]);
    const { updateFloor, updateFloorplanImage, updateAsset, updateSpace } = useAdministrationAPI();
    const panZoomRef = useRef({
        imageOffset: new Point(0, 0),
        pan: null as any,
        zoom: 1,
    });
    const [floor, setFloor] = useState([]) as any;
    const [spaces, setSpaces] = useState([]) as any;
    const [selectedSpaceIndex, setSelectedSpaceIndex] = useState([]) as any;
    const [assets, setAssets] = useState([]) as any;
    const [scaling, setScaling] = useState(false) as any;
    const [scalingDialogOpen, setScalingDialogOpen] = useState(false) as any;
    const [scalingMeasurement, setScalingMeasurement] = useState(1) as any;
    const [scalingDistance, setScalingDistance] = useState(1) as any;

    let distancePath: any = null;
    let distanceText: any = null;

    const toBuildingCoordinates = (point: any, imageScale: number, imageXOffset: number, imageYOffset: number) => {
        return { x: (point.x - imageXOffset) / imageScale * 1000, y: (point.y - imageYOffset) / imageScale * 1000 };
    };

    const toProjectCoordinates = (point: any, imageScale: number, imageXOffset: number, imageYOffset: number) => {
        return { x: point.x / 1000 * imageScale + imageXOffset, y: point.y / 1000 * imageScale - imageYOffset };
    };

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'AssetDnD',
        drop: async (item: any, monitor: any) => {
            if (distancePath) {
                distancePath.remove();
                distanceText.remove();
            }

            console.log('Client offset >>>', monitor.getClientOffset());

            const dropPosition = monitor.getClientOffset();
            const canvasBoundingBox = canvasRef.current.getBoundingClientRect();

            dropPosition.x -= canvasBoundingBox.x;
            dropPosition.y -= canvasBoundingBox.y;

            console.log('Drop position on canvas >>>', monitor.getClientOffset());

            const assetLocation = toBuildingCoordinates(paper.view.viewToProject(dropPosition), floor.floorplanImageScale, panZoomRef.current.imageOffset.x, panZoomRef.current.imageOffset.y)

            console.log('Asset located at >>>', assetLocation);

            const asset = assets.find((asset: any) => asset._id === item._id);

            // (Re)position asset

            if (asset) {
                const index = assets.indexOf(asset);
                const newAssets = [...assets];
                const newAsset = { ...asset, ...assetLocation, floor: properties.floor._id };

                newAssets[index] = newAsset;

                setAssets(newAssets);

                await updateAsset(newAsset)
            }
        },
        hover: (item: any, monitor: any) => {
        },
        collect: (monitor: any) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [assets])

    const onChange = async (imageList: any, addUpdateIndex: any) => {
        try {
            console.log(imageList, addUpdateIndex);

            const formData = new FormData();

            formData.append("floorplanImage", imageList[0].file);

            console.log('Image >>>', imageList[0].file);

            await updateFloorplanImage(properties.floor, formData);

            // console.log('Result >>>', result);

            if (properties.floorChanged) {
                properties.floorChanged({ ...properties.floor, floorplanImageURI: imageList[0].file.name });
            }

            setImages([]);
        } catch (error: any) {
            //properties.processError(error);
        }
    };

    const panZoom = (event: any) => {
        var newZoom = paper.view.zoom;
        var oldZoom = paper.view.zoom;

        // const factorIn = 1.05;
        // const factorOut = 0.95;
        const factorIn = 1.01;
        const factorOut = 0.99;

        if (event.deltaY > 0) {
            newZoom = paper.view.zoom * factorIn;
        } else {
            newZoom = paper.view.zoom * factorOut;
        }

        var beta = oldZoom / newZoom;

        var mousePosition = new paper.Point(event.offsetX, event.offsetY);

        //viewToProject: gives the coordinates in the Project space from the Screen Coordinates
        var viewPosition = paper.view.viewToProject(mousePosition);

        var mpos = viewPosition;
        var ctr = paper.view.center;

        var pc = mpos.subtract(ctr);
        var offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

        paper.view.zoom = newZoom;
        paper.view.center = paper.view.center.add(offset);

        panZoomRef.current.zoom = newZoom;
        panZoomRef.current.pan = paper.view.center;

        console.log('Save pan/zoom >>>', panZoomRef.current.pan.x, panZoomRef.current.pan.y, panZoomRef.current.zoom);

        //event.preventDefault();

        // @ts-ignore
        paper.view.draw();
    };

    useEffect(() => {
        setFloor({ ...properties.floor, floorplanImageScale: properties.floor.floorplanImageScale || 1 });
    }, [properties.floor]);

    useEffect(() => {
        if (properties.spaces) {
            setSpaces(properties.spaces)
        } else {

            setSpaces([]);
        }
    }, [properties.spaces]);

    useEffect(() => {
        setSelectedSpaceIndex(properties.selectedSpaceIndex)
    }, [properties.selectedSpaceIndex]);

    useEffect(() => {
        if (properties.assets) {
            setAssets(properties.assets)
        } else {

            setAssets([]);
        }
    }, [properties.assets]);

    useEffect(() => {
        if (!canvasRef || !canvasRef.current || !floor || !floor.floorplanImageWidth || !floor.floorplanImageHeight) {
            return;
        }

        //paper.install(window);
        paper.setup('editorCanvas');

        // Draw floorplan

        const raster = new paper.Raster('floorplan');

        raster.position = paper.view.center;

        raster.scale(1);

        panZoomRef.current.imageOffset = new Point(raster.bounds.left, raster.bounds.top);

        // Restore pan/zoom state

        console.log('Restore pan/zoom >>>', panZoomRef.current.pan, panZoomRef.current.zoom);

        if (panZoomRef.current.pan) {
            paper.view.center = panZoomRef.current.pan;
            paper.view.zoom = panZoomRef.current.zoom;
        }

        // Draw origin

        const originSize = 20 * panZoomRef.current.zoom;

        let originPath = new paper.Path.Line(new Point(raster.bounds.left - originSize / 2, raster.bounds.top), new Point(new Point(raster.bounds.left + originSize / 2, raster.bounds.top)));

        // @ts-ignore
        originPath.strokeColor = 'green';

        originPath = new paper.Path.Line(new Point(raster.bounds.left, raster.bounds.top - originSize / 2), new Point(new Point(raster.bounds.left, raster.bounds.top + originSize / 2)));

        // @ts-ignore
        originPath.strokeColor = 'green';

        // Draw spaces

        spaces.forEach((space: Space, index: number) => {
            if (index === selectedSpaceIndex) {
                return;
            }

            if (space.shape.length > 2) {
                const path = new paper.Path();
                const projectShape = space.shape.map((point: any) => toProjectCoordinates(point, floor.floorplanImageScale, raster.bounds.left, raster.bounds.top));

                // @ts-ignore
                path.strokeColor = space.color || 'rgba(173, 164, 217, 0.3)';
                // @ts-ignore
                path.fillColor = space.color || 'rgba(173, 164, 217, 0.1)';

                (projectShape || []).forEach((point: any) => path.add(new Point(point.x, point.y)));

                path.add(new Point(projectShape[0].x, projectShape[0].y));

                // Add interaction

                path.onClick = function (event: any) {
                    this.selected = !this.selected;

                    return false;
                }
            }
        });

        for (const asset of assets) {
            if (asset.y === undefined || asset.y === undefined) {
                continue;
            }

            const point = toProjectCoordinates(new Point(asset.x, asset.y), floor.floorplanImageScale, raster.bounds.left, raster.bounds.top)

            const icon: any = getAssetIcon(asset.icon || 'fire-extinguisher');
            
            paper.project.importSVG(`svgs/${icon.id}.svg`, function (item: any) {
                item.position = new paper.Point(point.x, point.y)
            });

            new paper.PointText({
                point: [point.x - 3 * asset.name.length, point.y + 25],
                content: asset.name,
                fillColor: icon.color,
                fontFamily: 'Lato',
                fontSize: 14
            });
        }

        const tool = new Tool();

        if (spaces && selectedSpaceIndex >= 0) {
            let path: any = null;
            const points: any = [];

            // @ts-ignore
            tool.onMouseDown = function (event: any) {
                const point = {
                    x: event.point.x,
                    y: event.point.y
                };

                if (points.length === 0) {
                    path = new Path();

                    //path.fillColor = 'rgba(255,0,0, 0.3)';
                    //path.strokeColor = 'red';
                    path.fillColor = 'rgba(173, 164, 217, 0.1)'
                    path.strokeColor = 'rgba(173, 164, 217, 0.3)';

                    points.push(point)
                    path.add(point);
                } else {
                    if (Math.sqrt(Math.pow(points[0].x - point.x, 2) + Math.pow(points[0].y - point.y, 2)) < 10) {
                        path.add(points[0]);

                        path = null;

                        const buildingPoints: any = points.map((point: any) => toBuildingCoordinates(point, floor.floorplanImageScale, raster.bounds.left, raster.bounds.top));

                        const newSpace = { ...spaces[selectedSpaceIndex], area: getConvexPolygonArea(buildingPoints) / 1000000, shape: buildingPoints }

                        updateSpace(newSpace).then(() => {
                            const newSpaces = [...spaces]

                            newSpaces[selectedSpaceIndex] = newSpace;

                            setSpaces(newSpaces);
                            setSelectedSpaceIndex(-1);

                            if (properties.spacesChanged) {
                                properties.spacesChanged();
                            }

                            if (properties.floorChanged) {
                                properties.floorChanged({ ...properties.floor, spaces: newSpaces });
                            }

                            if (properties.selectedSpaceIndexChanged) {
                                properties.selectedSpaceIndexChanged(-1);
                            }
                        });
                    } else {
                        path.add(point);
                        points.push(point)
                    }
                }
            };

            tool.onMouseMove = function (event: any) {
                if (!path || points.length === 0) {
                    return;
                }

                path.remove();
                path = new Path();

                path.fillColor = 'rgba(255,0,0, 0.3)';
                path.strokeColor = 'red';

                points.forEach((point: any) => path.add(point));

                const point = {
                    x: event.point.x,
                    y: event.point.y
                };

                path.add(point);
            };
        } else if (scaling) {
            let path: any = null;
            let startPoint: any = null;

            tool.onMouseDown = function (event: any) {
                console.log('Scaling start >>>', event.point);

                if (!startPoint) {
                    path = new Path();

                    path.strokeColor = 'red';

                    startPoint = {
                        x: event.point.x,
                        y: event.point.y
                    };

                    path.add(startPoint);
                    path.add(startPoint);
                } else {
                    if (!path) {
                        return;
                    }

                    path.remove();
                    setScalingMeasurement(Math.sqrt(Math.pow(startPoint.x - event.point.x, 2) + Math.pow(startPoint.y - event.point.y, 2)));
                    setScalingDialogOpen(true);
                    setScaling(false);
                    distanceInputRef.current?.focus();
                }
            }

            tool.onMouseMove = function (event: any) {
                console.log('Scaling move >>>', event.point);

                if (!path) {
                    return;
                }

                path.remove();

                path = new Path();
                path.strokeColor = 'red';

                path.add(startPoint);
                path.add({
                    x: event.point.x,
                    y: event.point.y
                });
            }
        } else {
            // Pan handler

            tool.onMouseDrag = function (event: any) {
                const panOffset = event.point.subtract(event.downPoint);

                paper.view.center = paper.view.center.subtract(panOffset);
            }

            tool.onMouseUp = function (event: any) {
                const panOffset = event.point.subtract(event.downPoint);

                paper.view.center = paper.view.center.subtract(panOffset);

                panZoomRef.current.pan = paper.view.center;

                console.log('Save pan/zoom >>>', panZoomRef.current.pan.x, panZoomRef.current.pan.y, panZoomRef.current.zoom);
            }
        }

        return () => {
            // Cleanup

            tool.remove();
        }
    }, [floor, assets, scaling, spaces, selectedSpaceIndex, properties, updateSpace]);

    return <div>
        {floor && floor.floorplanImageURI && floor.floorplanImageWidth && floor.floorplanImageHeight
            ?
            <div className="drawingArea" style={{ cursor: selectedSpaceIndex >= 0 || scaling ? 'crosshair' : 'pointer' }}>
                <div className="marginBottomXS displayFlex alignItemsCenter">
                    <div className='height20 flexGrow1 displayFlex colorContrast'>{selectedSpaceIndex >= 0 ? 'Wähle die Eckpunkte des Raums aus.' : (scaling ? 'Wähle Distanz zur Skalierung aus.' : '')}</div>
                    <div><span className="fontWeightBold">Scale: </span>{scaleFormat.format(floor.floorplanImageScale)} px/m</div>
                    <div><ActionButton iconProps={{ iconName: 'Design' }}
                        onClick={async () => setScaling(true)}></ActionButton></div>
                </div>
                <div ref={drop} className="width800 height600 canvasContainer">
                    <canvas id="editorCanvas" ref={canvasRef} onWheel={panZoom} width={floor.floorplanImageWidth} height={floor.floorplanImageHeight}></canvas>
                </div>
                <img style={{ display: 'none' }} id="floorplan" src='floorplan.png' alt=""></img>
            </div>
            :
            <div className="marginTopL displayFlex flexDirectionColumn">
                <ImageUploading
                    value={images}
                    onChange={onChange}
                    maxNumber={1}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                    }) => (
                        <div className="">
                            <div className="imageArea" style={isDragging ? { border: '1px solid red' } : undefined}
                                {...dragProps}>
                                {imageList.length === 0
                                    ?
                                    (
                                        properties.floor.floorplanImageURI
                                            ?
                                            <img className="floorplanImage" src={userAPI.getResourceURL(properties.floor.floorplanImageURI)} alt="Logo" />
                                            :
                                            <div className="width300 height200 backgroundColorLightGrey displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter">Grundriss hierhin ziehen.</div>
                                    )
                                    :
                                    <img className="floorplanImage" src={imageList[0]['data_url']} alt="" />
                                }
                            </div>
                            <div className="marginTopL displayFlex gapM">
                                <PrimaryButton onClick={onImageUpload}>
                                    {t('settingsPanel.companyPanel.uploadFromFile')}
                                </PrimaryButton>
                                <PrimaryButton onClick={onImageRemoveAll}>{t('global.clear')}</PrimaryButton>
                            </div>
                        </div>
                    )}
                </ImageUploading>
            </div>
        }
        <Dialog hidden={!scalingDialogOpen}
            onDismiss={() => setScalingDialogOpen(false)}
            maxWidth={400}
            minWidth={400}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Skalierung Grundriss',
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <div className="marginBottomS textM" >Im Bild abgetragene Strecke von {scalingMeasurement.toFixed(0)} Pixeln in Metern.</div>
            <TextField componentRef={distanceInputRef} label={'Strecke'} value={scalingDistance} onChange={(e: any, value: any) => setScalingDistance(value)}
                suffix="m" errorMessage={isNaN(scalingDistance) ? 'Zahl eingeben' : ''}
            />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.save')} onClick={async () => {
                    const newFloor = { ...properties.floor, floorplanImageScale: scalingMeasurement / scalingDistance };

                    setFloor(newFloor);

                    await updateFloor(newFloor);

                    setScalingDialogOpen(false);
                }} />
                <DefaultButton onClick={() => setScalingDialogOpen(false)} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog >
    </div >;
}