import { useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-leaflet'
import Supercluster from 'supercluster'
import RenderMarkerSuperCluster from './RenderMarkerSuperCluster'
import { LatLngBounds, Map } from 'leaflet'

interface Properties {
    data: any;
    options: any;
}

const updateMapBoundsAndZoom = (
    inputMap: Map,
    inputSetBounds: React.Dispatch<React.SetStateAction<GeoJSON.BBox>>,
    inputSetZoom: React.Dispatch<React.SetStateAction<number>>
) => {
    if (inputMap) {
        const b: LatLngBounds = inputMap.getBounds()

        inputSetBounds([
            b.getSouthWest().lng,
            b.getSouthWest().lat,
            b.getNorthEast().lng,
            b.getNorthEast().lat
        ])

        inputSetZoom(inputMap.getZoom())
    }
}

const ClusterWithSuperCluster = (properties: Properties) => {
    const mapContext = useMap();
    const [dataCluster, setDataCluster] = useState([]) as any;
    const [mapBounds, setMapBounds] = useState<GeoJSON.BBox>([0, 0, 0, 0]);
    const [mapZoom, setMapZoom] = useState<number>(0);
    const [superclusterRef, setSuperclusterRef] = useState<{ [key: string]: any; } | undefined>();

    const memoizeDataCluster = useMemo(() => {
        return dataCluster
    }, [dataCluster])
    const memoizeSuperclusterRef = useMemo(() => {
        return superclusterRef
    }, [superclusterRef])

    const updateCluster = () => {
        const pointList = properties.data.map((item: any) => ({
            type: 'Feature',
            properties: {
                cluster: false
            },
            geometry: {
                type: 'Point',
                coordinates: [item.long, item.lat]
            },
            markerData: item
        }))

        const initSupercluster = new Supercluster({ radius: 40, maxZoom: 16 });

        initSupercluster.load(pointList)

        const getClusters = initSupercluster.getClusters(mapBounds, mapZoom)

        setSuperclusterRef(initSupercluster)
        setDataCluster(getClusters)
    }

    useEffect(() => {
        if (mapContext) {
            if (!mapZoom) {
                updateMapBoundsAndZoom(mapContext, setMapBounds, setMapZoom)
            }

            mapContext.on('zoom drag', () => {
                updateMapBoundsAndZoom(mapContext, setMapBounds, setMapZoom)
            })
        }
    }, [mapContext])

    useEffect(() => {
        if (mapBounds && mapZoom) updateCluster()
    }, [mapBounds, mapZoom])

    return <RenderMarkerSuperCluster superclusterRef={memoizeSuperclusterRef} data={properties.data} options={properties.options}/>;
}

export default ClusterWithSuperCluster