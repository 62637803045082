import { ColorPicker, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import '../App.sass';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';

interface Properties {
  color?: string;
  colorChanged?: (color: string) => void
}

export const ColorSelector = (properties: Properties) => {
  const { t } = useTranslation();
  const [color, setColor] = useState('#FF0000');
  const [borderColor, setBorderColor] = useState('#FF0000');
  const [editColor, setEditColor] = useState() as any;

  useEffect(() => {
    const newColor = properties.color || '#FF0000';

    setColor(newColor);
    setBorderColor(tinycolor(newColor).darken(30).toRgbString());
  }, [properties.color]);

  return <>
    <div className='width20 height20' style={{ backgroundColor: color, border: `1px solid ${borderColor}` }}
      onClick={() => setEditColor(color)}>
    </div>
    <Dialog hidden={!editColor}
      onDismiss={() => setEditColor(null)}
      maxWidth={450}
      minWidth={450}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Farbauswahl',
      }}
      modalProps={{
        isBlocking: true,
      }}>
      <div className='displayFlex flexDirectionColumn alignItemsCenter'>
        <ColorPicker
          color={editColor}
          alphaType={'transparency'}
          onChange={(x: any, color: any) => {
            setEditColor(color);
          }}
          showPreview={true}
          styles={{
            panel: { padding: 12 },
            root: {
              maxWidth: 352,
              minWidth: 352,
            },
            colorRectangle: { height: 268 },
          }}
          // The ColorPicker provides default English strings for visible text.
          // If your app is localized, you MUST provide the `strings` prop with localized strings.
          strings={{
            // By default, the sliders will use the text field labels as their aria labels.
            // Previously this example had more detailed instructions in the labels, but this is
            // a bad practice and not recommended. Labels should be concise, and match visible text when possible.
            hueAriaLabel: 'Hue',
          }}
        />
      </div>
      <DialogFooter className="marginTopL">
        <PrimaryButton text={t('global.apply')} onClick={async () => {
          setColor(editColor.str);

          if (properties.colorChanged) {
            properties.colorChanged(editColor.str);
          }

          setEditColor(null);
        }} />
        <DefaultButton onClick={() => {
          setEditColor(null);
        }} text={t('global.cancel')} />
      </DialogFooter>
    </Dialog>
  </>;
};