import "./css/editor.sass"
import { useState } from 'react'
import { ActionButton, TextField } from "@fluentui/react";
import DataTable from "react-data-table-component";
import { FloorplanEditor } from "./FloorplanEditor";

const initialSpaces = [{ name: '1.01', area: 0, shape: [] }, { name: '1.02', area: 0, shape: [] }];
const initialAssets = [{ name: 'Kälteanlage X42', point: { x: 150, y: 400 } }, { name: 'Sprinkler 16.1.22', point: { x: 350, y: 280 } }];

export function EditorTest(props: any) {
    const [spaces, setSpaces] = useState(initialSpaces) as any;
    const [assets, setAssets] = useState(initialAssets) as any;
    const [selectedSpaceIndex, setSelectedSpaceIndex] = useState() as any;

    const spacesColumns = [{
        name: '',
        width: '100px',
        cell: (space: any) => (
            <div className='height20 displayFlex alignItemsCenter gapXS'>
                <ActionButton iconProps={{ iconName: 'Delete' }} onClick={() => {
                    setSelectedSpaceIndex(spaces.indexOf(space));
                }}></ActionButton>
                <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => {
                    setSelectedSpaceIndex(spaces.indexOf(space));
                }}></ActionButton>
            </div>
        ),
        sortable: false,

    }, {
        name: 'Name',
        minWidth: '250px',
        maxWidth: '250px',
        cell: (space: any) => (
            <TextField value={space.name} styles={{ fieldGroup: { width: 250 } }} />
        )
    }, {
        name: 'Fläche/㎡',
        minWidth: '100px',
        maxWidth: '100px',
        right: true,
        cell: (space: any) => (
            <span>{space.shape ? space.area : '-'}</span>
        )
    }];




    return <div className="displayFlex gapM">
        <div>
            <FloorplanEditor floor={{spaces: spaces}} assets={assets} 
                selectedSpaceIndex={selectedSpaceIndex}></FloorplanEditor>
        </div>
        <div>
            <div className='marginTopM headerL'>Räume</div>
            <ActionButton className='marginTopM' iconProps={{ iconName: 'Add' }}
                onClick={() => {
                    setSpaces([...spaces, { name: 'New Space', shape: [] }]);
                    setSelectedSpaceIndex(spaces.length);
                }}>
                Raum anlegen
            </ActionButton>
            <DataTable
                data={spaces}
                columns={spacesColumns}
                pagination
                paginationPerPage={5}

            />
        </div>
    </div>
}