import { useEffect } from "react";
import io from "socket.io-client";
import { configuration } from "./Configuration";

const baseUrl = configuration.serverUrl;

export const useSpeech = (progress: (text: string, final: boolean) => void, transcribing: boolean) => {
    // @ts-ignore
    useEffect(() => {
        if (transcribing) {
            // @ts-ignore
            const socket = io.connect(baseUrl);

            socket.on('transcription', (data: any) => {
                progress(data.text, data.isFinal);
            });

            socket.on('connect', (errorMessage: string) => {
            });

            socket.on('error', (errorMessage: string) => {
                console.error('Server error:', errorMessage);
            });

            let audioContext: any;
            let audioInput: any;
            let processor: any;

            try {
                const process = async () => {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                    audioContext = new AudioContext();
                    audioInput = audioContext.createMediaStreamSource(stream);
                    processor = audioContext.createScriptProcessor(1024, 1, 1);

                    audioInput.connect(processor);
                    processor.connect(audioContext.destination);

                    processor.onaudioprocess = (e: any) => {
                        const float32Array = e.inputBuffer.getChannelData(0);
                        const int16Array = new Int16Array(float32Array.length);

                        for (let i = 0; i < float32Array.length; i++) {
                            int16Array[i] = Math.max(-32768, Math.min(32767, Math.floor(float32Array[i] * 32768)));
                        }

                        socket.emit('audioData', int16Array.buffer);
                    };

                    socket.emit('startTranscription');
                };

                process();

                return () => {
                    if (audioContext && audioContext.state !== 'closed') {
                        audioInput.disconnect();

                        if (processor) {
                            processor.disconnect();
                        }

                        if (audioContext) {
                            audioContext.close();
                        }

                        socket.emit('stopTranscription');
                    }
                };
            } catch (error) {
                console.error('Error accessing microphone:', error);

                return () => { };
            }
        } else {
            return () => { };
        }
    }, [transcribing]);
};