import * as React from 'react';
import { makeStyles, shorthands } from "@fluentui/react-components";
import { useEffect, useState } from 'react';
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { CSVLink } from "react-csv";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { configuration } from '../Configuration';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';

const baseUrl = configuration.serverUrl;
const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', '#EFEFEF'),
        ...shorthands.padding('10px'),
        backgroundColor: 'white',
    },
    title: {
        fontWeight: 'bold',
    },
    download: {
        marginTop: '20px',
        fontSize: '14px',
        display: 'flex',
    },
    icon: {
        fontSize: '16px',
        height: '16px',
        width: '16px',
        margin: '0 10px',
    },
    flexGrow: {
        flexGrow: 1,
    }
});

export const TableResource = ({ resource }: { resource: any }) => {
    const styles = useStyles();
    const [csvData, setCSVData] = useState([] as any);
    const [columns, setColumns] = useState([] as any);
    const [items, setItems] = useState([] as any);
    const [expanded, setExpanded] = useState(false);

    if (!resource.options) {
        resource.options = {};
    }

    useEffect(() => {
        const minimumDataLength = resource.options.columns ? 1 : 2;

        if (!resource.data || resource.data.length < minimumDataLength) {
            return;
        }

        if (resource.options.columns) {
            setColumns(resource.options.columns.map((column: any, index: number) => {
                return {
                    name: column.name,
                    selector: (item: any) => item[column.field],
                    width: column.width,
                    cell: (item: any) => {
                        switch (column.type) {
                            case 'image':
                                return item[column.field] ?
                                    <a href={`${baseUrl}/${item[column.field]}`} target="_blank"><Image src={`${baseUrl}/${item[column.field]}`} width={100} height={100} imageFit={ImageFit.cover} /></a>
                                    : <span>-</span>;
                            case 'list': return item[column.field] ? <ul>{Object.keys(item[column.field]).map((key: string) => {
                                return <li className='overflowWrapWordWrap'>{key}: {item[column.field][key]}</li>
                            })}</ul> : <span>-</span>
                            case 'date':
                                return <div>{item[column.field] ? new Date(item[column.field]).toLocaleDateString() : '-'}</div>;
                            default:
                                return <div className='overflowWrapWordWrap'>{item[column.field] ? item[column.field] : '-'}</div>;
                        }
                    },
                    sortable: true,
                }
            }));
        } else {
            setColumns(resource.data[0].map((column: any, index: number) => {
                return {
                    name: column,
                    width: 120,
                    selector: (item: any) => item[index],
                    sortable: true,                    
                }
            }));
        }

        if (resource.options.columns) {
            setItems(resource.data);

            // Calculate CSV data

            const csvData = [];

            if (resource.data.length > 0) {
                csvData.push(resource.options.columns.map((column: any) => column.name));
                resource.data.forEach((object: any) => csvData.push(resource.options.columns.map((column: any) => {
                    switch (column.type) {
                        case 'image': return object[column.field] ? `${baseUrl}/${object[column.field]}` : '';
                        case 'list': return object[column.field] ? JSON.stringify(object[column.field]).replace(/"/g, '""') : '';
                        case 'date': return object[column.field] ? new Date(object[column.field]).toLocaleDateString() : '';
                        default: return object[column.field] ? object[column.field] : '';
                    }
                })));
            }

            setCSVData(csvData);
        } else {
            setItems(resource.data.slice(1));
            setCSVData(resource.data);
        }
    }, []);

    const iconClass = mergeStyles({
        fontSize: 20,
        height: 20,
        width: 20,
    });

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Table" iconName="Table" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
            <CSVLink data={csvData.slice(1)} headers={csvData[0]} filename={`${resource.title}.csv`}>
                <ActionButton iconProps={{ iconName: 'Download' }} allowDisabledFocus>
                    Download CSV
                </ActionButton>
            </CSVLink>
        </div>
        {expanded
            ?
            <>
                <DataTable
                    data={items}
                    columns={columns}
                    customStyles={dataTableStyles}
                    pagination
                />
            </>
            :
            <></>
        }
    </div>
}
