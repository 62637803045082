import { ActionButton, DefaultButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import Section from '../components/Section';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../components/FileUpload';
import { useAdministrationAPI } from '../AdministrationAPI';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ApplicationError, UnauthorizedError } from '../general/Errors';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';

interface Properties {
    processError: (error: any) => void;
}

const units = [{
    id: '4F55*',
    issued: new Date('1/1/2024')
}, {
    id: 'AA55*',
    issued: new Date('3/22/2024')
}, {
    id: 'Z885*',
    issued: new Date('5/9/2024')
}];

export default function APIPanel(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ingestData, ingestDocuments } = useAdministrationAPI();
    const [authentication] = useAtom(authenticationAtom) as any;
    const [ingestDataDialogOpen, setIngestDataDialogOpen] = useState(false);
    const [ingestDataResult, setIngestDataResult] = useState() as any;
    const [documentType, setDocumentType] = useState('unknown');
    const [entityType, setEntityType] = useState('Measurement');

    const toggleIngestDataDialogOpen = () => {
        setIngestDataDialogOpen(!ingestDataDialogOpen);
    }

    const entityTypeOptions: IDropdownOption[] = [
        { key: 'Measurement', text: 'Telemetriedaten (Measurement)' },
        { key: 'Asset', text: 'Geräte und Gebäudeausstattung (Asset)' },
    ];
    const documentTypeOptions: IDropdownOption[] = [
        { key: 'unknown', text: 'Unbekannt' },
        { key: 'lease-contract', text: 'Mietvertrag' },
        { key: 'maintenance-report', text: 'Wartungsprotokoll' },
        { key: 'product-manual', text: 'Produkthandbuch' },
    ];
    const columns = [
        {
            name: t('apiPanel.key'),
            minWidth: '100px',
            maxWidth: '200px',
            cell: (item: any) => (
                <Text>{item.id}</Text>
            ),
        }, {
            name: t('apiPanel.created'),
            minWidth: '100px',
            maxWidth: '200px',
            cell: (item: any) => (
                <Text>{item.issued.toLocaleDateString()}</Text>
            ),
        }, {
            name: t('apiPanel.delete'),
            minWidth: '100px',
            maxWidth: '200px',
            cell: (item: any) => (
                <ActionButton iconProps={{ iconName: 'Delete' }} >
                </ActionButton>
            ),
        },
    ];

    const onDocumentsUpload = async (files: FileList) => {
        try {
            const formData = new FormData();

            for (let i = 0; i < files.length; ++i) {
                if (files.item(i) !== null) {
                    // @ts-ignore
                    formData.append(files.item(i).name, files.item(i));
                }
            }

            const result = await ingestDocuments(authentication.customer, documentType, formData);

            console.log('Result >>>', result);
        } catch (error) {
            properties.processError(error);
        }
    }

    const onDataUpload = async (files: FileList) => {
        try {
            const formData = new FormData();

            console.log('Ingest data', files);

            for (let i = 0; i < files.length; ++i) {
                if (files.item(i) !== null) {
                    // @ts-ignore
                    formData.append(files.item(i).name, files.item(i));
                }
            }

            setIngestDataResult(await ingestData(authentication.customer, entityType, formData));
            toggleIngestDataDialogOpen();
        } catch (error) {
            properties.processError(error);
        }
    }

    return <><div className="displayFlex flexDirectionColumn gapM">
        <Section title={t('apiPanel.apiKeys')}>
            <ActionButton iconProps={{ iconName: 'Certificate' }} allowDisabledFocus>
                {t('apiPanel.createKey')}
            </ActionButton>
            <div className='borderNeutral'>
                <DataTable
                    data={units}
                    columns={columns}
                    customStyles={dataTableStyles}
                />
            </div>
        </Section>
        <div><Text>Bedenken einfach Daten oder Dokumente hochzuladen? Kein Angst: Es ist einfach, fehlertolerant und achtet den Datenschutz.</Text>
            <ActionButton iconProps={{ iconName: 'TextDocument' }} onClick={() => navigate("/private/markup/Daten-Upload.md")}>Mehr erfahren?</ActionButton></div>
        <div className='displayFlex gapM'>
            <div className='flexGrow1'>
                <Section title={t('apiPanel.uploadDocuments')}>
                    <div>
                        <div>
                            <Dropdown
                                label="Dokumententyp"
                                options={documentTypeOptions}
                                selectedKey={documentType}
                                onChange={(e: any, item: any) => setDocumentType(item.key)}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            />
                        </div>
                        <div className="marginTopXL">
                            <FileUpload onUpload={onDocumentsUpload}></FileUpload>
                        </div>
                    </div>
                </Section>
            </div>
            <div className='flexGrow1'>
                <Section title={t('apiPanel.uploadTelemetryData')}>
                    <div>
                        <div>
                            <Dropdown
                                label="Objekttyp"
                                options={entityTypeOptions}
                                selectedKey={entityType}
                                onChange={(e: any, item: any) => setEntityType(item.key)}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            />
                        </div>
                        <div className="marginTopXL">
                            <FileUpload onUpload={onDataUpload}></FileUpload>
                        </div>
                    </div>
                </Section>
            </div>
        </div>
    </div>
        <Dialog hidden={!ingestDataDialogOpen}
            onDismiss={toggleIngestDataDialogOpen}
            maxWidth={400}
            minWidth={400}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('apiPanel.ingestDataResult'),
            }}
            modalProps={{
                isBlocking: true,
            }}>
            {ingestDataResult
                ?
                <div>
                    <div className='displayFlex'>
                        Von insgesamt {ingestDataResult.total} Datensätzen
                    </div>
                    <div className="marginTopL displayFlex justifyContentCenter">
                        <div>
                            <div className='displayFlex'>
                                <div className='width50 fontWeightBold'>
                                    {ingestDataResult.inserted}
                                </div>
                                <div className="marginLeftM">{t('apiPanel.inserted')}</div>
                            </div>
                            <div className='displayFlex'>
                                <div className='width50 fontWeightBold'>
                                    {ingestDataResult.updated}
                                </div>
                                <div className="marginLeftM">{t('apiPanel.updated')}</div>
                            </div>
                            <div className='displayFlex'>
                                <div className='width50 fontWeightBold'>
                                    {ingestDataResult.ignored}
                                </div>
                                <div className="marginLeftM">{t('apiPanel.ignored')}</div>
                            </div>
                            <div className='displayFlex'>
                                <div className='width50 fontWeightBold'>
                                    {ingestDataResult.deleted}
                                </div>
                                <div className="marginLeftM">{t('apiPanel.deleted')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <DialogFooter className="marginTopXXL">
                <DefaultButton onClick={toggleIngestDataDialogOpen} text={t('global.close')} />
            </DialogFooter>
        </Dialog>
    </>;
}