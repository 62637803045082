import { IconButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { ApplicationError, UnauthorizedError } from '../general/Errors';
import { useNavigate } from "react-router-dom";

interface Properties {
  messageBox: any;
}

export const useMessagBox = (timeout: number = 15000) => {
  const [messages, setMessages] = useState([] as any);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect((): any => {
    if (timeout > 0) {
      const interval = setInterval(() => {
        // Cleanup all messages older than timeout

        setMessages(messages.filter((message: any) => (Date.now() - message.timestamp) < timeout));
      }, timeout);

      return () => clearInterval(interval);
    } else {
      return null;
    }
  }, [messages, setMessages]);

  const pushMessage = (content: string) => {
    setMessages([...messages, { content, timestamp: Date.now() }]);
  };

  const popMessage = (message: any) => {
    setMessages(messages.filter((check: any) => check.timestamp !== message.timestamp));
  };

  const processError = (error: any) => {
    if (error instanceof ApplicationError) {
      pushMessage(error.toString());
    } else if (error instanceof UnauthorizedError) {
      navigate('/login');
    } else {
      pushMessage(t('global.generalError'));
    }
  }

  return {
    processError, pushMessage, messageBox: { messages, popMessage },
  };
}

export const MessageBox = (properties: Properties) => {
  const { t } = useTranslation();

  return <div className="messageBox">{properties.messageBox.messages.map((message: any) => (
    <div key={message.id} className="message paddingM displayFlex alignItemsCenter gapM">
      <FontIcon iconName="WarningSolid" className="colorWhite" />
      <div>
        <Text className="colorWhite" variant="medium">{message.content}</Text>
      </div>
      <div className="flexGrow1 displayFlex justifyContentEnd">
        <IconButton className="colorWhite" iconProps={{ iconName: 'Cancel' }} title="Close" ariaLabel="Close" onClick={() => properties.messageBox.popMessage(message)} />
      </div>
    </div>)
  )}</div>;
};