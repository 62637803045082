import './css/website.sass';
import WebsiteHeader from './WebsiteHeader';
import { useTranslation } from "react-i18next";
import Footer from './Footer';

export default function PressPage() {
  const { t } = useTranslation();

  return (
    <div className="website">
      <div className='wave'></div>
      <div className='wave'></div>
      <div className='wave'></div>
      <WebsiteHeader showSidePanel={true} showMapItem={true} showTabs={false}></WebsiteHeader>
      <div className='body'>
        <div className="content">
          <div className='marginTopXXL titleText'>{t('pressPage.assetTitle')}</div>
          <div className='marginTopL bodyText'>{t('pressPage.assetDetails')}</div>
          <div className='height400'></div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
