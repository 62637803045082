import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { useState } from "react";
import InfoList from "./InfoList";

class Properties {
  postalCode: any;
}

export default function PostalCodeProperties(properties: Properties) {
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: 'General',
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    }
  ];

  return (
    <div>
      <div>
        <Text variant="medium">Postleitzahlengebiet</Text>
      </div>
      <div>
        <Text variant="xxLarge">{properties.postalCode.postalCode}</Text>
      </div>
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div>
              <Text variant="large">Population</Text>
            </div>
            <div>
              <InfoList list={[
                {
                  label: 'Total Population',
                  value: properties.postalCode.population,
                },]}></InfoList>
            </div>
          </div>
        }
      })()}
    </div>
  );
}
