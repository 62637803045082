import { TextField } from "@fluentui/react";
import tinycolor from "tinycolor2";

class Properties {
  building: any;
}

const efficiency = [
  [87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87, 87,],
  [93, 93, 93, 92, 92, 91, 90, 89, 88, 86, 85, 84, 86, 81, 81, 80, 79, 79, 79,],
  [97, 97, 97, 96, 95, 93, 91, 89, 87, 85, 82, 80, 77, 75, 73, 71, 70, 70, 70,],
  [100, 99, 99, 97, 96, 94, 91, 88, 85, 82, 79, 75, 72, 69, 66, 64, 62, 61, 61,],
  [100, 99, 99, 97, 95, 93, 90, 86, 83, 79, 75, 71, 67, 63, 59, 56, 54, 52, 52,],
  [98, 97, 96, 95, 93, 90, 87, 83, 79, 75, 70, 66, 61, 56, 52, 48, 45, 44, 43,],
  [94, 93, 92, 91, 88, 85, 82, 78, 74, 70, 65, 60, 55, 50, 46, 41, 38, 36, 35,],
  [88, 87, 86, 85, 82, 79, 76, 72, 68, 63, 58, 54, 49, 44, 39, 35, 32, 29, 28,],
  [80, 79, 78, 77, 75, 72, 68, 65, 61, 59, 51, 47, 42, 37, 33, 29, 26, 24, 23,],
  [69, 69, 69, 67, 65, 63, 60, 56, 53, 48, 44, 40, 35, 31, 27, 24, 21, 19, 18,],
];

const getColor = (value: number) => {
  return value === 999 ? '#FFFFFF' : tinycolor('#00FF00').spin(-(100 - value) / 100 * 180).setAlpha(0.3).toRgbString();
}

export default function PVPotentialProperties(properties: Properties) {


  return <div>
    <div className="marginTopXL displayFlex justifyContentCenter gapXL">
      <div className="displayFlex flexDirectionColumn alignItemsCenter">
        <div className="textS colorContrast">Kosten</div>
        <div className="textXL colorContrast fontWeightBold">13.200€</div>
      </div>
      <div className="displayFlex flexDirectionColumn alignItemsCenter">
        <div className="textS colorPrimary">Energie/Jahr</div>
        <div className="textXL colorPrimary fontWeightBold">2200 kWh</div>
      </div>
      <div className="displayFlex flexDirectionColumn alignItemsCenter">
        <div className="textS colorTernary">Amortisation</div>
        <div className="textXL colorPrimaryDark fontWeightBold">2.3 Jahre</div>
      </div>
    </div>
    <div className="marginTopXL headerL">Ausrüstbare Dachflächen</div>
    <div className="marginTopM textM">Es stehen <span className="fontWeightBold">122 m²</span> ausrüstbare Fläche zur Verfügung.</div>
    <div className="marginTopM headerL">Effizienz nach Dachneigung und -orientierung</div>
    <div className="marginTopM displayFlex alignItemsCenter gapXS">
      <div className="textOrientationUp textS fontWeightBold">Dachneigung</div>
      <div>
        <div className="displayFlex justifyContentCenter textS fontWeightBold">Dachausrichtung</div>
        <div className="displayFlex">
          <div className="width25"></div>
          {Array(19).fill(null).map((n: any, l: number) => <div className="displayFlex alignItemsCenter justifyContentCenter width25"
            style={{ paddingTop: '2px', paddingBottom: '2px' }}>
            <div className="textXS fontWeightBold">{`${l * 10}°`}</div>
          </div>)}
        </div>
        {Array(10).fill(null).map((n: any, i: number) =>
          <div className="displayFlex">
            <div className="width25 textXS fontWeightBold">{i * 10}°</div>
            {Array(19).fill(null).map((n: any, l: number) => <div className="displayFlex alignItemsCenter justifyContentCenter width25"
              style={{ backgroundColor: getColor(efficiency[i][l]), paddingTop: '2px', paddingBottom: '2px' }}>
              <div className="textXS">{efficiency[i][l] === 999 ? '-%' : `${efficiency[i][l]}%`}</div>
            </div>)}
          </div>)}
      </div>
    </div>
    <div className="marginTopM textM">
      <div><span className="fontWeightBold">61 m²</span> mit Neigung <span className="fontWeightBold">22°</span> und Orientierung <span className="fontWeightBold">188°</span> entspricht <span className="fontWeightBold">83%</span> Wirkungsgrad.</div>
      <div><span className="fontWeightBold">61 m²</span> mit Neigung <span className="fontWeightBold">22°</span> und Orientierung <span className="fontWeightBold">8°</span> entspricht <span className="fontWeightBold">33%</span> Wirkungsgrad.</div>
    </div>
    <div className="marginTopM headerL">Überschlagsrechnung Ertrag und Amortisation</div>
    <div className="marginTopM displayFlex gapM">
      <div className="width150">
        <TextField label="Leistung/m²" value="300" suffix="kW"></TextField>
        <div className="marginTopXS textXS">Richtwert 300 kWp</div></div>
      <div className="width150">
        <TextField label="Sonnenstunden/Jahr" value="3200"></TextField>
        <div className="marginTopXS textXS">Richtwert Region Frankfurt 3000</div></div>
      <div className="width150">
        <TextField label="Kosten/m²" value="180" suffix="€"></TextField>
        <div className="marginTopXS textXS">Richtwert für diese Anlagengröße inkl. Montage Region Frankfurt 180€/m².</div>
      </div>
    </div>
  </div>;
}
