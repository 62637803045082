import { Asset, Building, BusinessRule, Customer, CustomerTheme, Entity, Floor, Invoice, LegalPerson, Product, Project, ProjectEntitlement, Space } from "@marc.gille-sepehri/tri-model";
import { configuration } from "./Configuration";
import { ApplicationError, UnauthorizedError } from "./general/Errors";
import { useAtom } from "jotai";
import { authenticationAtom } from './State';

export const useAdministrationAPI = () => {
    const baseUrl = configuration.serverUrl;
    const [authentication] = useAtom(authenticationAtom) as any;

    const processResponse = async (response: Response) => {
        if (response.ok) {
            const result = await response.json();

            if (result.error) {
                throw new ApplicationError(result.error);
            }

            return result;
        } else {
            if (response.status === 401) {
                throw new UnauthorizedError();
            } else {
                throw Error();
            }
        }
    }

    const getCustomers = async () => {
        const response = await fetch(`${baseUrl}/administration/customers`, {
            credentials: 'include',
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const createCustomer = async (customer: any) => {
        const response = await fetch(`${baseUrl}/administration/customers`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(customer),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const updateCustomer = async (customer: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customer.id}`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(customer),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const updateCustomerLogo = async (customerId: string, form: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/logo`, {
            credentials: 'include',
            method: 'PUT',
            body: form,
            headers: {
                //'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const deleteCustomer = async (customerId: string) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}`, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const getUsers = async (customerId: string) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/users`, {
            credentials: 'include',
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const createUser = async (customerId: string, user: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/users`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const updateUser = async (customerId: string, user: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/users/${user.id}`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const deleteUser = async (customerId: any, user: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/users/${user.id}`, {
            credentials: 'include',
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const ingestData = async (customerId: string, entityType: string, form: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/ingest/data/${entityType}/files`, {
            credentials: 'include',
            method: 'POST',
            body: form,
            headers: {
                //'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const ingestDocuments = async (customerId: string, documentType: string, form: any) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/ingest/documents/${documentType}/files`, {
            credentials: 'include',
            method: 'POST',
            body: form,
            headers: {
                //'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const getInvoiceForecast = async (customerId: string, from: Date, to: Date) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/invoices/forecast?from=${from.toISOString()}&to=${to.toISOString()}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return new Invoice(await response.json());
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const getInvoices = async (customerId: string, from: Date, to: Date) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/invoices?from=${from.toISOString()}&to=${to.toISOString()}`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return (await response.json()).map((properties: any) => {
                return new Invoice(properties)
            });
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const getAllInvoices = async (from: Date, to: Date, openOnly: boolean, filter: string) => {
        let url = `${baseUrl}/administration/invoices?from=${from.toISOString()}&to=${to.toISOString()}&openOnly=${openOnly}`;

        if (filter) {
            url += `&filter=${filter}`;
        }

        const response = await fetch(url, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return (await response.json()).map((properties: any) => {
                return new Invoice(properties)
            });
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const runBillingGlobal = async (period: Date) => {
        const response = await fetch(`${baseUrl}/administration/billing?period=${period.toISOString()}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const currentlyActiveOrders = async (customerId: string) => {
        const response = await fetch(`${baseUrl}/administration/customers/${customerId}/invoices/current`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const runBilling = async (customers: Customer[], period: Date) => {
        const response = await fetch(`${baseUrl}/administration/billing?period=${period.toISOString()}`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(customers),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 400) {
                throw new ApplicationError((await response.json()).message);
            } else if (response?.status === 401) {
                throw new UnauthorizedError();
            }
        }
    };

    const updateEntity = async (entity: Entity) => {
        const response = await fetch(`${baseUrl}/entities/${entity.getTypeName()}`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(entity),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateInvoices = async (invoices: Invoice[]) => {
        const response = await fetch(`${baseUrl}/invoices`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(invoices),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const createOrUpdateProduct = async (product: Product) => {
        const response = await fetch(`${baseUrl}/products`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(product),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const createOrUpdateBusinessRule = async (businessRule: BusinessRule) => {
        const response = await fetch(`${baseUrl}/business-rules`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(businessRule),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const activateBusinessRule = async (businessRule: BusinessRule) => {
        const response = await fetch(`${baseUrl}/business-rules/activate`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(businessRule),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deactivateBusinessRule = async (businessRule: BusinessRule) => {
        const response = await fetch(`${baseUrl}/business-rules/deactivate`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(businessRule),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deleteBusinessRule = async (businessRule: BusinessRule) => {
        const response = await fetch(`${baseUrl}/business-rules`, {
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify(businessRule),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const createOrUpdateCustomerTheme = async (customerTheme: CustomerTheme) => {
        customerTheme.customer = authentication.customer;

        const response = await fetch(`${baseUrl}/theme`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(customerTheme),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const getLatestOperationalState = async () => {
        const response = await fetch(`${baseUrl}/operational-states/latest`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const createFloor = async (building: Building, floor: Floor) => {
        const response = await fetch(`${baseUrl}/buildings/${building._id}/floors`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(floor),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateFloor = async (floor: Floor) => {
        const response = await fetch(`${baseUrl}/floors`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(floor),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deleteFloor = async (floor: Floor) => {
        const response = await fetch(`${baseUrl}/floors`, {
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify(floor),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateFloorplanImage = async (floor: Floor, form: any) => {
        const response = await fetch(`${baseUrl}/floors/${floor._id}/floorplanImage`, {
            credentials: 'include',
            method: 'PUT',
            body: form,
            headers: {
                //'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return response.json();
    };

    const createSpace = async (floor: Floor, space: Space) => {
        if (!floor) {
            throw Error('Cannot create space. Floor or building undefined.');
        }

        const response = await fetch(`${baseUrl}/floors/${floor._id}/spaces`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(space),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateSpace = async (space: Space) => {
        const response = await fetch(`${baseUrl}/spaces`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(space),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deleteSpace = async (space: Space) => {
        const response = await fetch(`${baseUrl}/spaces`, {
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify(space),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateAsset = async (asset: Asset) => {
        const response = await fetch(`${baseUrl}/assets`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(asset),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const getProjects = async () => {
        const response = await fetch(`${baseUrl}/projects`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        const result = await processResponse(response);

        return result.map((entry: any) => new Project(entry));
    };

    const createProject = async (project: Project) => {
        const response = await fetch(`${baseUrl}/projects`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(project),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateProject = async (project: Project) => {
        const response = await fetch(`${baseUrl}/projects`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(project),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deleteProject = async (project: Project) => {
        const response = await fetch(`${baseUrl}/projects`, {
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify(project),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const getProjectEntitlements = async (project: Project) => {
        const response = await fetch(`${baseUrl}/projects/${project._id}/projectEntitlements`, {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };
    
    const createProjectEntitlement = async (projectEntitlement: ProjectEntitlement) => {
        const response = await fetch(`${baseUrl}/projectEntitlements`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(projectEntitlement),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateProjectEntitlement = async (projectEntitlement: ProjectEntitlement) => {
        const response = await fetch(`${baseUrl}/projectEntitlements`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(projectEntitlement),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deleteProjectEntitlement = async (projectEntitlement: ProjectEntitlement) => {
        const response = await fetch(`${baseUrl}/projectEntitlements`, {
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify(projectEntitlement),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const createLegalPerson = async (legalPerson: LegalPerson) => {
        const response = await fetch(`${baseUrl}/legalPersons`, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(legalPerson),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const updateLegalPerson = async (legalPerson: LegalPerson) => {
        const response = await fetch(`${baseUrl}/legalPersons`, {
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify(legalPerson),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    const deleteLegalPerson = async (legalPerson: LegalPerson) => {
        const response = await fetch(`${baseUrl}/legalPersons`, {
            credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify(legalPerson),
            headers: {
                'Content-Type': 'application/json',
                // "authorization": "Bearer " + authentication.access_token
            }
        });

        return processResponse(response);
    };

    return {
        getCustomers,
        createCustomer,
        updateCustomer,
        updateCustomerLogo,
        deleteCustomer,
        getUsers,
        createUser,
        updateUser,
        deleteUser,
        ingestData,
        ingestDocuments,
        getInvoiceForecast,
        getAllInvoices,
        updateInvoices,
        getInvoices,
        runBillingGlobal,
        currentlyActiveOrders,
        runBilling,
        updateEntity,
        createOrUpdateProduct,
        createOrUpdateBusinessRule,
        deleteBusinessRule,
        activateBusinessRule,
        deactivateBusinessRule,
        createOrUpdateCustomerTheme,
        getLatestOperationalState,
        createFloor,
        updateFloor,
        deleteFloor,
        updateFloorplanImage,
        createSpace,
        updateSpace,
        deleteSpace,
        updateAsset,
        getProjects,
        createProject,
        updateProject,
        deleteProject,
        getProjectEntitlements,
        createProjectEntitlement,
        updateProjectEntitlement,
        deleteProjectEntitlement,
        createLegalPerson,
        updateLegalPerson,
        deleteLegalPerson,
    }
}
