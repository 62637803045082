import { useEffect, useState } from 'react';
import './css/website.sass';
import WebsiteHeader, { Tabs } from './WebsiteHeader';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import { ActionButton } from '@fluentui/react/lib/Button';
import { useNavigate } from "react-router-dom";

export default function LegalPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="website">
      <div className='wave'></div>
      <div className='wave'></div>
      <div className='wave'></div>
      <WebsiteHeader showSidePanel={true} showMapItem={true} showTabs={false}></WebsiteHeader>
      <div className='body'>
        <div className="content">
          <div className='marginTopXXL titleText'>{t('legalPage.title')}</div>
          <div className='marginTopL bodyText'>{t('legalPage.introduction')}</div>
          <div>
            <div className='marginTopL paddingM displayFlex borderBottomNeutral'>
              <div className="textL">Datenschutzbestimmungen</div>
              <div className='flexGrow1 displayFlex justifyContentEnd bodyText'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => navigate('/markup/legal/Datenschutzbestimmungen.md')}>
                  {t('legalPage.read')}
                </ActionButton>
              </div>
            </div>
            <div className=' paddingM displayFlex borderBottomNeutral'>
              <div className="textL">Nutzungsbedingungen</div>
              <div className='flexGrow1 displayFlex justifyContentEnd bodyText'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => navigate('/markup/legal/Nutzungsbedingungen.md')}>
                  {t('legalPage.read')}
                </ActionButton>
              </div>
            </div>
            <div className=' paddingM displayFlex alignItemsCenter borderBottomNeutral'>
              <div className="textL">Zulässige Nutzung</div>
              <div className='flexGrow1 displayFlex justifyContentEnd bodyText'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => navigate('/markup/legal/Richtlinien_für_die_zulässige_Nutzung.md')}>
                  {t('legalPage.read')}
                </ActionButton>
              </div>
            </div>
            <div className=' paddingM displayFlex alignItemsCenter borderBottomNeutral'>
              <div className="textL">Sicherheitszusatz</div>
              <div className='flexGrow1 displayFlex justifyContentEnd bodyText'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => navigate('/markup/legal/Sicherheitszusatz.md')}>
                  {t('legalPage.read')}
                </ActionButton>
              </div>
            </div>
            <div className=' paddingM displayFlex borderBottomNeutral'>
              <div className="textL">Support & Service Level</div>
              <div className='flexGrow1 displayFlex justifyContentEnd bodyText'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => navigate('/markup/legal/Support_und_Service-Level.md')}>
                  {t('legalPage.read')}
                </ActionButton>
              </div>
            </div>
            <div className=' paddingM displayFlex borderBottomNeutral'>
              <div className="textL">Auftragsverarbeiter</div>
              <div className='flexGrow1 displayFlex justifyContentEnd bodyText'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => navigate('/markup/legal/Auftragsverarbeiterliste.md')}>
                  {t('legalPage.read')}
                </ActionButton>
              </div>
            </div>
          </div>
          <div className='marginTopL hoverHand'>
            <a href="email:info@the-real-insight.com">{t('legalPage.emailUs')}</a>
          </div>
          <div className='height400'></div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}
