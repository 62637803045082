import { atom } from 'jotai';
import { atomWithStorage } from "jotai/utils";

const authenticationKey = '__authentication';
const themeKey = '__theme';

export const settingsAtom = atom(null);
export const conversationAtom = atom([]);
export const mapQueryAtom = atom(null);
export const mapDataAtom = atom(null);
export const mapOptionsAtom = atom({});
export const selectedEntityAtom = atom(null);
export const requestsAtom = atom([]);
export const requestIndexAtom = atom(0);
export const authenticationAtom = atomWithStorage('authentication',
    JSON.parse(localStorage.getItem(authenticationKey) || "{}"), {
    getItem: (key: string, initialValue: any) => {
        return JSON.parse(localStorage.getItem(authenticationKey) || "{}");
    },
    setItem: (key: string, value: any) => {
        try {
            localStorage.setItem(authenticationKey, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    },
    removeItem: (key: string) => {
        localStorage.removeItem(authenticationKey);
    }
});
export const themeAtom = atomWithStorage('theme',
    JSON.parse(localStorage.getItem(themeKey) || "{}"), {
    getItem: (key: string, initialValue: any) => {
        return JSON.parse(localStorage.getItem(themeKey) || "{}");
    },
    setItem: (key: string, value: any) => {
        try {
            localStorage.setItem(themeKey, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    },
    removeItem: (key: string) => {
        localStorage.removeItem(themeKey);
    }
});

