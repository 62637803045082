import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import ChatBotPage from './ChatBotPage';
import MapView from './MapView';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Provider } from 'jotai';
import HomePage from './website/HomePage';
import Protected from './Protected';
import { LoginPage } from './website/LoginPage';
import { SignUpPage } from './website/SignUpPage';
import './i18n.ts';
import SettingsPage from './SettingsPage';
import { PartialTheme, ThemeProvider } from '@fluentui/react/lib/Theme';
import AdministrationPage from './administration/AdministrationPage';
import LegalPage from './website/LegalPage';
import PressPage from './website/PressPage';
import MarkupPage from './MarkupPage';
import EntityPage from './EntityPage';
import TestDIN276 from './TestDIN276';
import { CustomerThemeProvider } from './Theme';
import { registerIcons } from '@fluentui/react';
import { EndPointSolidIcon } from '@fluentui/react-icons-mdl2';
import ImprintPage from './website/ImprintPage';
import { EditorTest } from './floorplanEditor/EditorTest';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

initializeIcons();

registerIcons({
  icons: {
    endpointsolid: <EndPointSolidIcon />,
  }
});
const theme: PartialTheme = {
  palette: {
    themePrimary: '#649aaa',
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<HomePage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignUpPage />} />
                <Route path="imprint" element={<ImprintPage />} />
                <Route path="legal" element={<LegalPage />} />
                <Route path="press" element={<PressPage />} />
                <Route path="markup/*" element={<MarkupPage></MarkupPage>} />
                <Route path="din276/*" element={<TestDIN276></TestDIN276>} />
                <Route path="editor" element={<EditorTest />} />
                <Route element={<Protected />}>
                  <Route path="chatbot" element={<ChatBotPage />} />
                  <Route path="map" element={<MapView />} />
                  <Route path="entity" element={<EntityPage />} />
                  <Route path="settings" element={<SettingsPage />} />
                  <Route path="administration" element={<AdministrationPage />} />
                  <Route path="private/markup/*" element={<MarkupPage></MarkupPage>} />
                  <Route path=":customer/administration" element={<CustomerThemeProvider></CustomerThemeProvider>} />
                  <Route path=":customer/settings" element={<CustomerThemeProvider>
                    <SettingsPage />
                  </CustomerThemeProvider>} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </DndProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
