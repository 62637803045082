import { useState } from "react";
import { TextField } from "@fluentui/react";
import PasswordChecklist from "react-password-checklist"
import { useTranslation } from "react-i18next";

interface Properties {
  setPassword: any;
  setPasswordValid: any
}

export const PasswordChangePanel = (properties: Properties) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState();
  const [passwordRepeat, setPasswordRepeat] = useState(undefined);

  return <div>
    <div className="marginTopM displayFlex">
      <TextField
        autoFocus
        label={t('common.passwordChangePanel.newPassword')}
        type="password"
        canRevealPassword
        value={password}
        styles={{
          fieldGroup: [
            { width: '300px' },
          ]
        }}
        onChange={(event, value: any) => {
          setPassword(value);
        }}
      />
    </div>
    <div className="marginTopM displayFlex">
      <TextField
        label={t('common.passwordChangePanel.newPasswordRepeat')}
        type="password"
        canRevealPassword
        value={passwordRepeat}
        styles={{
          fieldGroup: [
            { width: '300px' },
          ]
        }}
        onChange={(event, value: any) => {
          setPasswordRepeat(value);
        }}
      />
    </div>
    <div className="marginTopM">
      <PasswordChecklist
        rules={["minLength", "specialChar", "number", "capital", "match"]}
        minLength={5}
        value={password || ''}
        valueAgain={passwordRepeat}
        messages={{
          minLength: t('common.passwordChangePanel.passwordRuleMinLength'),
          specialChar: t('common.passwordChangePanel.passwordRuleSpecialChar'),
          number: t('common.passwordChangePanel.passwordRuleNumber'),
          capital: t('common.passwordChangePanel.passwordRuleCapital'),
          match: t('common.passwordChangePanel.passwordRuleMatch'),
        }}
        onChange={(valid: boolean) => {
          properties.setPasswordValid(valid);
        }}
      />
    </div>
  </div>;
};