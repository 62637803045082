import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { useState } from "react";
import InfoList from "./InfoList";

class Properties {
  area: any;
}

const useStyles = makeStyles({
  commandBar: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  firstSectionHeader: {
    marginTop: '30px',
  },
  sectionHeader: {
    marginTop: '20px',
  },
  infoList: {
    marginTop: '10px',
  },
});

export default function LocalGovernmentProperties(properties: Properties) {
  const styles = useStyles();
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: 'General',
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    }
  ];

  return (
    <div>
      <div>
        <Text variant="medium">{properties.area.type}</Text>
      </div>
      <div>
        <Text variant="xxLarge">{properties.area.name}</Text>
      </div>
      <div className={styles.commandBar}>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div className={styles.firstSectionHeader}>
              <Text variant="large">Population</Text>
            </div>
            <div className={styles.infoList}>
              <InfoList list={[
                {
                  label: 'Total Population',
                  value: `17`,
                },]}></InfoList>
            </div>
          </div>
        }
      })()}
    </div>
  );
}
