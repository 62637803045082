import './css/website.sass';
import 'animate.css';
import { useTranslation } from "react-i18next";

export default function CompanyPanel() {
  const { t } = useTranslation();

  return <>
    <div className="centeredContentSection">
      <div className="titleText">Über The Real Insight</div>
    </div>
    <div className='marginTopXL flow zIndex999 height600'>
      <div className="block33">
        <img src="company/christian.png" className="img100Pct" alt="Christian Schlicht" />
        <div className="marginTopM centeredContentSection">
          <div className="textL fontWeightBold">Dr. Christian Schlicht</div>
          <div className="textL fontWeightBold  colorGrey">Chief Executive Officer</div>
          <div className="marginTopS vita textL">Mehr als 20 Jahre Erfahrung
            in Real Estate Management,
            ESG und Innovation</div>
          <div className="marginTopM"><a href="https://www.linkedin.com/in/theschlicht/"><img className="socialMediaLogo" src="/social-media/li-logo.png" alt="li-logo" /></a></div>
        </div>
      </div>
      {/* <div className="person">
        <img src="company/marcella.png" alt="Marcella Rothermel" />
        <div className="marginTopM personDetails">
          <div className="textL fontWeightBold">Dr. Marcella Rothermel</div>
          <div className="textL fontWeightBold colorGrey">Chief Data Engineer</div>
          <div className="marginTopS vita textL">Mehr als 10 Jahre Erfahrung
            in Data Analytics, Business
            Intelligence und Technology</div>
          <div className="marginTopM"><a href="https://www.linkedin.com/in/dr-marcella-rothermel-b585572b7/"><img className="socialMediaLogo" src="/social-media/li-logo.png" alt="li-logo" /></a></div>
        </div>
      </div> */}
      {/* <div className="person">
        <img src="company/theother.png" alt="Dr. Dre" />
        <div className="marginTopM personDetails">
          <div className="textL fontWeightBold">Dr. Dreh</div>
          <div className="textL fontWeightBold colorGrey">Mischief Officer</div>
          <div className="marginTopS vita textL">Mehr als 50 Jahre Erfahrung
            darin</div>
          <div className="marginTopM"><img className="socialMediaLogo" src="/social-media/li-logo.png" alt="li-logo" /></div>
        </div>
      </div> */}
    </div>
    {/* <div className='marginTopXXL displayFlex justifyContentCenter animate__animated animate__fadeIn animate__slow'>
      <div className="textXXL">Join the Crew</div>
      <div className='height400'></div>
    </div> */}
  </>;
}
