import { PartialTheme, ThemeProvider } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import tinycolor from "tinycolor2";
import { userAPI } from "./UserAPI";
import { CustomerTheme, EntityQuery, Query, is } from "@marc.gille-sepehri/tri-model";
import { useAtom } from "jotai";
import { themeAtom } from './State';

export const styleCalculator = [
    { direction: -1, factor: 10, name: 'Primary Darker', color: '#649aaa', field: 'primaryDarker' },
    { direction: -1, factor: 5, name: 'Primary Dark', color: '#649aaa', field: 'primaryDark' },
    { direction: 1, factor: 0, name: 'Primary', color: '#649aaa', field: 'primary' },
    { direction: 1, factor: 40, name: 'Primary Light', color: '#649aaa', field: 'primaryLight' },
    { direction: 1, factor: 45, name: 'Primary Lighter', color: '#649aaa', field: 'primaryLighter' },
    { direction: 1, factor: 0, name: 'Secondary', color: '#576071', field: 'secondary' },
    { direction: 1, factor: 0, name: 'Accent', color: '#1E1A76', field: 'accent' },
];

const calculateThemeColor = (parameters: any) => {
    if (parameters.direction > 0) {
        return tinycolor(parameters.color).lighten(parameters.factor).toHexString();
    } else {
        return tinycolor(parameters.color).darken(parameters.factor).toHexString();
    }
}

export const defaultTheme = styleCalculator.reduce((map: any, parameters: any) => {
    map[parameters.field] = calculateThemeColor(parameters);

    return map;
}, {});

// @deprecated
export const useCustomerTheme = () => {
    const params = useParams();
    const [logo, setLogo] = useState(null) as any;
    const [theme, setTheme] = useState(defaultTheme) as any;

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                if (params.customer) {
                    setLogo('/fire-iq-logo.png');
                    const result = await userAPI.query(new Query(new EntityQuery(CustomerTheme.type, [], undefined,
                        is('customer').equal(params.customer))));

                    if (result && result.length > 0) {
                        setTheme(result[0]);
                    } 
                } else {
                    setLogo(null);
                }
            } catch (e) {
                console.error(e);

                return null;
            }
        }

        fetchLogo();
    }, [params.customer]);

    return {
        logo,
        theme,
    }
}

interface Properties {
    children?: any;
}

export function CustomerThemeProvider(properties: Properties) {
    const params = useParams();
    const [theme, setTheme] = useAtom(themeAtom);

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                if (params.customer) {
                    const result = await userAPI.query(new Query(new EntityQuery(CustomerTheme.type, [], undefined,
                        is('customer').equal(params.customer))));

                    if (result && result.length > 0) {
                        setTheme(result[0]);
                    }
                }
            } catch (e) {
                console.error(e);

                return null;
            }
        }

        fetchLogo();
    }, [params.customer]);

    const partialThema = (): PartialTheme => {
        const currentTheme = theme || defaultTheme;

        return {
            palette: {
                themePrimary: currentTheme.primary,
                themeSecondary: currentTheme.secondary,
            },
        };
    }

    return <div>
        <ThemeProvider theme={partialThema()}>
            {properties && properties.children
                ?
                (properties.children)
                :
                <></>
            }
        </ThemeProvider>
    </div>;
}