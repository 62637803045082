import React from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
    avatar: {
        width: '40px',
        height: '40px',
        ...shorthands.borderRadius('50%'),
        backgroundColor: 'transparent',
    },
    avatarImage: {
        width: '100%',
    }
});

export const UserAvatar = () => {
    const styles = useStyles();

    return (
        // <div className="react-chatbot-kit-chat-bot-avatar">
        //     <div className="react-chatbot-kit-user-avatar-container">
        //         <div className={styles.avatar}>
        //             <img src="./icons/user.png" alt="user" className={styles.avatarImage} />
        //         </div>
        //     </div>
        // </div>
        <></>
    );
};