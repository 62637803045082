import { Dialog, DialogType, Label, TextField } from '@fluentui/react';
import '../App.sass';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { assetIcons, getAssetIcon, Icon } from './Icon';

interface Properties {
  icon: string;
  iconChanged?: (id: string) => void
}

export const IconSelector = (properties: Properties) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState(getAssetIcon('fire-extinguisher')) as any;
  const [dialogOpen, setDialogOpen] = useState() as any;

  useEffect(() => {
    if (!properties.icon) {
      return;
    }

    const newIcon = getAssetIcon(properties.icon);

    setIcon(newIcon);

  }, [properties.icon]);

  return <>
    <div className="width30 height30" style={{ backgroundColor: 'white', border: `1px solid ${icon.color}` }} onClick={() => setDialogOpen(true)}>
      <Icon id={icon.id}></Icon>
    </div>
    <Dialog hidden={!dialogOpen}
      onDismiss={() => setDialogOpen(false)}
      maxWidth={700}
      minWidth={700}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Symbolauswahl',
      }}
      modalProps={{
        isBlocking: true,
      }}>
      <div>
        <div className="displayFlex gapM">
          <Label>Filter:</Label><TextField placeholder='Filter eingeben ...' underlined />
        </div>
        <div className="marginTopL flex1 displayFlex flexDirectionColumn flexStretch">
          <div className='displayFlex gapM flexWrap'>
            {assetIcons.map((icon: any) =>
              <div className="displayFlex flexDirectionColumn alignItemsCenter width80" onClick={() => {
                setDialogOpen(false);
                setIcon(icon);

                if (properties.iconChanged) {
                  properties.iconChanged(icon.id);
                }
              }}>
                <div className="width30 height30" style={{ backgroundColor: 'white', border: `1px solid ${icon.color}` }}>
                  <Icon id={icon.id}></Icon>
                </div>
                <span className="marginTopS textXS textAlignCenter">{icon.label}</span>
              </div>)
            }
          </div>
        </div>
      </div>
    </Dialog>
  </>;
};