import { useEffect, useRef, useState } from 'react';
import '../../App.sass';
import { useTranslation } from 'react-i18next';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
import * as THREE from 'three';
// @ts-ignore
import { Threebox } from 'threebox-plugin';
import { userAPI } from '../../UserAPI';
import proj4 from 'proj4';
import earcut from 'earcut';
import { ActionButton } from '@fluentui/react';
import { CityObjectLoD2 } from '@marc.gille-sepehri/tri-model';
import Section from '../../components/Section';
import InfoList from '../InfoList';

mapboxgl.accessToken = 'pk.eyJ1IjoibWFyY2dpbGxlc2VwZWhyaSIsImEiOiJjbTFoOWszeHQwYzR6MmpzYXh0OWo0cWF1In0.oCmn9LnN9lZavqGgDL_Y2w';

class Properties {
    object: any;
}

const projections = {
    WGS84: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs",
    ETRS89: "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
};

mapboxgl.accessToken = 'pk.eyJ1IjoibWFyY2dpbGxlc2VwZWhyaSIsImEiOiJjbTFoOWszeHQwYzR6MmpzYXh0OWo0cWF1In0.oCmn9LnN9lZavqGgDL_Y2w';

const numberFormatArea = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 });

const dummyShell = [
    [[[0, 0, 0], [0, 10, 0], [10, 10, 0], [10, 0, 0]]], // Ground
    [[[0, 0, 0], [0, 10, 0], [0, 10, 7], [0, 0, 5]]], // Left
    [[[10, 0, 0], [10, 10, 0], [10, 10, 7], [10, 0, 5]]], // Right
    [[[0, 0, 0], [10, 0, 0], [10, 0, 5], [0, 0, 5]]], // Front
    [[[0, 10, 0], [10, 10, 0], [10, 10, 7], [0, 10, 7]]], // Back
    [[[0, 0, 5], [10, 0, 5], [10, 10, 7], [0, 10, 7]]], // Roof
];
const dummySurfaceTypes = [
    'GroundSurface',
    'WallSurface',
    'WallSurface',
    'WallSurface',
    'WallSurface',
    'RoofSurface',
];

const roofColor = 0xd9bc82;
const wallColor = 0xf0efc0;
const groundColor = 0xdddddd;
const unknownColor = 0xff00ff;

const pA = new THREE.Vector3();
const pB = new THREE.Vector3();
const pC = new THREE.Vector3();
const cb = new THREE.Vector3();
const ab = new THREE.Vector3();

const plane = (surface: any) => {
    let comparison: number[];
    let result = -1;

    surface.forEach((vertex: number[], index: number) => {
        if (index === 0) {
            comparison = vertex;
        } else {
            if (comparison[0] === vertex[0]) {
                result = 0;
            } else if (comparison[1] === vertex[1]) {
                result = 1;
            } else if (comparison[2] === vertex[2]) {
                result = 2;
            } else {
                result = -1;
            }
        }
    });

    return result;
}

const roofTypeLabel = (id: string) => {
    switch (id) {
        case '1000': return 'Flachdach';
        case '2100': return 'Pultdach';
        case '2200': return 'Versetztes Pultdach';
        case '3100': return 'Satteldach';
        case '3200': return 'Walmdach';
        case '3300': return 'Knüppelwalmdach';
        case '3400': return 'Mansardendach';
        case '3500': return 'Zeltdach';
        case '3600': return 'Kegeldach';
        case '3700': return 'Kuppeldach';
        case '3700': return 'Kuppeldach';
        case '3800': return 'Sheddach';
        case '3900': return 'Bogendach';
        case '4000': return 'Turmdach';
        case '5000': return 'Mischform';
        default: return 'Sonstiges';
    }
}

const drawGeometry = (vertices2D: any, indices: number[]) => {
    const canvas = document.getElementById("geometryCanvas");

    if (!canvas) {
        return;
    }

    // @ts-ignore
    const ctx = canvas.getContext("2d");
    const scale = 100;

    vertices2D = vertices2D.map((vertex: number[]) => [scale * vertex[0] + 10, scale * vertex[1] + 10, scale * vertex[2] + 10]);

    ctx.clearRect(0, 0, 500, 300);
    ctx.beginPath();

    vertices2D.forEach((vertex: number[], index: number) => {
        if (index === 0) {
            ctx.moveTo(vertex[0], vertex[1]);
        } else {
            ctx.lineTo(vertex[0], vertex[1]);
        }
    });

    ctx.lineTo(vertices2D[0][0], vertices2D[0][1]);

    ctx.lineWidth = 1;
    ctx.strokeStyle = "red";

    ctx.stroke();

    indices.forEach((index: number, i: number) => {
        if (i % 3 === 0) {
            ctx.beginPath();
            ctx.moveTo(vertices2D[index][0], vertices2D[index][1]);
        } else {
            ctx.lineTo(vertices2D[index][0], vertices2D[index][1]);
        }

        if (i % 3 === 2) {
            ctx.lineTo(vertices2D[indices[i - 2]][0], vertices2D[indices[i - 2]][1]);

            ctx.lineWidth = 0.5;
            ctx.strokeStyle = "blue";
            ctx.stroke();
        }
    });
}

// >>> tri-model

const calculate2DVertices = (surface: any) => {
    if (surface.length < 3) {
        throw new Error('Line, no plane. Cannot triangulate.');
    }

    let vertices2D: any;

    // Shortcut for planes in coordinate system planes, specifically xy plane

    const planeIndex = plane(surface);

    if (planeIndex === 0) {
        vertices2D = surface.map((vertex: number[]) => [vertex[1], vertex[2]]);
    } else if (planeIndex === 1) {
        vertices2D = surface.map((vertex: number[]) => [vertex[0], vertex[2]]);
    } else if (planeIndex === 2) {
        vertices2D = surface.map((vertex: number[]) => [vertex[0], vertex[1]]);
    } else {
        const engorge = 1000000;
        const v1 = new THREE.Vector3(surface[0][0] * engorge, surface[0][1] * engorge, surface[0][2] * engorge);
        const v2 = new THREE.Vector3(surface[1][0] * engorge, surface[1][1] * engorge, surface[1][2] * engorge);
        const v3 = new THREE.Vector3(surface[2][0] * engorge, surface[2][1] * engorge, surface[2][2] * engorge);
        const a = new THREE.Vector3();
        const b = new THREE.Vector3();
        const x = new THREE.Vector3();
        const y = new THREE.Vector3();
        const z = new THREE.Vector3();

        b.subVectors(v3, v2);
        a.subVectors(v1, v2);

        z.crossVectors(a, b);
        x.copy(a);
        y.crossVectors(z, x);

        x.normalize();
        y.normalize();
        z.normalize();

        vertices2D = [];

        for (const vertex of surface) {
            const v = new THREE.Vector3(vertex[0], vertex[1], vertex[2]);
            const raw = [x.dot(v), y.dot(v)]

            vertices2D.push(raw);
        }
    }

    return vertices2D;
}

const triangulationIndices = (vertices2D: number[]) => {
    return earcut(vertices2D.flat(), undefined, 2);
}

class BuildingGeometry {
    cityObjectLoD2: CityObjectLoD2;
    surfaces: any;
    surfaceTypes: string[];

    constructor(cityObjectLoD2: CityObjectLoD2) {
        this.cityObjectLoD2 = cityObjectLoD2;
        this.surfaces = cityObjectLoD2.geometry.transformedBoundaries[0];
        this.surfaceTypes = cityObjectLoD2.geometry.semantics.surfaces.map((surface: any) => surface.type)
    }

    triangleDetails(vertices: number[][]) {
        console.log('Triangle vertices >>>', vertices);

        const a = new THREE.Vector3(vertices[0][0], vertices[0][1], vertices[0][2]);
        const b = new THREE.Vector3(vertices[1][0], vertices[1][1], vertices[1][2]);
        const c = new THREE.Vector3(vertices[2][0], vertices[2][1], vertices[2][2]);
        const ab = new THREE.Vector3();
        const ac = new THREE.Vector3();
        const n = new THREE.Vector3();

        ab.subVectors(b, a);
        ac.subVectors(c, a);
        n.crossVectors(ab, ac);

        console.log('Normalenvektor', n);

        let direction = Math.abs(n.getComponent(0)) < 0.0000000001 ? 0 : Math.acos(n.getComponent(1) / n.getComponent(0)) / (2 * Math.PI) * 360

        // Consider periodicity of sinus

        direction += n.getComponent(0) < 0 && n.getComponent(1) < 0 ? 540 : 0;

        // Normalize to unit circle

        direction %= 360;

        return {
            area: Math.abs(n.length()) / 2,
            angle: (Math.PI / 2 - Math.asin(n.length() / (ab.length() * ac.length()))) / (2 * Math.PI) * 360,
            direction: direction,
        };
    }

    public wallArea() {
        const wallSurfaces = this.surfaces.filter((surface: any, index: number) => this.surfaceTypes[index] === 'WallSurface');
        let area = 0;

        wallSurfaces.forEach((surfaceList: any) => {
            const surface = surfaceList[0];
            const indices = triangulationIndices(calculate2DVertices(surface));

            for (let i = 0; i < indices.length; i = i + 3) {
                area += this.triangleDetails([surface[indices[i]], surface[indices[i + 1]], surface[indices[i + 2]]]).area;
            }
        });

        return area;
    }

    public roofDetails() {
        const details = {
            totalArea: 0,
            surfaces: [] as any,
        };

        const roofSurfaces = this.surfaces.filter((surface: any, index: number) => this.surfaceTypes[index] === 'RoofSurface');

        roofSurfaces.forEach((surfaceList: any) => {
            const surface = surfaceList[0];
            const indices = triangulationIndices(calculate2DVertices(surface));

            for (let i = 0; i < indices.length; i = i + 3) {
                console.log('Checking roof >>>');

                const surfaceDetails = this.triangleDetails([surface[indices[i]], surface[indices[i + 1]], surface[indices[i + 2]]]);
                details.totalArea += surfaceDetails.area;

                details.surfaces.push(surfaceDetails);
            }
        });

        return details;
    }
}

// <<< tri-model

export default function Environment3DProperties(properties: Properties) {
    const { t } = useTranslation();
    const mapContainerRef = useRef() as any;
    const mapRef = useRef() as any;
    const [groups, setGroups] = useState([]) as any;
    const [selectedCityObject, setSelectedCityObject] = useState([]) as any;
    const [selectedBuildingGeometry, setSelectedBuildingObject] = useState() as any;
    const [shell, setShell] = useState(null) as any;
    const [surfaceIndex, setSurfaceIndex] = useState(0);
    const [indices, setIndices] = useState([]) as any;
    const [debugGeometry, setDebugGeometry] = useState(false);

    const createShell = (shell: any, surfaceTypes: any, offsetWGS: any, offsetUTM: any, userData: any) => {
        const group = new THREE.Group();

        group.userData = userData;

        shell.forEach((surfaces: any, index: number) => {
            try {
                let surface = surfaces[0];

                const scale = 0.04;

                surface = surface.map((vertex: any) => [(vertex[0] - offsetUTM[0]) * scale, (vertex[1] - offsetUTM[1]) * scale, (vertex[2] - 100) * scale]);

                const surfaceType = surfaceTypes ? surfaceTypes[index] : 'UnknownSurface';
                let color = surfaceType === 'RoofSurface' ? roofColor :
                    (surfaceType === 'WallSurface') ? wallColor : (surfaceType === 'GroundSurface' ? groundColor : wallColor);

                const indices = triangulationIndices(calculate2DVertices(surface));

                const vertices = new Float32Array(surface.flat());
                const uvs = new Float32Array([
                    0, 0,  // UV A
                    0, 1,  // UV B
                    1, 0,  // UV C
                    1, 1   // UV D
                ]);

                // flat face normals

                pA.set(surface[0][0], surface[0][1], surface[0][2]);
                pB.set(surface[1][0], surface[1][1], surface[1][2]);
                pC.set(surface[2][0], surface[2][1], surface[2][2]);

                cb.subVectors(pC, pB);
                ab.subVectors(pA, pB);
                cb.cross(ab);

                cb.normalize();

                const nx = cb.x;
                const ny = cb.y;
                const nz = cb.z;
                const normals = [];

                normals.push(nx, ny, nz);
                normals.push(nx, ny, nz);
                normals.push(nx, ny, nz);

                if (surface.length === 4) {
                    normals.push(nx, ny, nz);

                } else if (surface.length === 5) {
                    normals.push(nx, ny, nz);
                    normals.push(nx, ny, nz);
                }

                // for (let i = 0; i < surface.length; ++i) {
                //     normals.push(nx, ny, nz);
                // }

                // BufferGeometry

                let geometry = new THREE.BufferGeometry();

                geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
                geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3)/*.onUpload( disposeArray )*/);
                //geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
                geometry.setIndex(indices);

                let wireframe = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({
                    color: 0x777777,
                    specular: 0xffffff,
                    shininess: 10,
                    reflectivity: 0.2,
                    wireframe: true,
                    side: THREE.DoubleSide,
                }));

                group.add(wireframe);
                let plane = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({
                    color,
                    specular: 0xffffff,
                    shininess: 10,
                    reflectivity: 0.2,
                    side: THREE.DoubleSide,
                }));

                group.add(plane);
            } catch (error) {
                console.log(error);
            }
        });

        // @ts-ignore
        const object = window.tb.Object3D({ obj: group });

        object.setCoords(offsetWGS);
        object.addEventListener('SelectedChange', (event: any) => {
            const newSelectedCityObject = event.detail.userData.obj.userData;

            setSelectedCityObject(newSelectedCityObject);
            setSelectedBuildingObject(new BuildingGeometry(newSelectedCityObject));
            setShell(newSelectedCityObject.geometry.transformedBoundaries[0].map((surfaces: any) => {
                let surface = surfaces[0];

                const scale = 0.03;

                return surface.map((vertex: any) => [(vertex[0] - offsetUTM[0]) * scale, (vertex[1] - offsetUTM[1]) * scale, (vertex[2] - 100) * scale]);
            }));
            setSurfaceIndex(0);
        }, true);

        // @ts-ignore
        window.tb.add(object);
    }

    useEffect(() => {
        if (!shell) {
            return;
        }

        const vertices2D = calculate2DVertices(shell[surfaceIndex]);
        const indices = triangulationIndices(vertices2D);

        setIndices(indices);

        drawGeometry(vertices2D, indices);
    }, [shell, surfaceIndex]);

    useEffect(() => {
        // @ts-ignore
        if (window.tb) {
            try {
                // @ts-ignore
                //window.tb.dispose();
            } catch (error) {
                console.log('Could not dispose: ', error);
            }
        }

        mapRef.current = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/light-v11',
            center: [properties.object.shape[0].lon, properties.object.shape[0].lat],
            zoom: 15.4,
            pitch: 64.9,
            bearing: 172.5,
            antialias: true
        });

        mapRef.current.on('style.load', () => {
            mapRef.current.addLayer({
                id: 'custom-threebox-model',
                type: 'custom',
                renderingMode: '3d',
                onAdd: async () => {
                    // @ts-ignore
                    window.tb = new Threebox(
                        mapRef.current,
                        mapRef.current.getCanvas().getContext('webgl'),
                        {
                            defaultLights: true,
                            enableSelectingObjects: true, //change this to false to disable 3D objects selection
                            //enableTooltips: true, // change this to false to disable default tooltips on fill-extrusion and 3D models
                        }
                    );

                    // Patch lighting 

                    // @ts-ignore
                    window.tb.scene.remove(window.tb.lights.ambientLight);
                    // @ts-ignore
                    window.tb.scene.remove(window.tb.lights.dirLight);
                    // @ts-ignore
                    window.tb.scene.remove(window.tb.lights.dirLight);
                    // @ts-ignore
                    window.tb.lights.dirLight = new THREE.DirectionalLight(new THREE.Color('hsl(0, 0%, 100%)'), 0.25);;
                    // @ts-ignore
                    window.tb.scene.add(window.tb.lights.dirLight);
                    // @ts-ignore
                    window.tb.lights.ambientLight = new THREE.AmbientLight(new THREE.Color('hsl(0, 0%, 100%)'), 0.8);
                    // @ts-ignore
                    window.tb.scene.add(window.tb.lights.ambientLight);

                    let cityObjects = await userAPI.getCityObjects(properties.object.shape[0].lat, properties.object.shape[0].lon, 300);

                    //cityObjects = cityObjects.slice(0, Math.min(cityObjects.length, 300));

                    const focusObject = 2;
                    let objectIndex = 0;

                    for (const cityObject of cityObjects) {
                        ++objectIndex;

                        if (objectIndex - 1 !== focusObject) {
                            //continue;
                        }

                        const offsetUTM = proj4(projections['WGS84'], projections['ETRS89'], [cityObject.minLon, cityObject.minLat]);

                        for (const shell of cityObject.geometry.transformedBoundaries) {
                            createShell(shell, cityObject.geometry.semantics.surfaces.map((surface: any) => surface.type), [cityObject.minLon, cityObject.minLat], offsetUTM, cityObject);
                        }
                    }
                },
                render: () => {
                    try {
                        // @ts-ignore                    
                        window.tb.update();
                    } catch (error) {
                    }
                }
            });
        });
    }, [properties.object]);

    return <div className='positionRelative'>
        <div id="map" className="flexGrow1" ref={mapContainerRef} style={{ height: '400px', width: '600px' }}></div>
        {selectedCityObject && selectedBuildingGeometry ?
            <div className='marginTopM'>
                <div className="displayFlex alignItemsCenter">
                    <div className="flexGrow1 displayFlex">
                        <div className='headerL'>{selectedCityObject.type}</div>
                    </div>
                    <ActionButton iconProps={{ iconName: 'Design' }} onClick={() => {
                        setDebugGeometry(!debugGeometry);
                    }}></ActionButton>
                </div>
                <div className='marginTopM displayFlex gapM'>
                    <Section className="flex1" title="Walls">
                        <InfoList list={[
                            {
                                label: 'Wall Area',
                                value: numberFormatArea.format(selectedBuildingGeometry.wallArea()),
                                suffix: 'm²',
                            },
                        ]}></InfoList>
                    </Section>
                    <Section className="flex1" title="Roof Parts">

                        <InfoList list={[
                            {
                                label: 'Roof Type',
                                value: roofTypeLabel(selectedCityObject.roofType),
                            },
                            {
                                label: 'Measured Height',
                                value: numberFormatArea.format(selectedCityObject.measuredHeight),
                                suffix: 'm²',
                            },
                            {
                                label: 'Total Roof Area',
                                value: numberFormatArea.format(selectedBuildingGeometry.roofDetails().totalArea),
                                suffix: 'm²',
                            },
                            ...selectedBuildingGeometry.roofDetails().surfaces.map((details: any) => [
                                {
                                    label: 'Roof Part',
                                    value: numberFormatArea.format(details.area),
                                    suffix: 'm²',
                                },
                                {
                                    label: 'Tilt',
                                    value: numberFormatArea.format(details.angle),
                                    suffix: '°',
                                },
                                {
                                    label: 'Direction',
                                    value: numberFormatArea.format(details.direction),
                                    suffix: '°',
                                },
                            ]).flat(2)
                        ]}></InfoList>
                    </Section>
                </div>
            </div>
            :
            <div className='marginTopM'>No object selected</div>}
        {shell !== null && debugGeometry ?
            <div className="positionFixed width500 height300 borderNeutral" style={{ bottom: '0px', left: '0px', backgroundColor: 'white', zIndex: 999 }}>
                <div className="paddingXS displayFlex alignItemsCenter gapM">
                    <ActionButton iconProps={{ iconName: 'CalculatorSubtract' }} onClick={() => {
                        setSurfaceIndex(Math.max(surfaceIndex - 1, 0))
                    }}></ActionButton>
                    <div>{surfaceIndex + 1}/{shell.length}</div>
                    <ActionButton iconProps={{ iconName: 'Add' }} onClick={() => {
                        setSurfaceIndex(Math.min(surfaceIndex + 1, shell.length - 1))
                    }}>
                    </ActionButton>
                    <div className='textXS'># Vertices {shell[surfaceIndex].length}</div> <div className="paddingS textXS">[{indices.join(',')}]</div>
                    <div className='textXS'>Object #{selectedCityObject.id}</div>
                </div>
                <div className="paddingXS textXS">Scaled Vertices: [{shell[surfaceIndex].map((vertex: any) => `[${(vertex[0] * 1000).toFixed(0)}, ${(vertex[1] * 1000).toFixed(0)}, ${(vertex[0] * 1000).toFixed(0)}]`).join(', ')}]</div>
                <canvas id="geometryCanvas" width={500} height={300}></canvas>
            </div>
            :
            <></>}
    </div>;
}