import { Asset, EntityQuery, is, Query } from "@marc.gille-sepehri/tri-model";
import { useTranslation } from "react-i18next";
import { userAPI } from "../../UserAPI";
import { useEffect, useState } from "react";
import { TreeState, TreeTable } from "cp-react-tree-table";
import { ActionButton, SpinButton, TextField } from "@fluentui/react";

class Properties {
  building: any;
}

const numberFormat = new Intl.NumberFormat('de');

export default function EnergyAndCO2Properties(properties: Properties) {
  const { t } = useTranslation();
  const [din276Metadata, setDIN276Metadata] = useState([]) as any;
  const [assets, setAssets] = useState([]) as any;
  const [tree, setTree] = useState([]/*MOCK_DATA*/) as any;
  const [treeData, setTreeData] = useState(TreeState.create([]/*MOCK_DATA*/)) as any;
  const [expanded, setExpanded] = useState(false);
  const [co2Equivalent, setCO2Equivalent] = useState(0.38);

  useEffect(() => {
    const call = async () => {
      const din276Metadata = await userAPI.getDIN276Metadata();

      setDIN276Metadata(din276Metadata);

      const assets = await userAPI.query(new Query(new EntityQuery(Asset.type, [], undefined,
        // TODO Complete address filter
        is('postalCode').equal(properties.building.address.postalCode))));

      console.log('Assets', assets);

      setAssets(assets);

      const tree: any = buildTree(assets, din276Metadata);

      setTree(tree);
      setTreeData(TreeState.create(tree));
    };

    call();
  }, [properties]);

  const energyConsumption = (asset: any) => {
    if (asset.schemaCategory === '434') {
      return 1500 * 720 * 12;
    } else {
      return 0;
    }

  };
  const buildTree = (assets: any, din276Metadata: any) => {
    let totalConsumption = 0;
    let totalCO2Equivalent = 0;
    const tree = assets.reduce((tree: any, asset: any) => {
      const category = asset.schemaCategory.split('.')[0];
      const categoryMetadata = din276Metadata.find((metadata: any) => metadata.code === category);
      const categoryName = categoryMetadata.name.split(' ')[0];

      tree[category] = tree[category] || { data: { name: `${category} ${categoryName}`, consumption: 0, co2: co2Equivalent * 4711 }, children: [] };

      let slot = tree[category].children.find((slot: any) => slot.name === asset.schemaCategory);

      if (!slot) {
        const subCategoryMetadata = din276Metadata.find((metadata: any) => metadata.code === asset.schemaCategory);
        const subCategoryName = subCategoryMetadata.name.split(' ')[0];

        slot = { data: { name: `${asset.schemaCategory} ${subCategoryName}`, consumption: 0, accuracy: 1, co2: 0, count: 1 } };

        tree[category].children.push(slot);
      } else {
        ++slot.count;
      }

      slot.data.consumption += energyConsumption(asset);
      slot.data.co2 = slot.data.consumption * co2Equivalent;
      tree[category].data.consumption += energyConsumption(asset);
      tree[category].data.co2 = tree[category].data.consumption * co2Equivalent;
      totalConsumption += energyConsumption(asset);
      totalCO2Equivalent = totalConsumption * co2Equivalent;

      return tree;
    }, {});

    return [{ data: { total: true, consumption: totalConsumption, co2: totalCO2Equivalent } }, ...Object.values(tree)];
  }

  const applyCO2Equivalent = (equivalent: number) => {
    for (const category of tree) {
      category.data.co2 = equivalent * category.data.consumption;

      if (category.children) {
        for (const slot of category.children) {
          slot.co2 = equivalent * slot.consumption;
        }
      }
    }

    setTree(tree);
    setTreeData(TreeState.create(tree));
  };

  const handleOnExpandAll = () => {
    setTreeData(TreeState.expandAll(treeData));
    setExpanded(true);
  };

  const handleOnCollapseAll = () => {
    setTreeData(TreeState.collapseAll(treeData));
    setExpanded(false);
  };

  const handleOnChange = (newValue: any) => {
    setTreeData(newValue);
  }

  const renderIndexCell = (row: any) => {
    if (row.data.total) {
      return <div className="marginTopS marginBottomS fontWeightBold">Gesamt</div>
    } else {
      return <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px' }}
        className={row.metadata.hasChildren ? 'width200 with-children' : 'width250 without-children'}>

        {(row.metadata.hasChildren)
          ? (
            <button className="toggle-button" onClick={row.toggleChildren}></button>
          )
          : ''
        }

        <span>{row.data.name}</span>
      </div>
    }
  };

  const renderProductCell = (row: any) => {
    if (row.children) {
      return <div></div>;
    } else {
      return <span>{row.data.product || '-'}</span>;
    }
  }

  const renderCountCell = (row: any) => {
    return <div className={`flexGrow1 textAlignRight ${row.data.total ? 'marginTopS marginBottomS fontWeightBold' : ''}`}>{row.data.count}</div>
  }

  const renderConsumptionCell = (row: any) => {
    const showAccuracy = !row.children && row.data.accuracy > 0;

    return <div className={`flexGrow1 textAlignRight ${row.data.total ? 'marginTopS marginBottomS fontWeightBold' : ''}`}>{numberFormat.format(row.data.consumption)} {showAccuracy ? <sup>{row.data.accuracy}</sup> : <sup>&nbsp;</sup>}</div>;
  }

  const renderCO2Cell = (row: any) => {
    return <div className={`flexGrow1 textAlignRight ${row.data.total ? 'marginTopS marginBottomS fontWeightBold' : ''}`}>{numberFormat.format(row.data.co2)}</div>;
  }

  return <div>
    <div className="marginTopM headerL">Energie- und CO2-Bilanz aus Gerätekomponenten</div>
    <div className="marginTopM displayFlex gapS">
      <div className="displayFlex">
        {expanded
          ?
          <ActionButton iconProps={{ iconName: 'FolderHorizontal' }} onClick={handleOnCollapseAll}></ActionButton>
          :
          <ActionButton iconProps={{ iconName: 'OpenFolderHorizontal' }} onClick={handleOnExpandAll}></ActionButton>
        }
      </div>
      <div className="displayFlex flexGrow1">
        <TextField placeholder="Filter eingeben" underlined styles={{ root: { width: 250 } }} />
      </div>
      <div className="displayFlex justifyContentEnd alignItemsCenter gapS">
        <SpinButton
          label="CO2-Äquivalent"
          step={0.1}
          value={`${co2Equivalent}`}
          onChange={(_: any, value: any) => {
            console.log('Value', value);
            setCO2Equivalent(Number(value));
            applyCO2Equivalent(Number(value));
          }}
          styles={{ spinButtonWrapper: { width: 75 } }}
        />
        <div className="textM">kg/kWh</div>
      </div>
    </div>
    <div className="marginTopM">
      <TreeTable
        value={treeData}
        onChange={handleOnChange}>
        <TreeTable.Column
          renderCell={renderIndexCell}
          renderHeaderCell={() => <span>Verbrauchertyp</span>}
          grow={1}
          basis={'400px'} />
        <TreeTable.Column
          renderCell={renderProductCell}
          renderHeaderCell={() => <span>Produkt</span>}
          grow={1}
          basis={'230px'} />
        <TreeTable.Column
          renderCell={renderCountCell}
          renderHeaderCell={() => <span>Anzahl</span>}
          grow={0}
          basis={'100px'} />
        <TreeTable.Column
          renderCell={renderConsumptionCell}
          renderHeaderCell={() => <span>Verbrauch (kWh/a)</span>}
          grow={0}
          basis={'180px'} />
        <TreeTable.Column
          renderCell={renderCO2Cell}
          renderHeaderCell={() => <span>CO2 (kg/a)</span>}
          grow={0}
          basis={'180px'} />
      </TreeTable>
    </div>
    <div className="marginTopM textS"><sup>1</sup> Geschätzt aus Gerätetyp&nbsp;&nbsp;<sup>2</sup> Geschätzt aus Typ/Produktangaben&nbsp;&nbsp;<sup>3</sup> Gemessen</div>
  </div >;
}
