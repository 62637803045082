import './App.sass';
import L, { LatLng } from "leaflet";
import { useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles } from "@fluentui/react-components";
import {
    LayerGroup,
    LayersControl,
    MapContainer,
    Marker,
    Rectangle,
    Polygon,
    Polyline,
    TileLayer,
    useMapEvent,
    useMap,
    Tooltip,
} from "react-leaflet";
import { useEventHandlers } from '@react-leaflet/core';
import marker from './marker.svg';
import markerChargingStation from './marker-charging-station.svg';
import markerUniversity from './marker-university.svg';
import markerAdvertisementColumn from './marker-advertisement-column.svg';
import markerBillboard from './marker-billboard.svg';
import tinycolor from "tinycolor2";
import { userAPI } from './UserAPI';
import { AdvertisementColumn, Billboard, ChargingStation, PoI, PoIType, University } from '@marc.gille-sepehri/tri-model';
import ClusterWithSuperCluster from './map/ClusterWithSuperCluster';

interface Properties {
    setObject?: any;
    showMiniMap?: boolean;
    objectClass?: string;
    query: string | null;
    data?: any;
    options?: any;
    pois?: any[]
}

const center = new LatLng(50.10557929585495, 8.696741299147176);

const POSITION_CLASSES: any = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}
const BOUNDS_STYLE = { weight: 1 }

const styleUrl = 'mapbox://styles/marcgille/clxgwajhc008701pcewze3l4s';
const tileUrl = 'https://api.mapbox.com/styles/v1/marcgille/clxgwajhc008701pcewze3l4s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFyY2dpbGxlIiwiYSI6ImNsZWlvazNkYTAzaWYzb25yZjBmejB5NmMifQ.XF9Z60UyGqXLztoIjH1SIA';

const getStyle = (options: any, object: any, heatmapMax: number, selected: boolean, border: boolean = true) => {
    if (options.heatmapField) {
        return {
            color: border ? 'black' : undefined,
            fillColor: options.heatmapField ? percentToColor(object[options.heatmapField] / heatmapMax * 100).toString() : '#649aaa',
            weight: 1,
            fill: true,
            fillOpacity: selected ? 1.0 : 0.3,
        }
    } else if (options && options.categoryField) {
        const entry = options.categoryField.values.find((entry: any) => entry.key === object[options.categoryField.field]);

        return {
            color: entry ? entry.color : '#649aaa',
            fillColor: entry ? entry.color : '#649aaa',
            weight: 1,
            fill: true,
            fillOpacity: selected ? 1.0 : 0.3,
        }
    } else {
        return {
            color: '#649aaa',
            fillColor: '#649aaa',
            weight: 1,
            fill: true,
            fillOpacity: selected ? 1.0 : 0.3,
        }
    }
};

const setStyle = (e: any, style: any) => {
    let layer = e.target;

    layer.setStyle(style);
};
const markerIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    popupAnchor: [-0, -0],
    //iconSize: [32, 45],
    iconSize: [16, 22],
});
const percentToColor = (value: number) => {
    const minimumColor = tinycolor('#00FF00');
    const maximumColor = tinycolor('#FF0000');
    const step = (maximumColor.toHsl().h - minimumColor.toHsl().h) / 100;

    return minimumColor.spin(step * value);
};

function MinimapBounds({ parentMap, zoom }: { parentMap: any, zoom: any }) {
    const minimap = useMap()

    // Clicking a point on the minimap sets the parent's map center
    const onClick = useCallback(
        (e: any) => {
            parentMap.setView(e.latlng, parentMap.getZoom())
        },
        [parentMap],
    )

    useMapEvent('click', onClick)

    // Keep track of bounds in state to trigger renders

    const [bounds, setBounds] = useState(parentMap.getBounds())

    const onChange = useCallback(() => {
        setBounds(parentMap.getBounds())
        // Update the minimap's view to match the parent map's center and zoom
        minimap.setView(parentMap.getCenter(), zoom)
    }, [minimap, parentMap, zoom])

    // Listen to events on the parent map

    const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])

    // @ts-ignore
    useEventHandlers({ instance: parentMap }, handlers)

    return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}

function MinimapControl({ position, zoom }: { position: any, zoom: any }) {
    const parentMap = useMap()
    const mapZoom = zoom || 0

    // Memoize the minimap so it's not affected by position changes
    const minimap = useMemo(
        () => (
            <MapContainer
                style={{ height: 200, width: 200 }}
                center={parentMap.getCenter()}
                zoom={mapZoom}
                dragging={false}
                doubleClickZoom={true}
                scrollWheelZoom={false}
                attributionControl={false}
                zoomControl={false}>
                <TileLayer url={tileUrl} />
                <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
            </MapContainer>
        ),
        [],
    )

    const positionClass =
        (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar">{minimap}</div>
        </div>
    )
}

export default function Map(properties: Properties) {
    const [map, setMap] = useState(null as any);
    const [bounds, setBounds] = useState(undefined);
    const [bridges, setBridges] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [areas, setAreas] = useState([]);
    const [shapes, setShapes] = useState([]);
    const [plotsOfLand, setPlotsOfLand] = useState([]);
    const [options] = useState(properties.options || {});
    const [heatmapMax, setHeatmapMax] = useState(100);
    const [heatmapMin, setHeatmapMin] = useState(0);
    const [pieChartData, setPieChartData] = useState() as any;
    const [selectedEntity, setSelectedEntity] = useState();

    const calculateHeatmapDetails = (features: any) => {
        if (!options.heatmapField || !features || features.length === 0) {
            return;
        }

        let minimum = Number.MAX_VALUE;
        let maximum = Number.MIN_VALUE;

        features.forEach((feature: any) => {
            const value = feature[options.heatmapField];

            if (value !== undefined) {
                minimum = Math.min(minimum, value);
                maximum = Math.max(maximum, value);
            }
        });

        setHeatmapMin(minimum);
        setHeatmapMax(maximum);
    }

    const adjustBounds = (features: any) => {
        let minimumLat = Number.MAX_VALUE;
        let maximumLat = Number.MIN_VALUE;
        let minimumLon = Number.MAX_VALUE;
        let maximumLon = Number.MIN_VALUE;

        features.forEach((feature: any) => {
            if (feature.boundingBox) {
                minimumLat = Math.min(minimumLat, feature.boundingBox[0][0]);
                maximumLat = Math.max(maximumLat, feature.boundingBox[1][0]);
                minimumLon = Math.min(minimumLon, feature.boundingBox[0][1]);
                maximumLon = Math.max(maximumLon, feature.boundingBox[1][1]);
            } else if (feature.shape) {
                feature.shape.forEach((entry: any) => {
                    if (entry.forEach) {
                        entry.forEach((point: any) => {
                            minimumLat = Math.min(minimumLat, point[0]);
                            maximumLat = Math.max(maximumLat, point[0]);
                            minimumLon = Math.min(minimumLon, point[1]);
                            maximumLon = Math.max(maximumLon, point[1]);
                        });
                    } else if (entry.lat) {
                        minimumLat = Math.min(minimumLat, entry.lat);
                        maximumLat = Math.max(maximumLat, entry.lat);
                        minimumLon = Math.min(minimumLon, entry.lon);
                        maximumLon = Math.max(maximumLon, entry.lon);
                    }
                });
            }
        });

        setBounds([[minimumLat, minimumLon], [maximumLat, maximumLon]] as any);
    }

    useEffect(() => {
        const f = async () => {

            if (properties.objectClass === 'bridge') {
                const newBridges = await userAPI.getBridges(properties.query);

                setBridges(newBridges);

                newBridges.forEach((bridge: any) => {
                    bridge.class = 'Bridge';
                });
            }
        };

        f().catch((error) => console.error(error))
    }, [map, properties.objectClass, properties.query]);

    useEffect(() => {
        const f = async () => {
            if (properties.objectClass === 'building') {
                let newBuildings;

                newBuildings = properties.data.buildings;

                setBuildings(newBuildings);

                newBuildings.forEach((building: any) => {
                    building.class = 'Building';
                });

                console.log('Buildings >>>', newBuildings);

                calculateHeatmapDetails(newBuildings);
                adjustBounds(newBuildings);
            }
        };

        f().catch((error) => console.error(error))
    }, [map]);

    useEffect(() => {
        const f = async () => {
            if (properties.objectClass === 'Tile') {
                let newAreas;

                newAreas = properties.data.areas;

                // TODO Hack

                newAreas.forEach((area: any) => {
                    area.class = 'Tile';
                });

                setAreas(newAreas);
                calculateHeatmapDetails(newAreas);
                adjustBounds(newAreas);
            }
        };

        f().catch((error) => console.error(error))
    }, []);

    useEffect(() => {
        const f = async () => {
            if (properties.objectClass === 'LocalGovernment' && properties.data.localGovernments) {
                let newLocalGovernments;
                newLocalGovernments = properties.data.localGovernments;

                // TODO Hack

                newLocalGovernments.forEach((area: any) => {
                    area.class = 'LocalGovernment';
                });

                setShapes(newLocalGovernments);
                adjustBounds(newLocalGovernments);
            }
        };

        f().catch((error) => console.error(error))
    }, []);

    useEffect(() => {
        const f = async () => {
            if (properties.objectClass === 'PostalCode' && properties.data.postalCodes) {
                let newPostalCodes;
                newPostalCodes = properties.data.postalCodes;

                // TODO Hack

                newPostalCodes.forEach((postalCode: any) => {
                    postalCode.class = 'PostalCode';
                });

                console.log('Postal codes >>>', newPostalCodes);

                setShapes(newPostalCodes);
                adjustBounds(newPostalCodes);
            }
        };

        f().catch((error) => console.error(error))
    }, []);

    useEffect(() => {
        const f = async () => {
            if (properties.objectClass === 'PlotOfLand' && properties.data.plotsOfLand) {
                let newPlotsOfLand;
                newPlotsOfLand = properties.data.plotsOfLand;

                // TODO Hacks

                newPlotsOfLand.forEach((plotOfLand: any) => {
                    plotOfLand.class = 'PlotOfLand';
                });

                console.log('Plots of land >>>', newPlotsOfLand);

                setPlotsOfLand(newPlotsOfLand);
                adjustBounds(newPlotsOfLand);
            }
        };

        f().catch((error) => console.error(error))
    }, []);

    useEffect(() => {
        const f = async () => {
            if (properties.data.points && properties.options.pieChart) {

                console.log('Pie Chart Data ====>>>', properties.data.points);

                setPieChartData(properties.data.points)
                //adjustBounds(newPlotsOfLand);
            }
        };

        f().catch((error) => console.error(error))
    }, []);

    useEffect(() => {
        try {
            if (map && bounds) {
                map.fitBounds(bounds);
            }
        } catch (error) {
            console.error(error);
        }
    }, [map, bounds]);

    const poiMarker = (poi: PoI) => {
        const iconSize: any = poi === selectedEntity ? [50, 50] : [35, 35];

        switch (poi.poiType) {
            case PoIType.chargingPoint: return new L.Icon({
                iconUrl: markerChargingStation,
                iconRetinaUrl: markerChargingStation,
                popupAnchor: [-0, -0],
                iconSize,
            });
            case PoIType.university: return new L.Icon({
                iconUrl: markerUniversity,
                iconRetinaUrl: markerUniversity,
                popupAnchor: [-0, -0],
                iconSize,
            });
            case PoIType.advertisementColumn: return new L.Icon({
                iconUrl: markerAdvertisementColumn,
                iconRetinaUrl: markerAdvertisementColumn,
                popupAnchor: [-0, -0],
                iconSize,
            });
            case PoIType.billboard: return new L.Icon({
                iconUrl: markerBillboard,
                iconRetinaUrl: markerBillboard,
                popupAnchor: [-0, -0],
                iconSize,
            });
        }
    }

    const poiTooltip = (poi: PoI) => {
        switch (poi.poiType) {
            case PoIType.chargingPoint: return `Ladestation von ${(poi as ChargingStation).operator} mit ${(poi as ChargingStation).ratedPower} kW Nennleistung.`;
            case PoIType.university: return `${(poi as University).name}, gegründet ${(poi as University).foundationYear} mit ${(poi as University).numberOfStudents} Studierenden.`;
            case PoIType.advertisementColumn: return `Litfaßsäule, ${(poi as AdvertisementColumn).lit ? 'beleuchtet' : 'unbeleuchtet'}`;
            case PoIType.billboard: return `Anzeigetafel ${(poi as Billboard).operator || ''}, ${(poi as AdvertisementColumn).lit ? 'beleuchtet' : 'unbeleuchtet'}`;
        }
    }

    const displayMap =
        () => (
            <div className="">
                <MapContainer
                    ref={setMap}
                    className="mapContainer" style={{ height: `80vh` }} bounds={bounds} center={bounds ? undefined : center} zoom={bounds ? undefined : 13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url={tileUrl}
                    />
                    <LayersControl position="topright">
                        <LayersControl.Overlay checked name="Points of Interest">
                            <LayerGroup>
                                <>
                                    {(properties.pois || []).map((poi: any) => (
                                        <Marker position={new LatLng(poi.lat, poi.lon)}
                                            opacity={1}
                                            icon={poiMarker(poi)}
                                            eventHandlers={
                                                { click: () => properties.setObject ? properties.setObject(poi) : null }
                                            }>

                                            <Tooltip>{poiTooltip(poi)}</Tooltip>
                                        </Marker>
                                    ))}
                                </>
                            </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Bridges">
                            <LayerGroup>
                                <>
                                    {bridges.map((bridge: any) => (
                                        <Marker position={new LatLng(bridge.lat, bridge.lon)}
                                            opacity={0.3}
                                            icon={markerIcon}
                                            eventHandlers={{ click: () => properties.setObject ? properties.setObject(bridge) : null }}></Marker>
                                    ))}
                                </>
                            </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Buildings">
                            <LayerGroup>
                                <>
                                    {buildings.map((building: any, index: number) => (
                                        <Polyline key={`buildingShape${index}`} pathOptions={getStyle(options, building, heatmapMax, false)}
                                            positions={building.shape.map((point: any) => new LatLng(point.lat, point.lon))}
                                            eventHandlers={{
                                                mouseover: (event: any) => { setStyle(event, getStyle(options, building, heatmapMax, true)) },
                                                mouseout: (event: any) => { setStyle(event, getStyle(options, building, heatmapMax, selectedEntity === building)) },
                                                click: () => {
                                                    if (properties.setObject) {
                                                        properties.setObject(building);
                                                    }

                                                    setSelectedEntity(building);
                                                }
                                            }}>
                                            {options.heatmapFieldLabel && options.heatmapField
                                                ?
                                                <Tooltip>{options.heatmapFieldLabel}: {building[options.heatmapField]}</Tooltip>
                                                :
                                                <></>
                                            }
                                        </Polyline>))}
                                </>
                            </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Areas">
                            <LayerGroup>
                                <>
                                    {areas.map((area: any, index: number) => (
                                        <Rectangle key={`areaShape${index}`}
                                            bounds={area.boundingBox}
                                            pathOptions={getStyle(options, area, heatmapMax, false, false)}
                                            eventHandlers={{
                                                mouseover: (event: any) => {
                                                    setStyle(event, getStyle(options, area, heatmapMax, true))
                                                },
                                                mouseout: (event: any) => { setStyle(event, getStyle(options, area, heatmapMax, selectedEntity === area, false)) },
                                                click: () => {
                                                    if (properties.setObject) {
                                                        properties.setObject(area);
                                                    }

                                                    setSelectedEntity(area);
                                                }
                                            }}>
                                            {options.heatmapFieldLabel && options.heatmapField
                                                ?
                                                <Tooltip>{options.heatmapFieldLabel}: {area[options.heatmapField]}</Tooltip>
                                                :
                                                <></>}
                                        </Rectangle>
                                    ))}
                                </>
                            </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Local Governments">
                            <LayerGroup>
                                <>
                                    {shapes.map((shape: any) => (
                                        shape.shape.map((coordinates: any, index: number) => {
                                            return <Polygon
                                                key={`localGovernments${index}`}
                                                positions={coordinates}
                                                pathOptions={{
                                                    color: undefined,
                                                    fillColor: '#505F90',
                                                    fillOpacity: 0.2,
                                                }}
                                                eventHandlers={{
                                                    click: () => {
                                                        if (properties.setObject) {
                                                            properties.setObject(shape);
                                                        }

                                                        setSelectedEntity(shape);
                                                    }
                                                }}
                                            />
                                        })
                                    ))}
                                </>
                            </LayerGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Grundstücke">
                            <LayerGroup>
                                <>
                                    {plotsOfLand.map((plotOfLand: any, index: number) => (
                                        <Polyline key={`plotOfLandShape${index}`}
                                            pathOptions={getStyle(options, plotOfLand, heatmapMax, false)}
                                            positions={plotOfLand.shape.map((point: any) => new LatLng(point.lat, point.lon))}
                                            eventHandlers={{
                                                mouseover: (event: any) => { setStyle(event, getStyle(options, plotOfLand, heatmapMax, true)) },
                                                mouseout: (event: any) => { setStyle(event, getStyle(options, plotOfLand, heatmapMax, selectedEntity === plotOfLand)) },
                                                click: () => {
                                                    if (properties.setObject) {
                                                        properties.setObject(plotOfLand);
                                                    }

                                                    setSelectedEntity(plotOfLand);
                                                }
                                            }}>
                                            {options.heatmapFieldLabel && options.heatmapField
                                                ?
                                                <Tooltip>{options.heatmapFieldLabel}: {plotOfLand[options.heatmapField]}</Tooltip>
                                                :
                                                <></>
                                            }
                                        </Polyline>))}
                                </>
                            </LayerGroup>
                        </LayersControl.Overlay>
                    </LayersControl>
                    {properties.showMiniMap
                        ?
                        <MinimapControl position="topright" zoom="1" />
                        :
                        <></>}
                    {options.heatmapField
                        ?
                        <div className="legend">
                            <div className="title">{properties.options && properties.options.legendTitle ? properties.options.legendTitle : 'Legend'}</div>
                            <div className="marginTopM">
                                {[...Array(5)].map((entry, index) => {
                                    return <div className="marginBottomS displayFlex alignItemsCenter">
                                        <div className="width20 height20" style={{ backgroundColor: percentToColor(index / 5 * 100).toString() }}></div>
                                        <div className="marginLeftS">&le; {(heatmapMax / 5 * (index + 1)).toFixed(0)}</div>
                                    </div>
                                })}
                            </div>

                        </div>
                        :
                        <></>
                    }
                    {options.categoryField
                        ?
                        <div className="legend">
                            <div className="title">Nutzung</div>
                            <div className="marginTopM">
                                {options.categoryField.values.map((value: any, index: number) => {
                                    return <div key={`categoryField${index}`} className="marginBottomS displayFlex alignItemsCenter">
                                        <div className="width20 height20" style={{ backgroundColor: value.color }}></div>
                                        <div className="marginLeftS">{value.label}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {pieChartData
                        ?
                        <ClusterWithSuperCluster data={pieChartData} options={properties.options.pieChart}/>
                        :
                        <></>
                    }                    
                </MapContainer>
            </div>
        );

    return (
        <div>
            {displayMap()}
        </div>
    );
}