import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { useState } from "react";
import InfoList from "./InfoList";
import { Billboard} from "@marc.gille-sepehri/tri-model";

interface Properties {
  billboard: Billboard;
}

export default function BillboardProperties(properties: Properties) {
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: 'General',
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    }
  ];

  console.log(properties.billboard);

  return (
    <div>
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div>
              <InfoList list={[
                {
                  label: 'Betreiber',
                  value: properties.billboard.operator,
                }, {
                  label: 'Modell',
                  value: properties.billboard.model,
                }, {
                  label: 'Beleuchtet',
                  value: `${properties.billboard.lit ? 'Ja' : 'Nein'}`,
                }]}></InfoList>
            </div>           
          </div>
        }
      })()}
    </div>
  );
}
