import { makeStyles, shorthands } from "@fluentui/react-components";
import Chart from 'react-apexcharts';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { IconButton } from '@fluentui/react/lib/Button';
import { mergeStyles } from '@fluentui/react';
import { useState } from 'react';

const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', 'lightgrey'),
        ...shorthands.padding('7px'),
        backgroundColor: 'white',
    },
    chart: {
        width: '100%',
    }
});

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

export const ChartResource = ({ resource }) => {
    const styles = useStyles();
    const [expanded, setExpanded] = useState(false);


    // Apply color scheme

    resource.chart.options.colors = ['#649aaa', '#576071', '#33638F', '#BBBBBB'];

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Map" iconName="StackedColumnChart2Fill" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
        </div>
        {resource.chart
            ?
            <Chart options={resource.chart.options} series={resource.chart.series} type={resource.chart.type} />
            :
            <></>
        }
    </div>;
}
