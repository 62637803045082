import { useCustomerTheme } from "../Theme";

export interface SideBarItem {
    key: string;
    text: String;
    iconName?: string;
    action?: () => void;
}

interface Properties {
    children?: any
}

export default function ContentPanel(properties: Properties) {
    const { theme } = useCustomerTheme();

    return <div className="contentPanel paddingXL" style={{ backgroundColor: theme.primaryLighter }}>
        {properties.children}
    </div>;
}