import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { useState } from "react";
import InfoList from "./InfoList";

class Properties {
  area: any;
}

const useStyles = makeStyles({
  commandBar: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  firstSectionHeader: {
    marginTop: '30px',
  },
  sectionHeader: {
    marginTop: '20px',
  },
  infoList: {
    marginTop: '10px',
  },
});

export default function AreaProperties(properties: Properties) {
  const styles = useStyles();
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: 'Allgemein',
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    },
    {
      key: 'housing',
      text: 'Bebauung',
      iconProps: { iconName: 'Home' },
      onClick: () => setTab('housing'),
    },
    {
      key: 'energyManagement',
      text: 'Energiemanagement',
      iconProps: { iconName: 'PlugDisconnected' },
      onClick: () => setTab('energyManagement'),
    },
    {
      key: 'mobileCoverage',
      text: 'Mobilfunk',
      iconProps: { iconName: 'Phone' },
      onClick: () => setTab('mobileCoverage'),
    }
  ];

  const _overflowItems: ICommandBarItemProps[] = [{
    key: 'sociodemographics',
    text: 'Soziodemographie',
    iconProps: { iconName: 'SchoolDataSyncLogo' },
    onClick: () => setTab('sociodemographics'),
  }];

  return (
    <div>
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          overflowItems={_overflowItems}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div className={styles.firstSectionHeader}>
              <Text variant="large">Bevölkerung</Text>
            </div>
            <div className={styles.infoList}>
              <InfoList list={[
                {
                  label: 'Bevölkerung',
                  value: `${properties.area.population}`,
                },]}></InfoList>
            </div>
          </div>
          case 'housing': return <div>
            <div className={styles.firstSectionHeader}>
              <Text variant="large">Miete</Text>
            </div>
            <div className={styles.infoList}>
              <InfoList list={[
                {
                  label: 'Miete pro m2',
                  value: properties.area.averageRentSqm,
                  suffix: '€/m²'
                },]}></InfoList>
            </div>
          </div>;
          case 'energyManagement': return <div>
            <div className="displayFlex gapM">
              <div className="flexGrow1">
                <div className={styles.firstSectionHeader}>
                  <Text variant="large">Heizquellen</Text>
                </div>
                <div className={styles.infoList}>
                  <InfoList list={[
                    {
                      label: 'Gesamt',
                      value: properties.area.heatingCount,
                    },
                    {
                      label: 'Fernwärme',
                      value: properties.area.districtHeatingCount,
                    },
                    {
                      label: 'Etagenheizung',
                      value: properties.area.storeyHeatingCount,
                    },
                    {
                      label: 'Blockheizkraftwerk',
                      value: properties.area.blockHeatingCount,
                    },
                    {
                      label: 'Zentralheizung',
                      value: properties.area.centralHeatingCount,
                    },
                    {
                      label: 'Ofenheizung',
                      value: properties.area.ovenHeatingCount,
                    },
                    {
                      label: 'Keine Heizung',
                      value: properties.area.noHeatingCount,
                    },
                  ]}></InfoList>
                </div>
              </div>
              <div className="flexGrow1">
                <div className={styles.firstSectionHeader}>
                  <Text variant="large">Energiequellen</Text>
                </div>
                <div className={styles.infoList}>
                  <InfoList list={[
                    {
                      label: 'Gesamt',
                      value: properties.area.totalEnergySourceCount,
                    },
                    {
                      label: 'Gas',
                      value: properties.area.gasEnergySourceCount,
                    },
                    {
                      label: 'Öl',
                      value: properties.area.oilEnergySourceCount,
                    },
                    {
                      label: 'Holz und Pellets',
                      value: properties.area.woodEnergySourceCount,
                    },
                    {
                      label: 'Biomasse',
                      value: properties.area.bioMassGasEnergySourceCount,
                    },
                    {
                      label: 'Solar/Geo/Wärmepumpe',
                      value: properties.area.solarGeoHeatPumpEnergySourceCount,
                    },
                    {
                      label: 'Eletrizität',
                      value: properties.area.electricalEnergySourceCount,
                    },
                    {
                      label: 'Kohle',
                      value: properties.area.coalEnergySourceCount,
                    },
                    {
                      label: 'Keine',
                      value: properties.area.noSourceCount,
                    },
                  ]}></InfoList>
                </div>
              </div>
            </div>
          </div>
        }
      })()}
    </div>
  );
}
