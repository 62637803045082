import { CommandBar, ICommandBarItemProps, Text } from "@fluentui/react";
import { useState } from "react";
import InfoList from "./InfoList";
import { University } from "@marc.gille-sepehri/tri-model";

interface Properties {
  university: University;
}

export default function UniversityProperties(properties: Properties) {
  const [tab, setTab] = useState('general');

  const _items: ICommandBarItemProps[] = [
    {
      key: 'general',
      text: 'General',
      iconProps: { iconName: 'Info' },
      onClick: () => setTab('general'),
    }
  ];

  console.log(properties.university);

  return (
    <div>      
      <div>
        <CommandBar
          styles={{ root: { padding: '0px', } }}
          items={_items}
          ariaLabel=""
          farItemsGroupAriaLabel="More actions"
        />
      </div>
      {(() => {
        switch (tab) {
          case 'general': return <div>
            <div>
              <InfoList list={[
                {
                  label: 'Träger',
                  value: properties.university.sponsorship,
                }, {
                  label: 'Gegründet',
                  value: properties.university.foundationYear,
                }, {
                  label: 'Anzahl Studenten',
                  value: properties.university.numberOfStudents,
                }, {
                  label: 'Promotionsrecht',
                  value: properties.university.rightToAwardDoctorates,
                }, {
                  label: 'Habilitationsrecht',
                  value: properties.university.rightToHabilitation,
                },]}></InfoList>
            </div>
            <div className="marginTopL headerL">Kontaktdaten</div>
            <div className="marginTopM">
              <InfoList list={[
                {
                  label: 'E-Mail',
                  value: properties.university.email,
                }, {
                  label: 'Website',
                  value: properties.university.website,
                }, {
                  label: 'Telefon',
                  value: properties.university.phoneNumber,
                }, {
                  label: 'Fax',
                  value: properties.university.faxNumber,
                },]}></InfoList>
            </div>
            <div className="marginTopL headerL">Postanschrift</div>
            <div className="marginTopM">
              {properties.university.postbox
                ?
                <div>Postfach {properties.university.postbox}</div>
                :
                <></>}
            </div>
            <div className="textM">{properties.university.streetMail} {properties.university.streetNumberMail}</div>
            <div className="textM">{properties.university.postalCodeMail} {properties.university.cityMail}</div>
          </div>
        }
      })()}
    </div>
  );
}
