import { Label } from '@fluentui/react-components';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { useTranslation } from "react-i18next";

interface Properties {
    loading: boolean,
    text?: string,
}

export default function LoadingIndicator(properties: Properties) {
    const { t } = useTranslation();

    return <div className="flex1 displayFlex alignItemsCenter justifyContentCenter paddingL">
        {properties.loading
            ?
            <Spinner label={`${t('global.loading')} ...`} labelPosition="right" />
            :
            <div className='textM'>{properties.text}</div>
        }
    </div>;
}