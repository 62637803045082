import { defaultThemes } from "react-data-table-component";

export const dataTableStyles: any = {
    header: {
        style: {
            minHeight: '56px',
        },
    },
    head: {
        style: {
			color: defaultThemes.default.text.primary,
            fontSize: '14px',
            fontWeight: 'bold !important',
		}
    },
    headRow: {
        style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: defaultThemes.default.divider.default,
        },
    },
    rows: {
        style: {
            '&:not(:last-of-type)': {
				borderBottomStyle: 'none',
				borderBottomWidth: '0px',
			},
        },
    },
    cells: {
        style: {
            fontSize: '14px',
            border: 'none',
        },
    },
};