import { Text } from "@fluentui/react";
import { makeStyles } from "@fluentui/react-components";
import { useState } from "react";

class Properties {
  list: any;
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  label: {
    width: '200px',
    fontWeight: 'bold',
  },
  value: {
  },
});

export default function InfoList(properties: Properties) {
  const styles = useStyles();

  return (
    <div>
      {properties.list.map((item: any) => <div className={styles.row}>
        <div className={styles.label}><Text variant="medium" style={{fontWeight: 'bold'}}>{item.label}:</Text></div>
        <div className={styles.value}><Text variant="medium">{item.value} {item.suffix}</Text></div>
      </div>)}
    </div>
  );
}
