import { Text, TextField } from "@fluentui/react";
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { useEffect, useState } from "react";
import { userAPI } from "../UserAPI";

interface Properties {
  readonly?: boolean;
  type: string;
  asset: any;
  setAsset: any;
}

export default function DIN276AssetProperties(properties: Properties) {
  const { t } = useTranslation();
  const [type, setType] = useState() as any;

  useEffect(() => {
    const call = async () => {
      const metadata = await userAPI.getDIN276Metadata();

      if (properties.type) {
        setType(metadata.find((type: any) => type.code === properties.type))
      }
    };

    call();
  }, [properties.type]);

  return <>{type && type.fields
    ?
    <div className="width600">
      {properties.readonly
        ?
        <>
          {type.fields.map((field: any) => <div>
            {(() => {
              switch (field.type) {
                case 'enum': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.asset && properties.asset[field.name] ? properties.asset[field.name] : '-'}</Text></div>
                  </div>
                </>;
                case 'number': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.asset && properties.asset[field.name] ? properties.asset[field.name] : '-'}</Text></div>
                  </div>
                </>;
                case 'boolean': return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.asset && properties.asset[field.name] ? properties.asset[field.name] : '-'}</Text></div>
                  </div>
                </>;
                case 'string':
                default: return <>
                  <div className="displayFlex marginBottomS">
                    <div className="displayFlex width200"><Text variant="medium" className="fontWeightBold">{field.name}:</Text></div>
                    <div><Text variant="medium">{properties.asset && properties.asset[field.name] ? properties.asset[field.name] : '-'}</Text></div>
                  </div>
                </>;
              }
            })()}
          </div>)}
        </>
        :
        <>
          {type.fields.map((field: any) => <div>
            {(() => {
              switch (field.type) {
                case 'enum': return <>
                  <Dropdown
                    label={field.name}
                    placeholder="Bitte auswählen ..."
                    options={[...field.values.map((x: any) => {
                      return {
                        key: x,
                        text: x,
                      }
                    })]}
                    selectedKey={properties.asset[field.name]}
                    onChange={(e: any, item: any) => {
                      const newAsset = { ...properties.asset } as any;

                      newAsset[field.name] = item.key;

                      properties.setAsset(newAsset)
                    }}
                    styles={{
                      dropdown: { width: 300 },
                    }}
                  />
                </>;
                case 'number': return <>
                  <TextField type="number" label={field.name} value={properties.asset[field.name]} onChange={(e: any, value: any) => {
                    const newAsset = { ...properties.asset } as any;

                    newAsset[field.name] = value;

                    properties.setAsset(newAsset)
                  }} />
                </>;
                case 'boolean': return <>
                  <TextField type="number" label={field.name} value={properties.asset[field.name]} onChange={(e: any, value: any) => {
                    const newAsset = { ...properties.asset } as any;

                    newAsset[field.name] = value;

                    properties.setAsset(newAsset)
                  }} />
                </>;
                case 'string':
                default: return <>
                  <TextField label={field.name} value={properties.asset[field.name]} onChange={(e: any, value: any) => {
                    const newAsset = { ...properties.asset } as any;

                    newAsset[field.name] = value;

                    properties.setAsset(newAsset)
                  }} />
                </>;
              }
            })()}
          </div>)}
        </>}


    </div>
    :
    <div></div>
  }
  </>;
}
