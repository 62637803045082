import { ActionButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

interface Properties {
  className?: string;
  onUpload?: (files: FileList) => void;
}

export const FileUpload = (properties: Properties) => {
  const { t } = useTranslation();
  const [forId] = useState(uuidv4());

  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();

    setDragOver(false);

    console.log(e);

    if (properties.onUpload) {
      properties.onUpload(e.target.files);
    }
  };

  const handleFileChange = (e: any) => {
    if (properties.onUpload) {
      properties.onUpload(e.target.files);
    }
  };

  return <div className="fileUpload">
    <div
      className={`${properties.className || ''} dropZone ${dragOver ? "dragOver" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className='textM'>
        {t('fileUpload.dragFilesHere')}
      </div>
      <div className='marginTopL displayFlex justifyContentCenter'>
        <label htmlFor={forId} className="customFileInput">
          {t('fileUpload.selectFiles')}
        </label>
        <input
          id={forId}
          multiple
          type="file"
          onChange={handleFileChange}
        ></input>
      </div>
    </div>
  </div>;
};