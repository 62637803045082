import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import '../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import Section from '../components/Section';
import Dialog, { DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useState } from 'react';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { useTranslation } from 'react-i18next';

interface Properties {
    processError: (error: any) => void;
}

export default function UserProfilePanel(properties: Properties) {
    const { t } = useTranslation();
    const [authentication, setAuthentication] = useAtom(authenticationAtom) as any;
    const [userProfileDialogOpen, setUserProfileDialogOpen] = useState(false);
    const [editUser, setEditUser] = useState({ ...authentication });

    const toggelUserProfileDialogOpen = () => {
        if (userProfileDialogOpen) {
            setUserProfileDialogOpen(false);
        } else {
            setUserProfileDialogOpen(true);
        }
    }

    const updateUser = async (user: any) => {
        setAuthentication({ ...authentication, ...user });
        setEditUser({ ...authentication, ...user });

        console.log('Changed user', { ...authentication, ...user });

        try {
            //await api.updateUser(authentication);
        } catch (error) {
            properties.processError(error);
        }
    }

    return <><div className="displayFlex flexDirectionColumn gapL width600">
        <Section title={t('settingsPanel.userProfilePanel.personalInformation')}>
            <div className='marginTopL displayFlex justifyContentEnd'>
                <DefaultButton text={t('global.edit')} onClick={toggelUserProfileDialogOpen} />
            </div>
            <Text variant="medium">{authentication.firstName} {authentication.lastName}</Text>
            <Text variant="medium">{authentication.email}</Text>
        </Section>
        <Section title={t('settingsPanel.userProfilePanel.invalidateAccount')}>
            <Text variant='medium'>{t('settingsPanel.userProfilePanel.invalidateAccountDetails')}</Text>
            <div className='marginTopL'>
                <PrimaryButton style={{ backgroundColor: 'red', borderColor: 'red' }} text={t('settingsPanel.userProfilePanel.invalidate')} onClick={() => {
                    try {
                        throw new Error('Bla');
                    } catch (error) {
                        properties.processError(error);
                    }
                }} allowDisabledFocus />
            </div>
        </Section>
    </div>
        <Dialog
            hidden={!userProfileDialogOpen}
            onDismiss={toggelUserProfileDialogOpen}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.userProfilePanel.userProfile'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <TextField label={t('settingsPanel.userProfilePanel.firstName')} value={editUser.firstName} onChange={(e: any, firstName: any) => setEditUser({ ...editUser, firstName })} />
            <TextField label={t('settingsPanel.userProfilePanel.lastName')} value={editUser.lastName} onChange={(e: any, lastName: any) => setEditUser({ ...editUser, lastName })} />
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.apply')} onClick={() => {
                    updateUser(editUser);
                    toggelUserProfileDialogOpen();
                }} />
                <DefaultButton onClick={toggelUserProfileDialogOpen} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog></>;
}