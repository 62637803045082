import 'animate.css';
import { useTranslation } from "react-i18next";

export default function ProductPanel() {
  const { t } = useTranslation();

  return <>
    <div className="centeredContentSection">
      <div className="marginTopXL titleText">The Real Insight</div>
      <div className='marginTopXL largeBodyText textAlignCenter'>Für bessere Entscheidungen und Prozesse im Real Estate.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Mit Daten aus über 300 Datenquellen.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Ergänzt durch eure eigenen Daten.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Durch AI aggregiert, angereichert und präsentiert.</div>
    </div>
    <div className="objectPictures">
      <div className='objectDetails'>
        <div className='headerText'>Infrastruktur</div>
        <img className='marginTopM objectPicture' src="/bridges.png" alt="building"></img>
      </div>
      <div className='objectDetails'>
        <div className='headerText'>{t('land')}</div>
        <img className='marginTopM objectPicture' src="/land.png" alt="building"></img>
      </div>
      <div className='objectDetails'>
        <div className='headerText'>{t('buildings')}</div>
        <img className='marginTopM objectPicture' src="/building.png" alt="building"></img>
      </div>
    </div>
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <a className="hoverHand onboarding textAlignCenter" href="mailto:info@the-real-insight.com">{t('onboarding')}</a>
    </div>
    {/* <div className='marginTopXXL animate__animated animate__fadeIn animate__slow'>
      <div className="textXXL">{t('productPage.trustedBy')}</div>
      <div className="marginTopXL displayFlex justifyContentCenter alignItemsCenter">
        <div className='paddingM'>
          <img className='customerLogo' src="/customers/fire-iq.png" alt="Fire IQ"></img>
        </div>
        <div className='paddingM'>
          <img className='customerLogo' src="/customers/mondrian.png" alt="Mondrian"></img>
        </div>
        <div className='paddingM'>
          <img className='customerLogo' src="/customers/wuerth.png" alt="Wuerth"></img>
        </div>
      </div>
    </div> */}
    <div className="marginTopXXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <div className="headerText">Multi-modale Antworten</div>
      <div className="marginTopL bodyText textAlignCenter">
        Antworten auf Anfragen in Text, Tabellen, Diagrammen, interaktiven Kartendarstellung und ganzen Berichten - oder über ein einfach zu nutzendes API.
      </div>
    </div>
    <div className="marginTopXL centeredContentSection animate__animated animate__fadeIn animate__slow">
      <img src='./product/chatbot.png' className="img100Pct" alt=""></img>
    </div>
    <div className="marginTopXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block50">
        <div className="headerText">Geräte und Ausstattung</div>
        <div className="marginTopM bodyText">
          Schnelle Erfassung des Bestands (DIN 276, FAC) in Gebäuden über Fotos von Geräten und Ausstattung und deren Typenschildern in unserer App, einfaches Hochladen von Dateien oder unsere API.
        </div>
        <div className="marginTopM bodyText">
          Installation, Überprüfung des Betriebszustands und Wartung - ebenfalls per App.
        </div>
      </div>
      <div className="block50">
        <img src='./product/equipment-registration.png' className="img60Pct" alt=""></img>
      </div>
    </div>
    <div className="marginTopXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block50">
        <img src='./product/iot.png' className="img100Pct" alt=""></img>
      </div>
      <div className="block50">
        <div className="headerText">IoT Messdaten</div>
        <div className="marginTopM bodyText">
          Einfache Verbindung zu IoT-Sensorik zur Optimierung des Betriebs und zur Unterstützung präventiver Wartung.
        </div>
      </div>
    </div>
    <div className="marginTopXXL animate__animated animate__fadeIn animate__slow">
      <div className="block50">
        <div className="headerText">Energieeffizienz</div>
        <div className="marginTopM bodyText">
          Unsere AI kombiniert bauliche, geographische und Infrastrukturdaten zur unmittelbaren Bewertung von Energie- und Nachhaltigkeitsmaßnahmen.
        </div>
      </div>
      <div className="marginTopXL flow">
        <div className='block50'>
          <img src='./product/pv.png' className="img100Pct" alt=""></img>
        </div>
        <div className='block50'>
          <img src='./product/esg.png' className="img100Pct" alt=""></img>
        </div>
      </div>
    </div>
    <div className="marginTopXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block50">
        <div className="headerText">Dokumente</div>
        <div className="marginTopM bodyText">
          Unsere AI erkennt und analysiert 32 verschiedene Dokumententypen (z.B. Mietverträge, Gerätehandbücher oder Wartungsprotokolle) von gescannten Dokumenten, 
          assoziiert diese mit Objekten und Personen und stellt diese Daten zur Unterstützung von Auswertungen und Prozessen bereit.
        </div>
        <div className="marginTopM bodyText">
          Sicher abgelegt auf unserer Cloud-Plattform.
        </div>
      </div>
      <div className="block50">
        <img src='./product/devices.png' className="img100Pct" alt=""></img>
      </div>
    </div>
    <div className="marginTopXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block50">
        <img src='./product/poi.png' className="img100Pct" alt=""></img>
      </div>
      <div className="block50">
        <div className="headerText">Points of Interest</div>
        <div className="marginTopM bodyText">
          Schnelle Übersicht zu relevanten Orten und Diensten in der Umgebung - mit all' deren Details. Zur Bewertung von Projekten und Objekten.
        </div>
        <div className="marginTopM bodyText">
          Infrastruktur, Versorgung, Bildung, Unterhaltung, Sport und mehr - ständig aktualisiert und erweitert.
        </div>
      </div>
    </div>
    <div className="marginTopXXL marginBottomXXL flow animate__animated animate__fadeIn animate__slow">
      <div className="block50">
        <div className="headerText ">Flächendaten</div>
        <div className="marginTopM bodyText">
          Umfassende Daten auf Rasterflächen und Postleitzahlengebieten.
        </div>
        <div className="marginTopM bodyText">
          Soziodemographie, Mieten und Kaufpreise, Kriminalität, Mobilfunkabdeckung, Energie- und Versorgungsdaten - und mehr.
        </div>
      </div>
      <div className="block50">
        <img src='./product/tile.png' className="img100Pct" alt=""></img>
      </div>
    </div>
  </>;
}
