import { and, Area, EntityQuery, Filter, is, Query } from "@marc.gille-sepehri/tri-model";
import { useTranslation } from "react-i18next";
import { userAPI } from "../../UserAPI";
import { useEffect, useState } from "react";
import InfoList from "../InfoList";

class Properties {
  building: any;
}

const numberFormat = new Intl.NumberFormat('de');
const amountFormat = new Intl.NumberFormat('de', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 });

export default function FinancialProperties(properties: Properties) {
  const { t } = useTranslation();
  const [area, setArea] = useState() as any;

  useEffect(() => {
    const process = async () => {
      const areas = await userAPI.query(new Query(new EntityQuery(Area.type, [], undefined,
        Filter.latLonContainedInBoundingBoxFilter(0.5 * (properties.building.minLat + properties.building.maxLat),
          0.5 * (properties.building.minLon + properties.building.maxLon)))));

      console.log('Area ============================>>>', areas);

      if (areas.length > 0) {
        setArea(areas[0])
      }
    }

    process();
  }, [properties]);

  return <div>
    <div className="marginTopM headerL">Verkauf</div>
    <div className='marginTopM'><div className="textM">{t('global.noInformationAvailable')}</div></div>
    <div className="marginTopM headerL">Vermietung</div>
    <div className="marginTopM">
      <InfoList list={[{
        label: 'Mietspiegel',
        value: area ? amountFormat.format(area.averageRentSqm) : undefined,
      },]}></InfoList>
    </div>
  </div >;
}
