import Header from "../Header";
import { Pivot, PivotItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import CustomerAndUserManagementPanel from "./CustomerAndUserManagementPanel";
import BillingPanel from "./BillingPanel";
import InvoicingPanel from "./InvoicingPanel";
import { MessageBox, useMessagBox } from "../components/MessageBox";

export default function AdministrationPage() {
    const { t } = useTranslation();
    const { processError, messageBox } = useMessagBox();

    return (
        <div>
            <Header showHome={true} title="Administration"></Header>
            <div className="paddingM">
                <MessageBox messageBox={messageBox} ></MessageBox>
                <Pivot aria-label="">
                    <PivotItem headerText={t('administrationPage.customerManagement')} itemIcon="CompanyDirectory">
                        <div className="paddingM">
                            <CustomerAndUserManagementPanel processError={processError}></CustomerAndUserManagementPanel>
                        </div>
                    </PivotItem>
                    <PivotItem headerText={t('administrationPage.email')} itemIcon="EditMail">
                        <div className="paddingM">
                            <div></div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText={t('administrationPage.billing')} itemIcon="Money">
                        <div className="paddingM">
                            <BillingPanel processError={processError}></BillingPanel>
                        </div>
                    </PivotItem>
                    <PivotItem headerText={t('administrationPage.invoicing')} itemIcon="PaymentCard">
                        <div className="paddingM">
                            <InvoicingPanel processError={processError}></InvoicingPanel>
                        </div>
                    </PivotItem>
                </Pivot>
            </div>
        </div>
    );
}