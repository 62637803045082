import { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl';

interface FloorplanGeopositionEditorProperties {
    floor?: any;
    floorChanged?: (floor: any) => void;
}

mapboxgl.accessToken = 'pk.eyJ1IjoibWFyY2dpbGxlIiwiYSI6ImNsZWlvazNkYTAzaWYzb25yZjBmejB5NmMifQ.XF9Z60UyGqXLztoIjH1SIA';

export function FloorplanGeopositionEditor(properties: FloorplanGeopositionEditorProperties) {
    const mapContainerRef = useRef(null) as any;
    const map = useRef(null) as any;
    const [lng] = useState(8.6960);
    const [lat] = useState(50.1064);
    const [zoom] = useState(17);
    const [src, setSrc] = useState();

    const coordinates = [
        [8.6960, 50.1064],
        [8.6970, 50.1064],
        [8.6970, 50.1060],
        [8.6960, 50.1060]
    ];

    const initHandle = (index: number) => {
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': coordinates[index]
                    }
                }
            ]
        };
        const onMove = (e: any) => {
            const coords = e.lngLat;

            geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
            map.current.getSource(`point${index}`).setData(geojson);
            map.current.setPaintProperty('floorplan-layer', 'raster-opacity', 0.5);

            const newCoordinates = [...map.current.getSource('floorplan').coordinates];

            newCoordinates[index] = [coords.lng, coords.lat]

            map.current.getSource('floorplan').setCoordinates(newCoordinates);
        }
        const onUp = (e: any) => {
            map.current.setPaintProperty('floorplan-layer', 'raster-opacity', 0.8);
            map.current.off('mousemove', onMove);
            map.current.off('touchmove', onMove);
        }

        map.current.addSource(`point${index}`, {
            'type': 'geojson',
            'data': geojson
        });
        map.current.addLayer({
            'id': `handle${index}`,
            'type': 'circle',
            'source': `point${index}`,
            "paint": {
                'circle-radius': 10,
                'circle-color': 'rgba(230, 164, 240, 0.5)'
            }
        });
        map.current.on('mouseenter', `handle${index}`, () => {
            map.current.setPaintProperty(`handle${index}`, 'circle-color', 'rgba(230, 164, 240, 0.8)');
        });
        map.current.on('mouseleave', `handle${index}`, () => {
            map.current.setPaintProperty(`handle${index}`, 'circle-color', 'rgba(240, 100, 250, 0.5)');
        });
        map.current.on('mousedown', `handle${index}`, (e: any) => {
            e.preventDefault();
            map.current.on('mousemove', onMove);
            map.current.once('mouseup', onUp);
        });
        map.current.on('mousedown', `handle${index}`, (e: any) => {
            e.preventDefault();
            map.current.on('mousemove', onMove);
            map.current.once('mouseup', onUp);
        });
        map.current.on('touchstart', `handle${index}`, (e: any) => {
            if (e.points.length !== 1) return;
            e.preventDefault();
            map.current.on('touchmove', onMove);
            map.current.once('touchend', onUp);
        });

        const element = document.createElement('div');

        element.className = 'marker';
    }

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v9',
            center: [lng, lat],
            zoom: zoom,
            preserveDrawingBuffer: true
        });

        var dpi = 300;

        Object.defineProperty(window, 'devicePixelRatio', {
            get: function () { return dpi / 96 }
        });

        map.current.on("load", () => {
            map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

            map.current.addSource('floorplan', {
                'type': 'image',
                'url': 'floorplan.png',
                coordinates
            });
            map.current.addLayer({
                id: 'floorplan-layer',
                'type': 'raster',
                'source': 'floorplan',
                'paint': {
                    'raster-fade-duration': 0,
                    "raster-opacity": 0.8,
                }
            });

            initHandle(0);
            initHandle(1);
            initHandle(2);
            initHandle(3);

            new mapboxgl.Marker().setLngLat(new mapboxgl.LngLat(lng, lat))
                .addTo(map.current);
            map.current.resize();

            setTimeout(() => {
                setSrc(map.current.getCanvas().toDataURL());
            }, 1000);
        })

        return () => map.current.remove();
    }, [lat, lng, zoom]);

    return <>
        <div className='map-container width800 height600' ref={mapContainerRef} />
        <img width="800" height="600" src={src} alt=""></img>
    </>;
}