import '../App.sass';
import { Text } from '@fluentui/react/lib/Text';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@fluentui/react-components';
import InfoList from './InfoList';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import { Address, Building, EntityQuery, FieldReference, FieldValue, Floor, LegalPerson, LinkedEntityQuery, Query, RelationshipDirection, Subsidiary, Unit } from '@marc.gille-sepehri/tri-model';
import DataTable from 'react-data-table-component';
import { dataTableStyles } from '../styles';
import { ActionButton } from '@fluentui/react';
import { useAdministrationAPI } from '../AdministrationAPI';

class Properties {
    building: any;
}

const useStyles = makeStyles({
    commandBar: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    },
    firstSectionHeader: {
        marginTop: '30px',
    },
    sectionHeader: {
        marginTop: '20px',
    },
    infoList: {
        marginTop: '10px',
    },
    flex: {
        display: 'flex',
    },
    end: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'end',
    },
    contractImage: {
        width: '100%',
    },
});

const numberFormat0Digits = new Intl.NumberFormat('de', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
const numberFormat2Digits = new Intl.NumberFormat('de', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

export default function GeneralBuildingProperties(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const styles = useStyles();
    const [floors, setFloors] = useState([]/*[{ level: -1, name: '1. UG', area: 234 }, { level: 0, name: 'EG', area: 234 }, { level: 0, name: '1. OG', area: 244 }]*/) as any;
    const [buildingHeight, setBuildingHeight] = useState() as any;
    const [units, setUnits] = useState([] as any);
    const [subsidiaries, setSubsidiaries] = useState([] as any[]);
    const { createFloor } = useAdministrationAPI();

    const floorColumns = [{
        name: '',
        width: '50px',
        cell: (floor: any) => (
            <div className='height20 displayFlex flexDirectionColumn justifyContentCenter'>
                <ActionButton iconProps={{ iconName: 'IncreaseIndentArrow' }} onClick={() => {
                    navigate(`/entity?selectedEntityType=Floor&selectedEntity=${floor._id}`);
                }}></ActionButton>
            </div>
        ),
        sortable: false,

    }, {
        name: 'Ebene',
        minWidth: '80px',
        maxWidth: '00px',
        right: true,
        cell: (floor: any) => (
            <span>{floor.level}</span>
        )
    }, {
        name: 'Name',
        minWidth: '100px',
        maxWidth: '100px',
        right: true,
        cell: (floor: any) => (
            <span>{floor.name}</span>
        )
    }, {
        name: 'Fläche/㎡',
        minWidth: '100px',
        maxWidth: '100px',
        right: true,
        cell: (floor: any) => (
            <span>{floor.area}</span>
        )
    }];

    const subsidiariesColumns = [{
        name: t('buildingProperties.generalBuildingPropertiesPanel.company'),
        minWidth: '100px',
        maxWidth: '300px',
        cell: (subsidiary: any) => (
            <div>
                <div>
                    <a onClick={() => {
                        navigate(`/entity?selectedEntityType=LegalPerson&selectedEntity=${17}`);
                    }}><Text variant='medium' className="fontWeightBold">{subsidiary[0]}</Text></a>
                </div>
                <div>
                    <Text variant='medium'>{subsidiary[3]}</Text>
                </div>
                <div>
                    <Text variant='medium'>{subsidiary[1]}</Text>
                </div>
            </div>
        )
    }, {
        name: t('buildingProperties.generalBuildingPropertiesPanel.contact'),
        minWidth: '100px',
        maxWidth: '300px',
        cell: (subsidiary: any) => (
            <div>
                <div>
                    <Text variant='medium'>{subsidiary[10]}</Text>
                </div>
                <div>
                    <Text variant='medium'><a href={`emailto:${subsidiary[11]}`}>{subsidiary[11]}</a></Text>
                </div>
                <div>
                    <Text variant='medium'><a href={subsidiary[12]}>{subsidiary[12]}</a></Text>
                </div>
            </div>
        )
    }];

    useEffect(() => {
        const call = async () => {
            const result = await userAPI.queryGraph(new Query(new EntityQuery(Unit.type)));

            console.log(result);

            setUnits(result.map((unit: any, index: number) => {
                return {
                    key: index,
                    name: unit.name,
                    tenant: 'Bla',
                };
            }));
        };

        call();
    }, []);

    useEffect(() => {
        const call = async () => {
            // TODO Needs to be replaced by a reload of building with floors
            const result = await userAPI.queryGraph(new Query(new EntityQuery(Floor.type)));

            console.log(result);

            setFloors(result);
        };

        call();
    }, []);

    useEffect(() => {
        const call = async () => {
            if (!properties.building) {
                return;
            }

            let result = await userAPI.queryGraph(new Query(
                new EntityQuery(LegalPerson.type, [], 'l'),
                [new LinkedEntityQuery('subsidiaries', RelationshipDirection.out, Subsidiary.type, [], 's'),
                new LinkedEntityQuery(
                    'address',
                    RelationshipDirection.out,
                    Address.type,
                    [new FieldValue('city', properties.building.address.city),
                    new FieldValue('postalCode', properties.building.address.postalCode),
                    new FieldValue('street', properties.building.address.street),
                    new FieldValue('streetNumber', properties.building.address.streetNumber)],
                    'a'),
                new LinkedEntityQuery('address',
                    RelationshipDirection.in,
                    Building.type,
                    [],
                    'b')], [new FieldReference('l', 'name'),
                    new FieldReference('l', 'tradeRegisterId'),
                    new FieldReference('l', 'legalForm'),
                    new FieldReference('l', 'purpose'),
                    new FieldReference('a', 'street'),
                    new FieldReference('a', 'streetNumber'),
                    new FieldReference('a', 'postalCode'),
                    new FieldReference('a', 'city'),
                    new FieldReference('b', 'levels'),
                    new FieldReference('b', 'shape'),
                    new FieldReference('s', 'phone'),
                    new FieldReference('s', 'email'),
                    new FieldReference('s', 'website')]));
            result = [...result.slice(1), ...(await userAPI.queryGraph(new Query(
                new EntityQuery(LegalPerson.type, [], 'l'),
                [new LinkedEntityQuery(
                    'headquarters',
                    RelationshipDirection.out,
                    Address.type,
                    [new FieldValue('city', properties.building.address.city),
                    new FieldValue('postalCode', properties.building.address.postalCode),
                    new FieldValue('street', properties.building.address.street),
                    new FieldValue('streetNumber', properties.building.address.streetNumber)],
                    'a'),
                new LinkedEntityQuery('address',
                    RelationshipDirection.in,
                    Building.type,
                    [],
                    'b')], [new FieldReference('l', 'name'),
                    new FieldReference('l', 'tradeRegisterId'),
                    new FieldReference('l', 'legalForm'),
                    new FieldReference('l', 'purpose'),
                    new FieldReference('a', 'street'),
                    new FieldReference('a', 'streetNumber'),
                    new FieldReference('a', 'postalCode'),
                    new FieldReference('a', 'city'),
                    new FieldReference('b', 'levels'),
                    new FieldReference('b', 'shape'),
                    new FieldReference('l', 'phone'),
                    new FieldReference('l', 'email'),
                    new FieldReference('l', 'website')]))).slice(1)];

            setSubsidiaries(result);
        };

        call();
    }, [properties]);

    useEffect(() => {
        const process = async () => {
            const cityObject = await userAPI.getCityObject(0.5 * (properties.building.minLat + properties.building.maxLat), 0.5 * (properties.building.minLon + properties.building.maxLon));

            console.log('City Object ============================>>>', cityObject);

            setBuildingHeight(cityObject.measuredHeight);
        }

        process();
    }, [properties]);

    return <div>
        <div>
            <div className="marginTopL headerL">{t('buildingProperties.generalBuildingPropertiesPanel.general')}</div>
            <div className={styles.infoList}>
                <InfoList list={[
                    {
                        label: t('buildingProperties.generalBuildingPropertiesPanel.yearOfConstruction'),
                        value: '-',
                    },
                    {
                        label: 'Gebäudehöhe',
                        value: buildingHeight ? numberFormat2Digits.format(buildingHeight) : '-',
                        suffix: 'm'
                    },
                    {
                        label: t('buildingProperties.generalBuildingPropertiesPanel.levels'),
                        value: properties.building.levels || (buildingHeight !== undefined ? `${Math.round(buildingHeight / 3.2)} (geschätzt)` : '-'),
                    },
                    {
                        label: t('buildingProperties.generalBuildingPropertiesPanel.grossFloorArea'),
                        value: properties.building.area ? `${numberFormat0Digits.format(properties.building.area)}㎡` : '-',
                    },
                    {
                        label: t('buildingProperties.generalBuildingPropertiesPanel.numberOfUnits'),
                        value: '-',
                    },
                ]}></InfoList>
            </div>
            <div>
                <div className='marginTopM headerL'>Etagen</div>
                <ActionButton className='marginTopM' iconProps={{ iconName: 'Add' }}
                    onClick={async () => {
                        const result = await createFloor(properties.building, new Floor({ name: `${properties.building.length}. Etage`, level: properties.building.length }));

                        console.log('Result >>>', result);
                        //setSelectedProject(new Project({ name: `Project ${new Date().toLocaleDateString()}` }));
                    }}>
                    Etage anlegen
                </ActionButton>
                <DataTable
                    data={floors}
                    columns={floorColumns}
                    pagination
                    paginationPerPage={5}

                />
            </div>
            {subsidiaries && subsidiaries.length > 0
                ?
                <>
                    <div className='marginTopM headerL'>{t('buildingProperties.generalBuildingPropertiesPanel.subsidiaries')}
                    </div>
                    <DataTable
                        data={subsidiaries}
                        columns={subsidiariesColumns}
                        customStyles={dataTableStyles}
                    />
                </>
                :
                <></>
            }
        </div>
    </div>;
}