import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, Checkbox, ChoiceGroup, DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, FontIcon, IComboBoxOption, IDropdownOption, Label, Pivot, PivotItem, PrimaryButton, TextField } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import { useTranslation } from 'react-i18next';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import DataTable from 'react-data-table-component';
import { Address, LegalPerson, EntityQuery, Project, Query, LinkedEntityQuery, RelationshipDirection, is, ProjectEntitlement, Building, Floor } from '@marc.gille-sepehri/tri-model';
import { dataTableStyles } from '../styles';
import { useAdministrationAPI } from '../AdministrationAPI';
import { FileUpload } from '../components/FileUpload';
import AddressPicker from '../components/AddressPicker';
import LegalPersonPicker from '../components/LegalPersonPicker';
import LoadingIndicator from '../components/LoadingIndicator';
import AssetList from '../components/AssetList';
import { useNavigate } from 'react-router-dom';

interface Properties {
    processError: (error: any) => void;
}

const numberFormat = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });

enum BuildingPart {
    wholeBuilding = 'wholeBuilding',
    partOfBuilding = 'partOfBuilding'
}

interface Object {
    buildingPart: BuildingPart;
    building?: Building;
    floors?: Floor[];
    address: Address;
}

export default function ProjectPanel(properties: Properties) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [authentication] = useAtom(authenticationAtom) as any;
    const { getProjects, getProjectEntitlements, createProject, updateProject, createProjectEntitlement, updateProjectEntitlement, deleteProjectEntitlement } = useAdministrationAPI();
    const [projects, setProjects] = useState([]) as any;
    const [selectedProject, setSelectedProject] = useState() as any;
    const [entitlements, setEntitlements] = useState([]) as any;
    const [loadingProjects, setLoadingProjects] = useState(false) as any;
    const [loadingEntitlements, setLoadingEntitlements] = useState(false) as any;
    const [tab, setTab] = useState('project') as any;
    const [documentType, setDocumentType] = useState('unknown');
    const [documentVisibility, setDocumentVisibility] = useState(['_self']);
    const [objectSelectDialogOpen, setObjectSelectDialogOpen] = useState(false);
    const [partnerSelectDialogOpen, setPartnerSelectDialogOpen] = useState(false);
    const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false);
    const [address, setAddress] = useState() as any;
    const [client, setClient] = useState() as any;
    const [selectedEntitlement, setSelectedEntitlement] = useState() as any;
    const [deletedEntitlements, setDeletedEntitlements] = useState([]) as any;
    const [ownEntitlement, setOwnEntitlement] = useState() as any;
    const [buildingPart, setBuildingPart] = useState(BuildingPart.wholeBuilding) as any;
    const [selectedFile, setSelectedFile] = useState() as any;
    const [floors, setFloors] = useState([]) as any;
    const [selectedFloors, setSelectedFloors] = useState([]) as any;
    const [floorName, setFloorName] = useState('') as any;
    const [selectedObject, setSelectedObject] = useState() as any;

    const roleOptions: IComboBoxOption[] = [
        { key: 'administration', text: 'Administration' },
        { key: 'registration', text: 'Erfassung' },
        { key: 'dataAnalysis', text: 'Datenanalyse' },
    ];

    const columns = [{
        name: t('settingsPanel.projectsPanel.name'),
        selector: (project: Project) => project.name,
        width: '300px',
        cell: (product: any) => <span>{product.name}</span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.ownership'),
        selector: (project: Project) => project.name,
        width: '120px',
        cell: (project: any) => (authentication.customer === project._tenant ?
            <span className='textS colorPrimary fontWeightBold'>EIGENTÜMER</span>
            :
            <span className='textS colorContrast fontWeightBold'>PARTNER</span>),
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.customer'),
        width: '300px',
        cell: (project: any) => project._company ? <div>
            <div>{project._company.name}</div><div>{project._company.headquarters.city}</div></div> : <span></span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.objects'),
        width: '150px',
        cell: (product: Project) => <span></span>,
        sortable: true,
    }];

    const entitlementColumns = [{
        name: '',
        width: '50px',
        cell: (entitlement: any) => <div>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    setEntitlements(entitlements.filter((entry: any) => entry !== entitlement));
                    setDeletedEntitlements([...deletedEntitlements, entitlement]);
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.partner'),
        width: '300px',
        cell: (entitlement: any) => <span>{entitlement.partner.name}</span>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.address'),
        selector: (entitlement: any) => entitlement.partner.headquarters.street,
        width: '400px',
        cell: (entitlement: any) => <div>{entitlement.partner.headquarters.street} {entitlement.partner.headquarters.streetNumber}, {entitlement.partner.headquarters.postalCode} {entitlement.partner.headquarters.city}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.primaryContact'),
        selector: (entitlement: any) => entitlement.primaryContactEmail,
        width: '200px',
        cell: (entitlement: any) => <div><a href={`mailto:${entitlement.primaryContactEmail}`}>{entitlement.primaryContactEmail}</a></div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.role'),
        width: '300px',
        cell: (entitlement: any) => <span>{entitlement.roles.map((role: string) => t(`settingsPanel.projectsPanel.${role}`)).toString()}</span>/*<ComboBox
            multiSelect
            selectedKey={entitlement.roles}
            options={roleOptions}
            onChange={(_: any, value: any) => {
                console.log('Value >>>', value);

                const entitlementIndex = entitlements.indexOf(entitlement);
                const newEntitlement = { ...entitlement };
                const newEntitlements = [...entitlements];

                if (value.selected) {
                    newEntitlement.roles.push(value.key);
                } else {
                    newEntitlement.roles = newEntitlement.roles.filter((role: string) => value.key === role);
                }

                newEntitlements[entitlementIndex] = newEntitlement;

                console.log('Entitlements >>>', newEntitlements);

                setEntitlements(newEntitlements);
            }}
            styles={{ root: { width: 400, maxWidth: 400 } }}
        />*/,
        sortable: true,
    }];

    const objectColumns = [{
        name: '',
        width: '50px',
        cell: (object: any) => <div>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    const objects = selectedProject.objects.filter((item: any) => item !== object);

                    setSelectedProject({ ...selectedProject, objects })
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.address'),
        selector: (object: any) => object.address.street,
        width: '400px',
        cell: (object: any) => <div>{object.address.street} {object.address.streetNumber}, {object.address.postalCode} {object.address.city}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.space'),
        width: '150px',
        cell: (object: any) => object.buildingPart === BuildingPart.wholeBuilding
            ?
            <span>{t('settingsPanel.projectsPanel.wholeBuilding')}</span>
            :
            <div>
                <div>{t('settingsPanel.projectsPanel.partOfBuilding')}</div>
                <div className="colorPrimary">{object.floors.map((floor: any, index: number) => <ActionButton onClick={() => navigate(`/entity?selectedEntityType=Floor&selectedEntity=${floor._id}`)}>{index > 0 ? ', ' : ''}{floor.name}</ActionButton>)}</div>
            </div>,
        sortable: true,
    }];

    const documentDummies = [{
        title: 'Bestellung',
        synopsis: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante ante, consequat a sagittis eu, sollicitudin ac neque. Nulla pulvinar libero non sem semper, sit amet dictum erat efficitur. Sed sollicitudin enim vitae velit accumsan vestibulum ac id ligula. Sed sed enim vel arcu mollis aliquet. Aenean et tellus nisl. Quisque vel lobortis sem, sed lacinia sapien. In non vulputate velit. Nulla eu interdum enim, ut faucibus sapien. Nullam vestibulum quam eu facilisis congue. Suspendisse sollicitudin vitae nisi id viverra.',
    }];

    const documentColumns = [{
        name: '',
        width: '50px',
        cell: (document: any) => <div>
            <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Document' }}
                onClick={() => {
                }}>
            </ActionButton>
        </div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.title'),
        selector: (object: any) => object.address.street,
        width: '150px',
        cell: (document: any) => <div>{document.title}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.received'),
        selector: (document: any) => new Date().getTime(),
        width: '150px',
        cell: (document: any) => <div>{new Date().toLocaleDateString()}</div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.visibility'),
        selector: (document: any) => new Date().getTime(),
        width: '350px',
        cell: (document: any) => <div><Dropdown
            options={[{ key: '4711', text: 'Nur eigenes Unternehmen' }, { key: null, text: 'All' }, ...entitlements.map((entitlement: any) => { return { key: entitlement.partner._id, text: entitlement.partner.name } })]}
            onChange={(e: any, item: any) => setDocumentType(item.key)}
            styles={{
                dropdown: { width: 300 },
            }}
        /></div>,
        sortable: true,
    }, {
        name: t('settingsPanel.projectsPanel.summary'),
        width: '600px',
        cell: (document: any) => <div className='textXS'>{document.synopsis}</div>,
        sortable: true,
    }];

    const selectProject = (project: any) => {
        setSelectedProject(project);
    };

    useEffect(() => {
        if (selectedProject) {
            if (selectedProject._id) {
                setLoadingEntitlements(true);

                const call = async () => {
                    try {
                        // const entitlements = await userAPI.query(new Query(new EntityQuery(ProjectEntitlement.type, [], undefined,
                        //     is('project').equal(selectedProject._id))));

                        const entitlements = await getProjectEntitlements(selectedProject);

                        for (const entitlement of entitlements) {
                            const companies = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
                                is('_id').equal(entitlement.company)), [new LinkedEntityQuery('headquarters', RelationshipDirection.out, Address.type)]));
                            entitlement.partner = companies[0];

                            if (authentication.legalPerson && entitlement.company === authentication.legalPerson._id) {
                                setOwnEntitlement(entitlement);
                            }
                        }

                        setProjects(projects);
                        setEntitlements(entitlements);
                    } catch (error) {
                        properties.processError(error);
                    } finally {
                        setLoadingEntitlements(false);
                    }
                };

                call();
            }
        } else {
            setLoadingProjects(true);

            const call = async () => {
                try {
                    const projects = await getProjects();

                    for (const project of projects) {
                        const companies = await userAPI.query(new Query(new EntityQuery(LegalPerson.type, [], undefined,
                            is('_id').equal(project.customer)), [new LinkedEntityQuery('headquarters', RelationshipDirection.out, Address.type)]));
                        project._company = companies[0];
                    }

                    console.log('Projects >>>', projects);

                    setProjects(projects);
                } catch (error) {
                    properties.processError(error);
                } finally {
                    setLoadingProjects(false);
                }
            };

            call();
        }
    }, [selectedProject]);

    const documentTypeOptions: IDropdownOption[] = [
        { key: 'unknown', text: t('settingsPanel.projectsPanel.unknown') },
        { key: 'contract', text: t('settingsPanel.projectsPanel.contract') },
        { key: 'order', text: t('settingsPanel.projectsPanel.order') },
        { key: 'invoice', text: t('settingsPanel.projectsPanel.invoice') },
        { key: 'productManual', text: t('settingsPanel.projectsPanel.productManual') },
    ];

    const onDocumentsUpload = async (files: FileList) => {
        if (!files || files.length === 0) {
            return;
        }
        console.log('Files >>>', files);

        setSelectedFile(files[0]);
        setAddDocumentDialogOpen(true);

        try {
            const formData = new FormData();

            for (let i = 0; i < files.length; ++i) {
                if (files.item(i) !== null) {
                    // @ts-ignore
                    formData.append(files.item(i).name, files.item(i));
                }
            }

            // const result = await ingestDocuments(authentication.customer, documentType, formData);

            // console.log('Result >>>', result);
        } catch (error) {
            properties.processError(error);
        }
    }

    const setParams = (value: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('tab', value.key.substring(1));
        window.history.pushState(null, '', url.toString());

        setTab(value.key.substring(1));
    }

    const isValidEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isValid = () => {
        return selectedProject.name && selectProject.name.trim().length > 0 &&
            selectedProject.description && selectedProject.description.trim().length > 0 &&
            selectedProject.startDate &&
            selectedProject.customer &&
            client;
    }

    const isSelectedEntitlementValid = () => {
        return selectedEntitlement.partner &&
            selectedEntitlement.primaryContactEmail && selectedEntitlement.primaryContactEmail.length > 0 && isValidEmail(selectedEntitlement.primaryContactEmail);
    }

    const ownsSelectedProject = () => {
        return !selectedProject._tenant || selectedProject._tenant === authentication.customer;
    }

    const canAdministrateSelectedProject = () => {
        return ownEntitlement && ownEntitlement.roles.indexOf('administration') >= 0;
    }

    const isBuildingPartComplete = () => {
        return buildingPart === BuildingPart.partOfBuilding ? floorName || selectedFloors.length > 0 : true;
    }

    return <><div>
        {selectedProject
            ?
            <div>
                <div className='displayFlex alignItemsCenter'>
                    <div className='flexGrow1 displayFlex textL'>
                        {selectedProject.name}
                    </div>
                    <div className='displayFlex gapM'>
                        {ownsSelectedProject() || canAdministrateSelectedProject()
                            ?
                            (selectedProject && selectedProject._id
                                ?
                                <PrimaryButton disabled={!isValid()} onClick={async () => {
                                    try {
                                        console.log('Updating project >>>', selectedProject);

                                        await updateProject(selectedProject);

                                        for (const entitlement of entitlements) {
                                            if (entitlement._id) {
                                                await updateProjectEntitlement(new ProjectEntitlement({
                                                    _id: entitlement._id,
                                                    project: selectedProject._id,
                                                    roles: entitlement.roles,
                                                    company: entitlement.partner._id,
                                                    primaryContactEmail: entitlement.primaryContactEmail,
                                                }));
                                            } else {
                                                await createProjectEntitlement(new ProjectEntitlement({
                                                    project: selectedProject._id,
                                                    roles: entitlement.roles,
                                                    company: entitlement.partner._id,
                                                    primaryContactEmail: entitlement.primaryContactEmail,
                                                }));
                                            }
                                        }

                                        for (const entitlement of deletedEntitlements) {
                                            await deleteProjectEntitlement(entitlement);
                                        }

                                        setSelectedProject(null);
                                    } catch (error) {
                                        // TODO Process error
                                        console.log('process error', error);
                                    }
                                }}>
                                    {t('global.save')}
                                </PrimaryButton>
                                :
                                <PrimaryButton disabled={!isValid()} onClick={async () => {
                                    try {
                                        console.log('Create project >>>', selectedProject);
                                        console.log('Entitlements >>>', entitlements);

                                        const project = await createProject(selectedProject);

                                        for (const entitlement of entitlements) {
                                            await createProjectEntitlement(new ProjectEntitlement({
                                                project: project._id,
                                                roles: entitlement.roles,
                                                company: entitlement.partner._id,
                                                primaryContactEmail: entitlement.primaryContactEmail,
                                            }));
                                        }

                                        setSelectedProject(null);
                                    } catch (error) {
                                        // TODO Process error
                                        console.log('process error', error);
                                    }
                                }}>
                                    {t('global.create')}
                                </PrimaryButton>
                            )
                            :
                            <></>
                        }
                        <DefaultButton
                            onClick={() => {
                                setSelectedProject(null);
                            }}>
                            {t('global.close')}
                        </DefaultButton>
                    </div>
                </div>
                <div className="marginTopM ">
                    <Pivot aria-label="" onLinkClick={setParams} selectedKey={tab}>
                        <PivotItem id="project" headerText={t('settingsPanel.projectsPanel.general')}>
                            <div className='width1200'>
                                <div className="width500">
                                    <TextField required disabled={!ownsSelectedProject()} className="marginTopL" label={t('settingsPanel.projectsPanel.name')} value={selectedProject.name} onChange={(e: any, name: any) => setSelectedProject({ ...selectedProject, name })}
                                        style={{ width: '300px' }} />
                                    <TextField required disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.description')} value={selectedProject.description} onChange={(e: any, description: any) => setSelectedProject({ ...selectedProject, description })}
                                        style={{ width: '300px' }} multiline />
                                    <div className="displayFlex gapM">
                                        <DatePicker disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.startDate')}
                                            styles={{ root: { width: 300 } }}
                                            value={new Date(selectedProject.startDate)}
                                            onSelectDate={(date: any) => setSelectedProject({ ...selectedProject, startDate: new Date(date) })}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        //strings={defaultDatePickerStrings}
                                        />
                                        <DatePicker disabled={!ownsSelectedProject()} label={t('settingsPanel.projectsPanel.endDate')}
                                            styles={{ root: { width: 300 } }}
                                            value={selectedProject.endDate}
                                            onSelectDate={(date: any) => setSelectedProject({ ...selectedProject, endDate: new Date(date) })}
                                        />
                                    </div>
                                    <div>
                                        <div className='marginTopL textL'>{t('settingsPanel.projectsPanel.customer')}</div>
                                        {client
                                            ?
                                            <div className='marginTopM'>
                                                <div className='textM fontWeightBold'>{client.name} {client.legalForm}</div>
                                                <div className='textM'>{client.headquarters.street} {client.headquarters.streetNumber}</div>
                                                <div className='textM'>{client.headquarters.postalCode} {client.headquarters.city}, {client.headquarters.country}</div>
                                                {client.tradeRegisterId && client.tradeRegisterCity
                                                    ?
                                                    <div className='textM'>{client.tradeRegisterId}, Handelsregister {client.tradeRegisterCity}</div>
                                                    :
                                                    <></>
                                                }
                                                <ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Edit' }} onClick={() => {
                                                    setClient(null);
                                                    setSelectedProject({ ...selectedProject, customer: undefined });
                                                }}>
                                                    {t('global.change')}
                                                </ActionButton>
                                            </div>
                                            :
                                            <LegalPersonPicker className='marginTopM' setLegalPerson={(legalPerson: any) => {
                                                console.log(legalPerson);
                                                setClient(legalPerson);

                                                if (legalPerson) {
                                                    setSelectedProject({ ...selectedProject, customer: legalPerson._id });
                                                } else {
                                                    setSelectedProject({ ...selectedProject, customer: undefined });
                                                }
                                            }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="objects" headerText={t('settingsPanel.projectsPanel.objects')}>
                            <div>
                                <div className='marginTopL'>{t('settingsPanel.projectsPanel.buildingsSpacesDetails')}:</div>
                                <div className='marginTopL'>
                                    {<ActionButton disabled={!ownsSelectedProject()} iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setFloorName('');
                                            setSelectedFloors([]);
                                            setObjectSelectDialogOpen(true);
                                        }}>
                                        {t('settingsPanel.projectsPanel.addBuildingSpace')}
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={selectedProject ? selectedProject.objects : []}
                                        columns={objectColumns}
                                        customStyles={dataTableStyles}
                                        selectableRows
                                        selectableRowsSingle
                                        onSelectedRowsChange={({ selectedRows }) => {
                                            if (selectedRows && selectedRows.length > 0) {
                                                setSelectedObject(selectedRows[0]);
                                            } else {
                                                setSelectedObject(null);
                                            }
                                        }}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={false} text={t('settingsPanel.projectsPanel.noObjectsDefined')}></LoadingIndicator>}
                                    />
                                </div>
                                {selectedObject
                                    ?
                                    <div>
                                        <div className="marginTopL textL">{t('settingsPanel.projectsPanel.assets')}</div>
                                        <AssetList className="marginTopM" scopeBuildings={selectedObject.building ? [selectedObject.building] : []} scopeFloors={selectedObject.floors}></AssetList>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </PivotItem>
                        <PivotItem id="entitlements" headerText={t('settingsPanel.projectsPanel.entitlements')}>
                            <div>
                                <div className='marginTopL'>
                                    {<ActionButton disabled={!ownsSelectedProject() && !canAdministrateSelectedProject()} iconProps={{ iconName: 'Add' }}
                                        onClick={() => {
                                            setSelectedEntitlement(null);
                                            setPartnerSelectDialogOpen(true);
                                        }}>
                                        {t('settingsPanel.projectsPanel.addPartner')}
                                    </ActionButton>}
                                </div>
                                <div className='borderNeutral'>
                                    <DataTable
                                        data={entitlements}
                                        columns={entitlementColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={loadingEntitlements} text={'Keine Berechtigungen verfügbar.'}></LoadingIndicator>}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="documents" headerText={t('settingsPanel.projectsPanel.documents')}>
                            <div>
                                <div className="marginTopXL">
                                    <FileUpload onUpload={onDocumentsUpload}></FileUpload>
                                </div>
                                <div className='marginTopL borderNeutral'>
                                    <DataTable
                                        data={documentDummies}
                                        columns={documentColumns}
                                        customStyles={dataTableStyles}
                                        pagination
                                        paginationPerPage={5}
                                        noDataComponent={<LoadingIndicator loading={true}></LoadingIndicator>}
                                    />
                                </div>
                            </div>
                        </PivotItem>
                    </Pivot>
                </div>
            </div>
            :
            <div>
                <div className='textHeader'>
                    {t('settingsPanel.projectsPanel.projects')}
                </div>
                <div className='marginTopM'>
                    <ActionButton iconProps={{ iconName: 'AddToShoppingList' }}
                        onClick={() => {
                            setSelectedProject(new Project({ name: `Project ${new Date().toLocaleDateString()}`, startDate: new Date() }));
                            setEntitlements([]);
                            setClient(null);
                        }}>
                        {t('settingsPanel.projectsPanel.createProject')}
                    </ActionButton>
                    <div className='marginTopL borderNeutral'>
                        <DataTable
                            data={projects}
                            columns={columns}
                            customStyles={dataTableStyles}
                            selectableRows
                            selectableRowsSingle
                            onSelectedRowsChange={({ selectedRows }) => {
                                if (selectedRows && selectedRows.length > 0) {
                                    setClient((selectedRows[0] as any)._company);
                                    selectProject(selectedRows[0]);
                                } else {
                                    selectProject(null);
                                }
                            }}
                            pagination
                            paginationPerPage={5}
                            noDataComponent={<LoadingIndicator loading={loadingProjects} text='Keine Projekte verfügbar.'></LoadingIndicator>}
                        />
                    </div>
                </div>
            </div>
        }
    </div >
        <Dialog
            hidden={!objectSelectDialogOpen}
            onDismiss={() => setObjectSelectDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.objectSelection'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>{t('settingsPanel.projectsPanel.addressBuilding')}</Label>
                    <AddressPicker setAddress={async (address: any) => {
                        setAddress(address);

                        if (!address) {
                            return;
                        }

                        const buildings = await userAPI.query(new Query(new EntityQuery(Building.type, [], undefined,
                            is('id').equal(`${address.street} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.country}`)), [new LinkedEntityQuery('floors', RelationshipDirection.out, Floor.type)]));

                        if (buildings.length && buildings[0].floors && buildings[0].floors.length) {
                            setFloors(buildings[0].floors);
                        } else {
                            setFloors([]);
                        }
                    }} />
                </div>
                <div className='marginTopM'>
                    <ChoiceGroup disabled={!address} selectedKey={buildingPart} options={[{ key: BuildingPart.wholeBuilding, text: t('settingsPanel.projectsPanel.wholeBuilding') }, { key: BuildingPart.partOfBuilding, text: t('settingsPanel.projectsPanel.partOfBuilding') }]}
                        onChange={(_: any, option: any) => setBuildingPart(option.key)} label={t('settingsPanel.projectsPanel.space')} />
                </div>
                <div className="marginTopM height100">
                    {buildingPart === BuildingPart.wholeBuilding
                        ?
                        <></>
                        :
                        <>
                            {floors.length > 0
                                ?
                                <Dropdown disabled={!address}
                                    label="Floor"
                                    options={floors.map((floor: any) => {
                                        return {
                                            key: floor._id,
                                            text: floor.name
                                        }
                                    })}
                                    multiSelect
                                    selectedKeys={selectedFloors.map((floor: any) => floor._id)}
                                    onChange={(e: any, item: any) => {
                                        if (selectedFloors.find((floor: any) => floor._id === item.key)) {
                                            setSelectedFloors(selectedFloors.filter((floor: any) => floor._id !== item.key));
                                        } else {
                                            setSelectedFloors([...selectedFloors, floors.find((floor: any) => floor._id === item.key)]);
                                        }

                                        console.log('Selected floors >>>', selectedFloors);
                                    }}
                                    styles={{
                                        dropdown: { width: 300 },
                                    }}
                                />
                                :
                                <TextField label="Floor name" value={floorName} onChange={(_: any, floorName: any) => setFloorName(floorName)}
                                    onGetErrorMessage={(floorName: any) => {
                                        return floorName ? '' : t('settingsPanel.projectsPanel.enterFloorName');
                                    }}
                                    validateOnFocusOut></TextField>
                            }

                        </>
                    }
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!isBuildingPartComplete()} text={t('global.add')} onClick={async () => {
                    let object;

                    if (buildingPart === BuildingPart.wholeBuilding) {
                        // TODO Lookup or even create building

                        object = { buildingPart, building: { id: `${address.street} ${address.streetNumber}, ${address.postalCode} ${address.city}, ${address.country}`, address }, address };
                    } else {
                        // TODO Create floor from floor name

                        const floors = floorName ? [{ name: floorName }] : selectedFloors;

                        object = { buildingPart, floors, address };
                    }

                    setSelectedProject({ ...selectedProject, objects: [...selectedProject.objects, object] });
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} />
                <DefaultButton onClick={() => {
                    setObjectSelectDialogOpen(!objectSelectDialogOpen);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!partnerSelectDialogOpen}
            onDismiss={() => setPartnerSelectDialogOpen(!partnerSelectDialogOpen)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.selectPartner'),
                //subText: 'Do you want to send this message without a subject?',
                //maxWidth: 800,
                //width: 800
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                <div>
                    <Label>{t('settingsPanel.projectsPanel.company')}</Label>
                    <LegalPersonPicker setLegalPerson={(partner: any) => setSelectedEntitlement({ roles: [], partner })} />
                </div>
                <div className='marginTopM textL'>
                    {t('settingsPanel.projectsPanel.partnerEntitlements')}
                </div>
                <div className='marginTopM'>
                    <Checkbox label={t('settingsPanel.projectsPanel.administration')} onChange={(_: any, checked: any) => {
                        // TODO Enum for roles

                        const newRoles = selectedEntitlement.roles.filter((role: string) => role === 'administration');

                        if (checked) {
                            newRoles.push('administration');
                        }

                        setSelectedEntitlement({ ...selectedEntitlement, roles: newRoles });
                    }} />
                    <div className='marginTopS textS'>{t('settingsPanel.projectsPanel.administrationDetails')}</div>
                </div>
                <div className='marginTopS'>
                    <Checkbox label={t('settingsPanel.projectsPanel.assetManagement')} onChange={(_: any, checked: any) => {
                        // TODO Enum for roles

                        const newRoles = selectedEntitlement.roles.filter((role: string) => role === 'assetRegistration');

                        if (checked) {
                            newRoles.push('assetRegistration');
                        }

                        setSelectedEntitlement({ ...selectedEntitlement, roles: newRoles });
                    }} />
                    <div className='marginTopS textS'>{t('settingsPanel.projectsPanel.assetManagementDetails')}</div>
                </div>
                <div className='marginTopS'>
                    <Checkbox label={t('settingsPanel.projectsPanel.dataAnalysis')} onChange={(_: any, checked: any) => {
                        // TODO Enum for roles

                        const newRoles = selectedEntitlement.roles.filter((role: string) => role === 'dataAnalysis');

                        if (checked) {
                            newRoles.push('dataAnalysis');
                        }

                        setSelectedEntitlement({ ...selectedEntitlement, roles: newRoles });
                    }} />
                    <div className='marginTopS textS'>{t('settingsPanel.projectsPanel.dataAnalysisDetails')}</div>
                </div>
                <div className='marginTopM textL'>
                    {t('settingsPanel.projectsPanel.primaryContact')}
                </div>
                <div className='marginTopM textS'>
                    {t('settingsPanel.projectsPanel.primaryContactDetails')}
                </div>
                <div className='marginTopM'>
                    <TextField required label={t('settingsPanel.projectsPanel.primaryContactEmail')} value={selectedEntitlement ? selectedEntitlement.primaryContactEmail : ''} onChange={(_: any, primaryContactEmail: any) => {
                        setSelectedEntitlement({ ...selectedEntitlement, primaryContactEmail });
                    }} onGetErrorMessage={(primaryContactEmail: any) => {
                        return primaryContactEmail && isValidEmail(primaryContactEmail) ? '' : t('settingsPanel.projectsPanel.enterValidEmail');
                    }}
                        validateOnFocusOut></TextField>
                </div>
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton disabled={!selectedEntitlement || !isSelectedEntitlementValid()} text={t('global.add')} onClick={() => {
                    setEntitlements([...entitlements, selectedEntitlement]);
                    setSelectedEntitlement(null);
                    setPartnerSelectDialogOpen(false);
                }} />
                <DefaultButton onClick={() => {
                    setPartnerSelectDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
        <Dialog
            hidden={!addDocumentDialogOpen}
            onDismiss={() => setAddDocumentDialogOpen(false)}
            maxWidth={600}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.normal,
                title: t('settingsPanel.projectsPanel.addDocument'),
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <div>
                {selectedFile ?
                    <div>
                        <div className="displayFlex gapL">
                            <div><FontIcon iconName="PDF" className="colorPrimary" style={{ fontSize: 50, height: 50, width: 50, }} /></div>
                            <div>
                                <div className='textS fontWeightBold'>{selectedFile.name}</div>
                                <div className='textS colorGrey'>{selectedFile.lastModifiedDate.toLocaleDateString()}</div>
                                <div className='textS colorPrimary'>{numberFormat.format(selectedFile.size / 1000000)} MB</div>
                            </div>
                        </div>
                        <div className='marginTopL'>
                            <Dropdown
                                label={t('settingsPanel.projectsPanel.documentType')}
                                options={documentTypeOptions}
                                selectedKey={documentType}
                                onChange={(e: any, item: any) => setDocumentType(item.key)}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            />
                        </div>
                        <div className='marginTopM'>
                            <Dropdown
                                options={[{ key: '_self', text: 'Nur eigenes Unternehmen' }, { key: '_all', text: 'All' }, ...entitlements.map((entitlement: any) => { return { key: entitlement.partner._id, text: entitlement.partner.name } })]}
                                selectedKeys={documentVisibility}
                                multiSelect
                                onChange={(e: any, item: any) => {
                                    if (documentVisibility.indexOf(item.key) >= 0) {
                                        if (item.key !== '_self' && item.key !== '_all') {
                                            setDocumentVisibility(documentVisibility.filter((key: string) => key !== item.key));
                                        }
                                    } else {
                                        if (item.key === '_self' || item.key === '_all') {
                                            setDocumentVisibility([item.key]);
                                        } else {
                                            setDocumentVisibility([...documentVisibility.filter((key: string) => key !== '_self' && key !== '_all'), item.key]);
                                        }
                                    }
                                }}
                                styles={{
                                    dropdown: { width: 300 },
                                }}
                            /></div>
                    </div>
                    :
                    <></>
                }
            </div>
            <DialogFooter className="marginTopXXL">
                <PrimaryButton text={t('global.add')} onClick={() => {
                    setAddDocumentDialogOpen(false);
                }} />
                <DefaultButton onClick={() => {
                    setAddDocumentDialogOpen(false);
                }} text={t('global.cancel')} />
            </DialogFooter>
        </Dialog>
    </>;
}