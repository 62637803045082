import '../App.sass';
import 'animate.css';
import { useTranslation } from "react-i18next";

export default function SecurityPanel() {
  const { t } = useTranslation();

  return <><div className="centeredContentSection">
    <div className='marginTopXXL titleText textAlignCenter'>{t('homePage.securityPanel.mostSecurePlatform')}</div>
    <div className=''>
    <div className="height400 centeredContentSection">
      <div className='marginTopXL largeBodyText textAlignCenter'>Alle Daten auf deutschem Boden.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Multi-factor Authentication.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Jährliche Pen-Tests.</div>
      <div className="marginTopM largeBodyText textAlignCenter">Audit-fähiges Information Management System.</div>
    </div>
    </div>
  </div>
    <div className='marginTopXXL marginBottomXXL centeredContentSection animate__animated animate__fadeIn animate__slow'>
      <div className='headerText'>{t('homePage.securityPanel.certificatesAndStandards')}</div>
      <div className='marginTopL bodyText'>{t('homePage.securityPanel.alwaysWorkingOnStandardsCompliance')}</div>
    </div>
  </>;
}
