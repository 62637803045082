export const assetIcons = [
    {
        id: 'fire-extinguisher',
        label: 'Feuerlöscher',
        color: '#FF0000',
    }, {
        id: 'fire-hydrant',
        label: 'Feuerhydrant',
        color: '#FF0000',
    }, {
        id: 'pacemaker',
        label: 'Herzschrittmache',
        color: '#FF0000',
    }, {
        id: 'defibrillator',
        label: 'Defibrillator',
        color: '#FF0000',
    }, {
        id: 'heat-pump',
        label: 'Wärmepumpe',
        color: '#0000FF',
    }, {
        id: 'fan',
        label: 'Ventilator',
        color: 'grey',
    }, {
        id: 'valve',
        label: 'Ventil',
        color: 'grey',
    }, {
        id: 'electricity-meter',
        label: 'Elektrizitätszähler',
        color: 'grey',
    }, {
        id: 'escalator',
        label: 'Rolltreppe',
        color: 'grey',
    }, {
        id: 'door',
        label: 'Tür',
        color: 'grey',
    }, {
        id: 'fire-damper',
        label: 'Brandschutzklappe',
        color: 'red',
    }
];

export const getAssetIcon = (id: string) => assetIcons.find((icon: any) => icon.id === id);

interface IconProperties {
    id: string;
}

export function Icon(properties: IconProperties) {
    return <img src={`svgs/${properties.id}.svg`} alt={properties.id}></img>
}