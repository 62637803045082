import React from "react";

export const ListResource = ({ resource }) => {
    return <div className={'resource'}>
        <div className={'title'}>{resource.title}</div>
        <ul>{
            resource.data.map((item) => {
                return <li>{item}</li>
            })
        }</ul>
    </div>
}
