import './App.sass';
import './markdown.css';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Markdown from 'react-markdown';
import { userAPI } from "./UserAPI";
import { useEffect, useState } from "react";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useAtom } from "jotai";
import { authenticationAtom } from './State';
import { Text } from '@fluentui/react/lib/Text';
import remarkToc from 'remark-toc';
import { FontIcon } from '@fluentui/react/lib/Icon';

export default function MarkupPage() {
    const { t } = useTranslation();
    const [authentication] = useAtom(authenticationAtom);
    const [markup, setMarkup] = useState('')
    const [title, setTitle] = useState('')
    const params = useParams();

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }, [markup]);
    
    useEffect(() => {
        const call = async () => {
            if (params && params['*']) {
                const path = params['*'];
                const resourcePath = path.lastIndexOf('/') >= 0 ? path.substring(path.lastIndexOf('/')) : '';
                const resource = path.substring(path.lastIndexOf('/') ? path.lastIndexOf('/') + 1 : 0, path.indexOf('.'));
                let newMarkup = await userAPI.getMarkup(path);

                console.log(newMarkup);
                newMarkup = newMarkup.replace(/!\[Image\]\(/g, `![Image](${userAPI.getResourceURL(resourcePath)}/markup/`);
                console.log(newMarkup);

                setMarkup(newMarkup);
                setTitle(resource.replace(/_/g, ' '));
            }
        };

        call();
    }, [params]);

    const back = () => {
        window.history.back()
    }

    return <div className="markupPage markdown-body">
        <div className="borderBottomNeutral displayFlex alignItemsCenter">
            <div className="paddingM hoverHand" onClick={back}><FontIcon
                iconName="Back"
                style={{
                    height: '30px',
                    width: '30px',
                }}
            /></div>
            <div className='flexGrow1 displayFlex justifyContentCenter'>
                <div className='title'><Text variant='xxLarge'>{title}</Text></div>
            </div>
        </div>
        <div className="marginTopXL displayFlex justifyContentCenter">
            <div className="markup">
                <Markdown rehypePlugins={[rehypeRaw] as any} remarkPlugins={[remarkGfm, [remarkToc, { heading: '(table[ -]of[ -])?Inhalt?|toc' }]]}>{markup}</Markdown>
            </div >
        </div>
    </div>;
}