import '../App.sass';
import { useEffect, useState } from 'react';
import { ActionButton, DefaultButton, Dropdown, Label, Pivot, PivotItem, PrimaryButton, TextField } from '@fluentui/react';
import { userAPI } from '../UserAPI';
import Section from '../components/Section';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { AndCondition, ComparisonCondition, Condition, EntityQuery, OrCondition, Product, Query, User } from '@marc.gille-sepehri/tri-model';
import { dataTableStyles } from '../styles';
import AssetList from '../components/AssetList';
import ConditionSection from '../components/ConditionSection';
import { useAdministrationAPI } from '../AdministrationAPI';

interface Properties {
    processError: (error: any) => void;
}

enum FieldType {
    integerNumber = 'integerNumber',
    decimalNumber = 'decimalNumber',
    string = 'string',
    boolean = 'boolean',
}

class TelemetryField {
    name?: string;
    type: FieldType;

    constructor(name: string | undefined = undefined, type: FieldType = FieldType.integerNumber) {
        this.name = name
        this.type = type
    }
}

const fieldType = [
    { key: FieldType.integerNumber, text: 'Integer' },
    { key: FieldType.decimalNumber, text: 'Decimal Number' },
    { key: FieldType.string, text: 'String' },
    { key: FieldType.boolean, text: 'Boolean' },
];

export default function ProductManagementPanel(properties: Properties) {
    const { t } = useTranslation();
    const { createOrUpdateProduct } = useAdministrationAPI();
    const [tab, setTab] = useState('productData') as any;
    const [products, setProducts] = useState([]) as any;
    const [telemetryFields, setTelemetryFields] = useState([]) as any;
    const [selectedProduct, setSelectedProduct] = useState() as any;
    const [warningConditions, setWarningConditions] = useState([]) as any;
    const [criticalConditions, setCriticalConditions] = useState([]) as any;

    const columns = [{
        name: 'Product',
        selector: (product: Product) => product.name,
        width: '300px',
        cell: (product: any) => <span>{product.name}</span>,
        sortable: true,
    }, {
        name: 'Category',
        selector: (product: Product) => product.schemaCategory,
        width: '150px',
        cell: (product: Product) => <span>{product.schemaCategory}</span>,
        sortable: true,
    }, {
        name: 'Manufacturer',
        selector: (product: Product) => product.manufacturer ? product.manufacturer.name : '-',
        width: '150px',
        cell: (product: Product) => <span>{product.manufacturer ? product.manufacturer.name : '-'}</span>,
        sortable: true,
    }];

    const selectProduct = (product: any) => {
        setSelectedProduct(product);

        if (product) {
            setWarningConditions(product.warningConditions || []);
            setCriticalConditions(product.criticalConditions || []);

            if (product.telemetry) {
                setTelemetryFields(product.telemetry.fields || []);
            }
        }
    };

    useEffect(() => {
        const call = async () => {
            try {
                setProducts(await userAPI.query(new Query(new EntityQuery(Product.type))));
            } catch (error) {
                properties.processError(error);
            }
        };

        call();
    }, [selectedProduct]);

    const fieldsComplete = () => {
        for (const field of telemetryFields) {
            if (!field.name || field.name.trim().length === 0) {
                return false
            }
        }

        return true;
    }

    const fieldsValid = () => {
        if (telemetryFields.length === 0) {
            return false;
        }

        if (!fieldsComplete()) {
            return false;
        }

        for (const field of telemetryFields) {
            for (const otherField of telemetryFields) {
                if (field === otherField) {
                    continue;
                }

                if (field.name === otherField.name) {
                    return false;
                }
            }
        }

        return true;
    }

    const conditionMenuProps = (addCondition: (condition: Condition) => void) => {
        return {
            items: [
                {
                    key: 'comparison',
                    text: 'Vergleich',
                    onClick: () => {
                        addCondition(new ComparisonCondition())
                    },
                },
                {
                    key: 'and',
                    text: 'UND-Verknüpfung',
                    onClick: () => addCondition(new AndCondition()),
                },
                {
                    key: 'or',
                    text: 'ODER-Verknüpfung',
                    onClick: () => addCondition(new OrCondition()),
                },
            ],
        }
    };

    const schemaItems: any[] = [
        { key: 'din276', text: 'DIN 276' },
    ];

    const setParams = (value: any) => {
        const url = new URL(window.location.href);

        url.searchParams.set('tab', value.key.substring(1));
        window.history.pushState(null, '', url.toString());

        setTab(value.key.substring(1));
    }

    return <><div>
        {selectedProduct
            ?
            <div>
                <div className='displayFlex alignItemsCenter'>
                    <div className='flexGrow1 displayFlex alignItemsCenter'>
                        <span className='textL'>{selectedProduct.name}</span>
                    </div>
                    <div className='displayFlex alignItemsCenter justifyContentEnd gapM'>
                        <DefaultButton
                            onClick={() => {
                                setSelectedProduct(null);
                            }}>
                            Close
                        </DefaultButton>
                        <PrimaryButton
                            disabled={!fieldsValid()}
                            onClick={async () => {
                                try {
                                    const newProduct = new Product({
                                        ...selectedProduct,
                                        telemetry: {
                                            fields: telemetryFields
                                        },
                                        warningConditions,
                                        criticalConditions,
                                    });

                                    await createOrUpdateProduct(newProduct);

                                    setSelectedProduct(null);
                                } catch (error) {
                                    properties.processError(error);
                                }
                            }}>
                            Save
                        </PrimaryButton>
                    </div>
                </div>
                <div className='marginTopM displayFlex'>
                    <Pivot aria-label="" onLinkClick={setParams} selectedKey={tab}>
                        <PivotItem id="productData" headerText={'Product Data'} itemIcon="UserOptional">
                            <div className='width600'>
                                <div className="paddingM">
                                    <TextField label="Name"
                                        value={selectedProduct.name}
                                        onChange={(e: any, name: string | undefined) => {
                                            setSelectedProduct({ ...selectedProduct, name });
                                        }}
                                        onGetErrorMessage={(name: string) => (name && name.trim().length > 0)
                                            ? '' : 'Name muss gesetzt sein.'}
                                        style={{ width: '300px' }} />
                                    <div>
                                        <Label>Schema</Label>
                                        <Dropdown
                                            // @ts-ignore
                                            //onChange={onPOITypesSelectionChange}
                                            options={schemaItems}
                                            selectedKey={selectedProduct.schema}
                                            onChange={(event: any, item: any) => {
                                            }}
                                            styles={{ dropdown: { width: 200 } }}
                                        />
                                    </div>
                                    <div>
                                        <Label>Schema Category</Label>
                                        <Dropdown
                                            // @ts-ignore
                                            //onChange={onPOITypesSelectionChange}
                                            options={schemaItems}
                                            selectedKey={selectedProduct.schema}
                                            onChange={(event: any, item: any) => {
                                            }}
                                            styles={{ dropdown: { width: 200 } }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="telemetry" headerText={'Telemetry'} itemIcon="PageLock">
                            <div className='width1200'>
                                <div className="width600">
                                    <Section title='Telemetry Fields'>
                                        <div className='marginTopM'>
                                            {telemetryFields.map((field: TelemetryField, index: number) => <div className='marginBottomS displayFlex alignItemsCenter gapM'>
                                                <ActionButton iconProps={{ iconName: 'Delete' }}
                                                    onClick={() => {
                                                        setTelemetryFields(telemetryFields.filter((entry: TelemetryField) => field !== entry));
                                                    }}>
                                                </ActionButton>
                                                <div className='displayFlex alignItemsStart gapM'>
                                                    <TextField label="Name"
                                                        value={field.name}
                                                        onChange={(e: any, name: string | undefined) => {
                                                            const newTelemetryFields = [...telemetryFields];

                                                            newTelemetryFields[index] = new TelemetryField(name, field.type);

                                                            setTelemetryFields(newTelemetryFields);
                                                        }}
                                                        onGetErrorMessage={(name: string) => (name && name.trim().length > 0)
                                                            ? '' : 'Name muss gesetzt sein.'} />
                                                    <Dropdown
                                                        label="Type"
                                                        options={fieldType}
                                                        selectedKey={field.type}
                                                        onChange={(e: any, item: any) => {
                                                            const newTelemetryFields = [...telemetryFields];

                                                            newTelemetryFields[index] = new TelemetryField(field.name, item.key);

                                                            setTelemetryFields(newTelemetryFields);
                                                        }}
                                                        styles={{ dropdown: { width: 200 } }}
                                                    />
                                                </div>
                                            </div>)}
                                            <div>
                                                <ActionButton disabled={!fieldsComplete()} iconProps={{ iconName: 'AddTo' }}
                                                    onClick={() => {
                                                        setTelemetryFields([...telemetryFields, new TelemetryField()]);
                                                    }}>
                                                </ActionButton>
                                            </div>
                                        </div>
                                    </Section>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="operatingState" headerText={'Operating State'} itemIcon="CityNext">
                            <div className='width1200'>
                                <div className="paddingM">
                                    <Section title='Normal'>
                                        <div className='displayFlex gapL'>
                                            <div className='displayFlex flexDirectionColumn justifyContentCenter'>
                                                <div className='width30 height30 backgroundColorGreen'></div>
                                            </div>
                                            <div className='width300'>
                                                Das Gerät arbeitet normal.
                                            </div>
                                            <div>
                                                Standardbetriebszustand wenn nicht anders gesetzt oder ermittelt.
                                            </div>
                                        </div>
                                    </Section>
                                    <Section title='Warning'>
                                        <div className='displayFlex gapL'>
                                            <div className='displayFlex flexDirectionColumn justifyContentCenter'>
                                                <div className='width30 height30 backgroundColorAmber'></div>
                                            </div>
                                            <div className='width300'>
                                                Das Gerät arbeitet mit Einschränkungen aufgrund expliziter Fehlermeldungen vom Gerät oder nebenstehender Bedingungen.
                                            </div>
                                            <div>
                                                <ActionButton menuProps={conditionMenuProps((condition: Condition) => {
                                                    setWarningConditions([...warningConditions, condition]);
                                                })}>
                                                    Bedingung ergänzen
                                                </ActionButton>
                                                {warningConditions.map((condition: Condition, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                                                    <ActionButton iconProps={{ iconName: 'Delete' }}
                                                        onClick={() => {
                                                            setWarningConditions(warningConditions.filter((entry: Condition) => condition !== entry));
                                                        }}>
                                                    </ActionButton>
                                                    <ConditionSection condition={condition} setCondition={(condition: Condition) => {
                                                        const newConditions = [...warningConditions];

                                                        newConditions[index] = condition;

                                                        setWarningConditions(newConditions);
                                                    }}></ConditionSection>
                                                </div>)}
                                            </div>
                                        </div>
                                    </Section>
                                    <Section title='Critical'>
                                        <div className='displayFlex gapL'>
                                            <div className='displayFlex flexDirectionColumn justifyContentCenter'>
                                                <div className='width30 height30 backgroundColorRed'></div>
                                            </div>
                                            <div className='width300'>
                                                Der Betrieb des Gerätes ist gestört aufgrund expliziter Fehlermeldungen vom Gerät oder nebenstehender Bedingungen.
                                            </div>
                                            <div>
                                                <ActionButton menuProps={conditionMenuProps((condition: Condition) => {
                                                    setCriticalConditions([...criticalConditions, condition]);
                                                })}>
                                                    Bedingung ergänzen
                                                </ActionButton>
                                                {criticalConditions.map((condition: Condition, index: number) => <div key={`condition${index}`} className='marginBottomS displayFlex alignItemsCenter gapM'>
                                                    <ActionButton iconProps={{ iconName: 'Delete' }}
                                                        onClick={() => {
                                                            setCriticalConditions(warningConditions.filter((entry: Condition) => condition !== entry));
                                                        }}>
                                                    </ActionButton>
                                                    <ConditionSection condition={condition} setCondition={(condition: Condition) => {
                                                        const newConditions = [...criticalConditions];

                                                        newConditions[index] = condition;

                                                        setCriticalConditions(newConditions);
                                                    }}></ConditionSection>
                                                </div>)}
                                            </div>
                                        </div>
                                    </Section>
                                    <Section title='Maintenance'>
                                        <div className='displayFlex gapL'>
                                            <div className='displayFlex flexDirectionColumn justifyContentCenter'>
                                                <div className='width30 height30 backgroundColorBlue'></div>
                                            </div>
                                            <div className='width300'>
                                                Das Gerät wird zur Zeit gewartet. Alarme werden weiter verarbeitet.
                                            </div>
                                            <div>
                                                Dieser Betriebszustand wir explizit (z.B. aus der App) gesetzt.
                                            </div>
                                        </div>
                                    </Section>
                                    <Section title='Not in Use'>
                                        <div className='displayFlex gapL'>
                                            <div className='displayFlex flexDirectionColumn justifyContentCenter'>
                                                <div className='width30 height30 backgroundColorPurple'></div>
                                            </div>
                                            <div className='width300'>
                                                Das Gerät wird zur Zeit nicht genutzt und für zukünftige Nutzung gelagert.
                                            </div>
                                            <div>
                                                Dieser Betriebszustand wir explizit (z.B. aus der App) gesetzt.
                                            </div>
                                        </div>
                                    </Section>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="devices" headerText={'Devices'} itemIcon="PageLock">
                            <div className='width1200'>
                                <div className="paddingM">
                                    <AssetList product={selectedProduct}></AssetList>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem id="documents" headerText={'Documents'} itemIcon="Documentation">
                            <div className='width1200'>
                            </div>
                        </PivotItem>
                    </Pivot>
                </div>
            </div>
            :
            <div>
                <ActionButton iconProps={{ iconName: 'AppIconDefaultAdd' }}
                    onClick={() => {setSelectedProduct({ name: 'Schneidige Meppen' })}}>
                    Create product
                </ActionButton>
                <div className='marginTopL borderNeutral'>
                    <DataTable
                        data={products}
                        columns={columns}
                        customStyles={dataTableStyles}
                        selectableRows
                        selectableRowsSingle
                        onSelectedRowsChange={({ selectedRows }) => {
                            if (selectedRows && selectedRows.length > 0) {
                                selectProduct(selectedRows[0]);
                            } else {
                                selectProduct(null);
                            }
                        }}
                        pagination
                        paginationPerPage={5}
                    />
                </div>
            </div>
        }
    </div >
    </>;
}