import { makeStyles, shorthands } from "@fluentui/react-components";
import { PDFViewer, PDFDownloadLink, Document, Page, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { settingsAtom } from '../State';
import { userAPI } from '../UserAPI';
import { useAtom } from "jotai";
import { authenticationAtom } from '../State';
import { ActionButton, IconButton } from '@fluentui/react/lib/Button';
import { Fragment } from "react/jsx-runtime";
import ApexCharts from 'apexcharts'
import { v4 as uuidv4 } from 'uuid';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { useEffect, useState } from 'react';
import { mergeStyles } from '@fluentui/react';
import mapboxgl from 'mapbox-gl';

// General reporting settings

Font.register({
    family: 'Lato',
    fonts: [{
        src: 'http://fonts.gstatic.com/s/lato/v13/v0SdcGFAl2aezM9Vq_aFTQ.ttf',
        fontWeight: 400,
    }]
})
Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const fontFamily = 'Lato';
const useStyles = makeStyles({
    list: {
        ...shorthands.border('1px', 'solid', '#EFEFEF'),
        ...shorthands.padding('10px'),
        backgroundColor: 'white',
    },
    titleBar: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold',
        marginRight: '10px',
    },
    downloadLink: {
        marginTop: '10px',
        display: 'flex',
        gap: '10px',
        textDecoration: 'none',
    },
});

// <<<<<<<<<<<<<<<<<<<

const styles = StyleSheet.create({
    page: {
        paddingTop: 50,
        paddingBottom: 65,
        paddingHorizontal: 50,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: fontFamily,
        marginTop: 10,
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: fontFamily,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        fontFamily: fontFamily,
    },
    logo: {
        marginVertical: 10,
        marginHorizontal: 10,
        width: 70,
    },
    pageHeader: {
        fontSize: 10,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    // Table of content

    tocItem: {
        marginTop: 10,
        fontSize: 16,
        color: 'black',
    },

    // Text body

    paragraph: {
        fontSize: 11,
        fontFamily: fontFamily,
        color: 'black',
        marginBottom: 5,
    },
    header1: {
        fontSize: 16,
        fontWeight: 600,
        marginTop: 10,
        marginBottom: 10,
        fontFamily: fontFamily,
        color: 'black',
    },
    header2: {
        fontSize: 14,
        fontWeight: 600,
        marginTop: 7,
        marginBottom: 7,
        fontFamily: fontFamily,
        color: 'black',
    },
    header3: {
        fontSize: 12,
        fontWeight: 600,
        marginTop: 6,
        marginBottom: 6,
        fontFamily: fontFamily,
        color: 'black',
    },
    bodySmall: {
        fontSize: 10,
        fontFamily: fontFamily,
        color: 'black',
    },

    // Layout

    centered: {
        display: 'flex',
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: 'center',
    },
    bulletRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    bulletIcon: {
        height: '100%',
        fontSize: 11,
        color: 'black',
    },
    bulletText: {
        height: '100%',
        fontSize: 11,
        fontFamily: fontFamily,
        color: 'black',
    },

    // Table styles

    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 16,
    },
    row: {
        fontSize: 12,
        flexDirection: "row",
        alignItems: "center",
        padding: 7,
        borderBottom: '1px solid lightgrey'
    },
    cell: {
        width: "20%",
        fontSize: 9,
    },

    // Map styles

    mapAttribution: {
        marginTop: 5,
        fontSize: 8,
        fontFamily: fontFamily
    }
});

const PDFListItem = (properties: any) => {
    return (
        <View style={styles.bulletRow}>
            <View style={styles.bulletIcon}>
                <Text>{'\u2022' + " "}</Text>
            </View>
            <Text style={styles.bulletText}>{properties.children}</Text>
        </View>
    )
}

// @ts-ignore
const PDFChart = ({ options }) => {
    const id = uuidv4();

    options.chart.id = id;
    options.chart.width = 1000;
    options.chart.height = 500;
    options.chart.animations = { enabled: false };

    try {
        const container = document.createElement("div");

        container.id = id;
        container.style.visibility = "hidden";

        // @ts-ignore
        document.getElementById("sandbox").appendChild(container);

        const chart = new ApexCharts(container, options);

        console.log('Chart >>>', chart);

        const provider = new Promise(async (resolve, reject) => {
            await chart.render();

            const base64 = await chart.dataURI()

            // @ts-ignore
            resolve(base64.imgURI);
            // @ts-ignore
            document.getElementById("sandbox").removeChild(container);
        });

        // @ts-ignore
        return <Image src={provider} style={{ width: 300, height: 150 }}></Image>;
    } catch (error) {
        console.log(error);

        return <Text>Error in Image</Text>;
    }
}

// @ts-ignore
const PDFTable = ({ data, options }) => {
    return <View style={styles.tableContainer}>
        {/* <View>
            <>
                {options.headers.map((header) =>
                    <Text>{header}</Text>)
                }
            </>
        </View> */}
        <Fragment>
            {data.map((row: any) =>
                <View style={styles.row}>
                    {row.map((value: any) => <Text style={{ ...styles.cell, width: `"${100 / row.length}%"` }}>{value}</Text>)}
                </View>
            )}
        </Fragment>
    </View>
}

const renderMapLayer = (map: any, layer: any, index: number) => {
    console.log('Render layer  >>>', layer);

    let children: any = [];

    if (layer.props.children) {
        if (layer.props.children.length) {
            children = layer.props.children.flat();
        } else {
            children = [layer.props.children];
        }
    }

    const buildings = children.filter((child: any, index: number) => /*index < 30 && */ child.props.building && child.props.building.shape)
        .map((child: any) => child.props.building);

    map.addSource(`features${index}`, {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': buildings.map((building: any) => {
                return {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [building.shape.map((point: any) => [point.lon, point.lat])],
                    }
                };
            })
        }
    });

    if (layer.props.options.fillColor) {
        map.addLayer({
            'id': `features-fill-layer${index}`,
            'type': 'fill',
            'source': `features${index}`,
            'layout': {},
            'paint': {
                'fill-color': layer.props.options.fillColor
            }
        });
    }

    if (layer.props.options.color) {
        map.addLayer({
            'id': `features-line-layer${index}`,
            'type': 'line',
            'source': `features${index}`,
            'layout': {},
            'paint': {
                'line-color': layer.props.options.color,
                'line-width': layer.props.options.lineWidth || 1,
            }
        });
    }
}

// >>>> Supporting functions map

// colors to use for the categories

const colors = ['#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c'];

function createDonutChart(props: any) {
    const offsets = [];
    const counts = [
        props.mag1,
        props.mag2,
        props.mag3,
        props.mag4,
        props.mag5
    ];
    let total = 0;
    for (const count of counts) {
        offsets.push(total);
        total += count;
    }
    const fontSize =
        total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
    const r =
        total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div>
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

    for (let i = 0; i < counts.length; i++) {
        html += donutSegment(
            offsets[i] / total,
            (offsets[i] + counts[i]) / total,
            r,
            r0,
            colors[i]
        );
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
<text dominant-baseline="central" transform="translate(${r}, ${r})">
${total.toLocaleString()}
</text>
</svg>
</div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
}

function donutSegment(start: number, end: number, r: any, r0: any, color: string) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
        } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
        } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
        }" fill="${color}" />`;
}

interface PDFMapProperties {
    options: any;
    children?: any
}

const PDFMap = (properties: PDFMapProperties) => {
    const id = uuidv4();

    try {
        const container = document.createElement("div");

        container.id = id;
        container.style.visibility = "hidden";
        container.style.width = properties.options.width || 400;
        container.style.height = properties.options.width || 300;

        // @ts-ignore
        document.getElementById("sandbox").appendChild(container);

        const map = new mapboxgl.Map({
            container,
            style: properties.options.style || 'mapbox://styles/mapbox/light-v11',
            center: [properties.options.lon || 8.6960, properties.options.lat || 50.1064],
            zoom: properties.options.zoom || 16,
            preserveDrawingBuffer: true
        });

        var dpi = 300;

        Object.defineProperty(window, 'devicePixelRatio', {
            get: function () { return dpi / 96 }
        });

        const provider = new Promise(async (resolve, reject) => {
            map.on("load", () => {
                console.log('Children >>>', properties.children);

                let children: any = [];

                if (properties.children) {
                    if (properties.children.length) {
                        children = properties.children.flat();
                    } else {
                        children = [properties.children];
                    }
                }

                children.forEach((child: any, index: number) => {
                    if (child.type.name === 'PDFMapLayer') {
                        renderMapLayer(map, child, index);
                    }
                })

                // >>>>>>>

                const feature: any = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [properties.options.lon, properties.options.lat]
                    },
                    properties: {
                        mag1: 12,
                        mag2: 4,
                        mag3: 3,
                        mag4: 7,
                        mag5: 2
                    }
                };

                map.addSource('points', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            feature
                        ]
                    }
                });

                // map.addLayer({
                //     id: `points-circle`,
                //     type: 'circle',
                //     source: `points`,
                //     paint: {
                //         'circle-radius': 40,
                //         'circle-color': 'rgba(255, 0, 0, 1)'
                //     }
                // });

                // const features = map.querySourceFeatures('points');

                // for (const feature of features) {
                // console.log('Feature >>>', feature);

                //const element: any = createDonutChart(feature.properties);

                //console.log('Element >>>', element);

                // const element = document.createElement('div');

                // element.className = 'marker';

                // new mapboxgl.Marker().setLngLat(new mapboxgl.LngLat(properties.options.lon, properties.options.lat))
                //     .addTo(map);
                //});

                // <<<<<<

                map.resize();

                setTimeout(() => {
                    resolve(map.getCanvas().toDataURL());
                }, 4000);

                // @ts-ignore
                document.getElementById("sandbox").removeChild(container);
            });
        });

        // @ts-ignore
        return <View><Image src={provider} style={{ width: properties.options.width || 400, height: properties.options.height || 300 }}></Image>
            <Text style={styles.mapAttribution}>©️ Mapbox ©️ OpenStreetMap</Text>
        </View>;
    } catch (error) {
        console.log(error);

        return <Text>Error in Map.</Text>;
    }
}

const PDFMapLayer = (properties: any) => {
    return <></>
}

const PDFMapBuilding = (properties: any) => {
    return <></>
}

interface PDFMapPointsProperties {
}


interface PDFMapDonutChartProperties {
    points: any;
    colors: any;
}

const PDFMapDonutChart = (properties: PDFMapDonutChartProperties) => {
    return <></>
}

// >>>>>>>>>>>>>>>>

// TODO
// For now, report templates for specific skills are hardcoded here
// Will move to Node (and skill definitions there as soon as we have sorted out Apex rendering)

// @ts-ignore
const FireExtinguisherReport = ({ authentication, resource, settings }) => {
    // Some data aggregations

    const totalFireExtinguisherCount = 66;
    const totalSubsidiaryCount = 22;
    const totalCompanyCount = 2;
    const costSavingsPotential = 43;
    const co2SavingsPotential = 12;

    const headers = ['Name', 'HR', 'Rechtsform', 'Zweck', 'Adresse', 'Etagen', 'Gesch. Fläche', 'Bedarf Feuerlöscher'];
    const chartOptions = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    const companyPieChartOptions = {
        series: [425, 844, 312],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['Siemens', 'Deutsche Bank', 'Mainova'],
    };

    const postalCodePieChartOptions = {
        series: [44, 55, 13],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['60594', '60382', '60322'],
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(settings.corporateIdentity.logoImage) : 'https://react-pdf.org/images/logo.png'}></Image>
            <View>
                <Text>Bedarfe für Feuerlöscher in Frankfurt</Text>
            </View>
            <Text style={styles.header1}>Zusammenfassung</Text>
            <Text style={styles.paragraph}>
                In Frankfurt besteht in in ingesamt {totalSubsidiaryCount} Niederlassungen von ingesamt {totalCompanyCount} Unternehmen ein Bedarf an ingesamt {totalFireExtinguisherCount} Feuerlöschern.
                Bei einem Einsparpotential von {costSavingsPotential}€ und {co2SavingsPotential}kg CO2 pro Feuerlöscher entspricht das einem Gesamteinsparpotential von {costSavingsPotential * totalFireExtinguisherCount}€ und {co2SavingsPotential * totalFireExtinguisherCount} kg CO2.
            </Text>
            <Text style={styles.paragraph}>
                Die Niederlassungen befinden sich auf einer Fläche von insgesamt 77 qkm.
            </Text>
            <Text style={styles.header1}>Bedarfe nach Anzahl Feuerlöscher</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <Text style={styles.header1}>Bedarfe nach Unternehmen</Text>
            <Text style={styles.paragraph}>Geordnet nach Unternehmen bestehen die Bedarfe wie folgt:</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <Text style={styles.paragraph}>Und in der grafischen übersicht</Text>
            <View style={styles.centered}>
                <PDFChart options={companyPieChartOptions}></PDFChart>
            </View>
            <Text style={styles.header1}>Bedarfe nach Postleitzahlgebieten</Text>
            <Text style={styles.paragraph}>Geordnet nach Postleizahlen bestehen die Bedarfe wie folgt:</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <Text style={styles.paragraph}>Und in der grafischen übersicht</Text>
            <View style={styles.centered}>
                <PDFChart options={postalCodePieChartOptions}></PDFChart>
            </View>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Stadtgebiete</Text>
            <View style={styles.centered}>
                <PDFChart options={chartOptions}></PDFChart>
            </View>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Empfehlung</Text>
            <Text style={styles.paragraph}>Frankfurt hat einen hohen Bedarf auf einer relativ kleinen Fläche und ist ein gutes Ziel für den Austausch.</Text>
        </Page>
    </Document>
};

// @ts-ignore
const FireExtinguisherCompanyReport = ({ authentication, resource, settings }) => {
    // Some data aggregations

    const totalFireExtinguisherCount = 66;
    const totalSubsidiaryCount = 22;
    const totalCompanyCount = 2;
    const costSavingsPotential = 43;
    const co2SavingsPotential = 12;

    const headers = ['Name', 'HR', 'Rechtsform', 'Zweck', 'Adresse', 'Etagen', 'Gesch. Fläche', 'Bedarf Feuerlöscher'];
    const chartOptions = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    const companyPieChartOptions = {
        series: [425, 844, 312],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['Siemens', 'Deutsche Bank', 'Mainova'],
    };

    const postalCodePieChartOptions = {
        series: [44, 55, 13],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['60594', '60382', '60322'],
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(settings.corporateIdentity.logoImage) : 'logo.png'}></Image>
            <View>
                <Text>Bedarfe an Feuerlöschern für {resource.data.company}</Text>
            </View>
            <Text style={styles.header1}>Zusammenfassung</Text>
            <Text style={styles.paragraph}>
                An den in ingesamt {totalSubsidiaryCount} Niederlassungen von {resource.data.company} besteht insgesamt ein Bedarf an ingesamt {totalFireExtinguisherCount} Feuerlöschern.
                Bei einem Einsparpotential von {costSavingsPotential}€ und {co2SavingsPotential}kg CO2 pro Feuerlöscher entspricht das einem Gesamteinsparpotential von {costSavingsPotential * totalFireExtinguisherCount}€ und {co2SavingsPotential * totalFireExtinguisherCount} kg CO2.
            </Text>
            <Text style={styles.paragraph}>
                Die Niederlassungen befinden sich in insgesamt 5 Städten.
            </Text>
            <Text style={styles.header1}>Bedarfe nach Anzahl Feuerlöscher</Text>
            <PDFTable data={resource.data.buildings} options={{ headers }}></PDFTable>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Städte</Text>
            <View style={styles.centered}>
                <PDFChart options={chartOptions}></PDFChart>
            </View>
            <View style={styles.centered}></View>
            <Text style={styles.header1}>Empfehlung</Text>
            <Text style={styles.paragraph}>{resource.data.company} hat einen hohen Bedarf auf einer relativ kleinen Fläche und ist ein gutes Ziel für den Austausch.</Text>
        </Page>
    </Document>
};

// @ts-ignore
const CoolantReport = ({ authentication, resource, settings }) => {
    // Some data aggregations

    const totalFireExtinguisherCount = 66;
    const totalSubsidiaryCount = 22;
    const totalCompanyCount = 2;
    const costSavingsPotential = 43;
    const co2SavingsPotential = 12;

    const headers = ['Name', 'HR', 'Rechtsform', 'Zweck', 'Adresse', 'Etagen', 'Gesch. Fläche', 'Bedarf Feuerlöscher'];
    const chartOptions = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Product Trends by Month',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    };

    const companyPieChartOptions = {
        series: [425, 844, 312],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['Siemens', 'Deutsche Bank', 'Mainova'],
    };

    const postalCodePieChartOptions = {
        series: [44, 55, 13],
        chart: {
            width: 800,
            height: 800,
            type: 'pie',
        },
        labels: ['60594', '60382', '60322'],
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(settings.corporateIdentity.logoImage) : 'logo.png'}></Image>
            <View>
                <Text>Kältemittelnutzung und regulatorische Hinweise</Text>
            </View>
            <Text style={styles.header1}>Geänderte F-Gase Verordnung seit dem 1.1.2015</Text>
            <Text style={styles.paragraph}>
                Die EG-VO 517-2014 über teilfluorierte Treibhausgase hat die Verordnung Nr.
                842/2006 aufgehoben. Im Folgenden erfahren Sie, welche praktischen Auswirkungen die neue F-Gase Verordnung, die im Wesentlichen eine neue Gewichtung von
                Kältemittelfüllmengen und die stufenweise Reduzierung der Mengen der in Verkehr gebrachten teilfluorierten Kohlenwasserstoffe vorsieht, auf Ihre Anlagen hat.
            </Text>
            <Text style={styles.header1}>
                Neubau von Kälte- und Klimaanlagen
            </Text>
            <Text style={styles.header2}>Ortsfeste Kälteanlagen</Text>
            <Text style={styles.header2}>Klimaanlagen</Text>
            <Text style={styles.header1}>
                Service und Wartung von Kälte- und Klimaanlagen
            </Text>
            <Text style={styles.header2}>Ortsfeste Kälteanlagen</Text>
            <Text style={styles.header2}>Klimaanlagen</Text>
            <Text style={styles.paragraph}>
                Bis 2030 können die vorhandenen Kältemittel eingesetzt werden.
            </Text>
            <Text style={styles.header1}>
                Verfügbarkeit
            </Text>
            <Text style={styles.paragraph}>
                Neben den Neuregelungen für Kälte- und Klimaanlagen wird die Menge der in Verkehr gebrachten teilfluorierten Kältemittel begrenzt. Basis ist die in den Jahren 2009 - 2012 in der EU hergestellte und in die EU eingeführte durchschnittliche Gesamtmenge, ausgedrückt in CO2
                -Äquivalent.
            </Text>
            <Text style={styles.header1}>
                Dichtheitskontrollen
            </Text>
            <Text style={styles.header2}>
                Ortsfeste Kälteanlagen, Klimaanlagen und Wärmepumpen
            </Text>
            <Text style={styles.paragraph}>
                Ab dem 1.1.2015 ist die bisherige Logik vom 3/30/300 kg Füllgewicht auf CO2-Äquivalent umgestellt.
            </Text>
            <Text style={styles.paragraph}>
                In der Praxis müssen alle Dichtheitsprüfungsintervalle anhand der CO2-Äquivalent-Basis überprüft
                und entsprechend angepasst werden.
                Für Betreiber von Einrichtungen, an denen Dichtheitskontrollen vorgeschrieben sind, besteht
                Dokumentationspflicht.
                Führung von Aufzeichnungen:
                1. Menge und Art der enthaltenen fluorierten Treibhausgase
                2. Alle Mengen die hinzugefügt werden
                3. Werden recycelte oder aufgearbeitete fluorierte Treibhausgase verwendet, so ist Name und Anschrift der Recycling- oder Aufarbeitungsanlage (ggfs. mit Zertifizierungsnummer) anzugeben
                4. Alle Mengen die entnommen werden
                5. Angaben zum Unternehmen, das Arbeiten an der Einrichtung durchgeführt hat
                (ggfs. mit Zertifizierungsnummer)
                6. Ergebnisse der Dichtheitsprüfung (Nachprüfung)
                7. Maßnahmen zur Rückgewinnung, Entsorgung der fluorierten Treibhausgase bei Stilllegung der
                Einrichtung
                Betreiber und ausführende Unternehmen sind verpflichtet die Aufzeichnungen mindestens 5 Jahre
                aufzubewahren.
            </Text>
            <PDFMap options={{}}></PDFMap>
            <PDFMap options={{ style: 'mapbox://styles/mapbox/satellite-streets-v9' }}></PDFMap>
        </Page>
    </Document>
};

// @ts-ignore
const EnergyEfficiencyReport = ({ authentication, resource, settings }) => {
    console.log('Resource >>>', resource);
    console.log('Settings >>>', settings);

    const plotOfLandTable = [
        ['Flurstück', '38/2'],
        ['Grundstücksfläche', '394 m²'],
        ['Bebaute Fläche', '151 m²'],
        ['GRZ 0', '38'],
        ['GRZ 2', '0,00'],
        ['BMZ 2', '22'],
        ['Alter des Grundstücks', '1976'],
    ];

    return <Document>
        <Page size="A4" style={styles.page} wrap>
            <Text style={styles.pageHeader} fixed>
                ~ Created with The Real Insight ~
            </Text>
            <Image style={styles.logo} src={settings && settings.corporateIdentity && settings.corporateIdentity.logoImage ? userAPI.getResourceURL(settings.corporateIdentity.logoImage) : 'logo.png'}></Image>
            <Text style={styles.title}>Energieeffizienz und Immobilienbewertung</Text>
            <Text style={styles.header1}>{resource.data.building.id}</Text>
            <View break style={styles.title}>
                <Text>Inhalt</Text>
            </View>
            <View style={styles.paragraph}>
                <Text style={styles.tocItem}>Stammdaten</Text>
                <Text style={styles.tocItem}>Nachhaltigkeitsbewertung</Text>
                <Text style={styles.tocItem}>Bebauungspotenzial</Text>
                <Text style={styles.tocItem}>Sanierungspotenziale</Text>
                <Text style={styles.tocItem}>Kartendarstellungen</Text>
                <Text style={styles.tocItem}>Quellen</Text>
                <Text style={styles.tocItem}>Glossar</Text>
            </View>
            <Text break style={styles.header1}>Stammdaten</Text>
            <PDFTable data={plotOfLandTable} options={{}}></PDFTable>
            <Text style={styles.header1}>Nachhaltigkeitsbewertung</Text>
            <Text style={styles.header1}>Bebauungspotenzial</Text>
            <Text style={styles.header1}>Sanierungspotenzial</Text>
            <Text break style={styles.header1}>Kartendarstellung</Text>
            <Text style={styles.header2}>Satellitenansicht</Text>
            <View style={styles.centered}>
                <PDFMap options={{
                    style: 'mapbox://styles/mapbox/satellite-streets-v9', lat: resource.data.building.minLat, lon: resource.data.building.minLon,
                }}>
                    <PDFMapLayer options={{ color: 'rgba(255,0,0,1)', fillColor: 'rgba(255,0,0,0.1)' }}>
                        {resource.data.buildings.map((building: any) => <PDFMapBuilding building={building}></PDFMapBuilding>)}
                    </PDFMapLayer>
                    <PDFMapLayer options={{ color: 'rgba(0,0,0,1)' }}>
                        <PDFMapBuilding building={resource.data.building}></PDFMapBuilding>
                    </PDFMapLayer>
                </PDFMap>
            </View>
            <Text style={styles.header2}>GRS</Text>
            <View style={styles.centered}>
                <PDFMap options={{ lat: resource.data.building.minLat, lon: resource.data.building.minLon, zoom: 14 }}>
                    <PDFMapLayer options={{ color: 'rgba(255,0,0,1)', fillColor: 'rgba(255,0,0,0.1)' }}>
                        {resource.data.buildings.map((building: any) => <PDFMapBuilding building={building}></PDFMapBuilding>)}
                    </PDFMapLayer>
                    <PDFMapLayer options={{ color: 'rgba(0,0,0,1)' }}>
                        <PDFMapBuilding building={resource.data.building}></PDFMapBuilding>
                    </PDFMapLayer>
                </PDFMap>
            </View>
            <Text style={styles.header2}>Umgebung</Text>
            <View style={styles.centered}>
                <PDFMap options={{ lat: resource.data.building.minLat, lon: resource.data.building.minLon, zoom: 14 }}>
                    <PDFMapLayer options={{ color: 'rgba(255,0,0,1)', fillColor: 'rgba(255,0,0,0.1)' }}>
                        {resource.data.buildings.map((building: any) => <PDFMapBuilding building={building}></PDFMapBuilding>)}
                    </PDFMapLayer>
                    <PDFMapLayer options={{ color: 'rgba(0,0,0,1)' }}>
                        <PDFMapBuilding building={resource.data.building}></PDFMapBuilding>
                    </PDFMapLayer>
                    {/* <PDFMapPoints points={[{
                        lat: resource.data.building.minLat,
                        lon: resource.data.building.minLon,
                        normal: 33,
                        maintenance: 2,
                        warning: 2,
                        critical: 1
                    }]}></PDFMapPoints> */}
                </PDFMap>
            </View>
            <Text style={styles.header1}>Quellen</Text>
            <Text style={styles.header2}>Daten der Bundesländer, Kreise und Gemeinden</Text>
            <Text style={styles.header3}>Lizenz: Vervielfältigung und Verbreitung mit Quellenangabe</Text>
            <Text style={styles.paragraph}>© Herausgebergemeinschaft Statistische Ämter des Bundes und der Länder (Destatis) 2023: Alle politisch selbständigen Gemeinden mit
                ausgewählten Merkmalen am 31.12.2022, URL</Text>
            <Text style={styles.header3}>Lizenz: dl-de/by-2-0</Text>
            <Text style={styles.paragraph}>© GeoBasis-DE / BKG 2023 (Daten verändert): Verwaltungsgebiete 1:5 000 000, Stand 01.01. (VG5000 01.01.), URL</Text>
            <Text style={styles.header2}>Baden-Württemberg</Text>
            <Text style={styles.header3}>Lizenz: dl-de/by-2-0</Text>
            <Text style={styles.paragraph}>LGL-BW 2024, www.lgl-bw.de: ALKIS NOrA, URL
                LGL-BW 2024, www.lgl-bw.de: 3D-Gebäudemodell LoD2, URL</Text>
            <Text style={styles.header2}>Berlin</Text>
            <Text style={styles.header3}>Lizenz: dl-de/by-2-0</Text>
            <Text style={styles.paragraph}>Geoportal Berlin Airborne Laserscanning (ALS) Primäre 3D Laserscan-Daten 2023, URL
                Geoportal Berlin: ALKIS Berlin Flurstücke 2023, URL
                Geoportal Berlin: ALKIS Berlin Gebäude 2023, URL
                Geoportal Berlin: ALKIS Berlin Tatsächliche Nutzung 2023, URL
                Geoportal Berlin: Adressen Berlin 2023, URL
                Geoportal Berlin: 3D-Gebäudemodell im Level of Detail 2 2023, URL</Text>
            <Text style={styles.header2}>Brandenburg</Text>
            <Text style={styles.header3}>Lizenz: dl-de/by-2-0</Text>
            <Text style={styles.paragraph}>© GeoBasis-DE / LGB: ALKIS Brandenburg Vereinfacht, URL
                © GeoBasis-DE / LGB: ALKIS Brandenburg NAS-konform, URL
                © GeoBasis-DE / LGB: 3D-Gebäude Land Brandenburg, URL
                © GeoBasis-DE / LGB: Laserscandaten Brandenburg, URL</Text>
            <Text style={styles.header1}>Glossar</Text>
            <Text style={styles.header2}>Bestandsfläche</Text>
            <Text style={styles.header3}>BGF</Text>
            <Text style={styles.paragraph}>
                Tragen Sie hier die Brutto-Grundfläche (BGF) aller bestehenden Hauptanlagen auf dem Grundstück ein. Nebenanlagen wie z.B.
                Garagen sind hier abzuziehen. Aus diesem Wert wird über einen Faktor die Mietfläche geschätzt.
                The Real Insight ermittelt diesen Wert automatisch aus dem 3D-Volumen bei Kalkulationen, die mit einem Grundstück in The Real Insight
                verknüpft sind.</Text>
            <Text style={styles.header3}>Grundfläche</Text>
            <Text style={styles.paragraph}>Tragen Sie hier die Grundfläche aller bestehenden Hauptanlagen auf dem Grundstück ein. Nebenanlagen wie z.B. Garagen sind
                hier abzuziehen.</Text>
            <Text style={styles.paragraph}>The Real Insight ermittelt diesen Wert automatisch aus dem amtlichen Liegenschaftskataster bei Kalkulationen, die mit einem
                Grundstück in The Real Insight verknüpft sind.</Text>
            <Text style={styles.header3}>GRZ 1</Text>
            <Text style={styles.paragraph}>Die Grundflächenzahl (GRZ) gibt an, wieviel von dem Grundstück aktuell durch Baumasse überdeckt wird und berechnet sich
                als Quotient aus BGF und Grundstücksfläche.
                Da sich die BGF nur auf Hauptanlagen bezieht, handelt es sich hierbei um die GRZ 1. Die GRZ 1 berechnet sich automatisch aus
                Grundfläche / Grundstücksfläche.
                Volumen
                Tragen Sie hier das Volumen aller bestehenden Gebäude inklusive Haupt- und Nebenanlagen über einen BGF-Faktor ein. Aus
                diesem Wert werden die Kosten für den Rückbau berechnet.
                The Real Insight ermittelt diesen Wert automatisch aus dem 3D-Volumen bei Kalkulationen, die mit einem Grundstück in The Real Insight
                verknüpft sind.
                Mietfläche
                Die Mietfläche des Bestands berechnet sich über einen BGF-Faktor.
                Für Wohngebäude ist ein konservativer sinnvoller Faktor 0,72.
                Für Bürogebäude liegt der Faktor bei etwa 0,84.
                The Real Insight nimmt bei Kalkulationen, die mit einem Grundstück in The Real Insight verknüpft sind, immer einen Faktor von 0,72 an.</Text>
            <Text style={styles.header3}>Baujahr</Text>
            <Text style={styles.paragraph}>Geben Sie hier das Baujahr oder das Datum der letzten Kernsanierung an.
                Das Baujahr wird genutzt, um den Zustand und damit die Miet- und Verkaufspreise zu schätzen. Außerdem wird das Baujahr
                genutzt, um die Fördermittel zu berechnen.
                The Real Insight schätzt das Baujahr bei Kalkulationen, die mit einem Grundstück in The Real Insight verknüpft sind. Sanierungen kann die KI
                aktuell noch nicht erkennen. überprüfen Sie diesen Wert und korrigieren ihn ggf.</Text>
            <Text style={styles.header3}>Neubaufläche</Text>
            <Text style={styles.header3}>BGF</Text>
            <Text style={styles.paragraph}>Tragen Sie hier die Brutto-Grundfläche (BGF) aller neuen Hauptanlagen auf dem Grundstück ein.
                Nebenanlagen wie z.B. Garagen sind hier abzuziehen. Aus diesem Wert wird über einen Faktor die neue Mietfläche geschätzt.
                Im Fall Nachverdichtung ist nur die zusätzliche, neu entstehende BGF anzugeben.
                The Real Insight ermittelt diesen Wert automatisch aus der Schätzung des Bebauungspotenzials bei Kalkulationen, die mit einem
                Grundstück in The Real Insight verknüpft sind.</Text>
            <Text style={styles.header3}></Text>Grundfläche
            <Text style={styles.paragraph}>Tragen Sie hier die Grundfläche aller neuen Hauptanlagen auf dem Grundstück ein.
                Nebenanlagen wie z.B. Garagen sind hier abzuziehen. Im Fall Nachverdichtung ist nur die zusätzliche, neu entstehende
                Grundfläche anzugeben.
                The Real Insight ermittelt diesen Wert automatisch aus der Schätzung des Bebauungspotenzials bei Kalkulationen, die mit einem
                Grundstück in The Real Insight verknüpft sind.</Text>
            <Text style={styles.header3}>Volumen</Text>
            <Text style={styles.paragraph}>ragen Sie hier das Volumen aller neuen Gebäude über einen BFG-Faktor ein.
                The Real Insight ermittelt diesen Wert automatisch aus der Schätzung des Bebauungspotenzials bei Kalkulationen, die mit einem
                Grundstück in The Real Insight verknüpft sind.</Text>
            <Text style={styles.header3}>Mietfläche</Text>
            <Text style={styles.paragraph}>Die neue Mietfläche berechnet sich über einen Faktor auf die Neubau-BGF.
                Für Wohngebäude ist ein konservativer sinnvoller Faktor 0,72.
                Für Bürogebäude liegt der Faktor bei etwa 0,84.
                The Real Insight nimmt bei Kalkulationen, die mit einem Grundstück in The Real Insight verknüpft sind, immer einen Faktor von 0,72 an.
                <Text style={styles.header3}>Stellplätze</Text>
                Tragen Sie hier alle oberirdischen Stellplätze ein, die benötigt werden.
                Diese werden sowohl in den Kosten berücksichtigt, als auch in der Vermietung. The Real Insight gibt Ihnen einige Hilfestellungen in Form
                von Warnungen an.
                Hierbei werden folgende Regelungen angewendet, die von der tatsächlichen Stellplatz-Regelungen abweichen können:</Text>
            <View>
                <PDFListItem>Angenommene Stellplatzgröße: 30m²</PDFListItem>
                <PDFListItem>1/2 Stellplatz je Mieteinheit mit bis zu 50m² Mietfläche</PDFListItem>
                <PDFListItem>1 Stellplatz je Mieteinheit mit bis zu 120m² Mietfläche</PDFListItem>
                <PDFListItem>2 Stellplätze je Mieteinheit mit über 120m² Mietfläche</PDFListItem>
            </View>
            <Text style={styles.paragraph}>Empfohlene maximale oberirdische Bebauung (Versiegelung) von Gebäuden und Stellplätzen: 60%*
                The Real Insight nimmt bei verknüpften Kalkulation obigen Regeln an, um die Anzahl der Stellplätze zu schätzen.
                * Sollte sich das Grundstück in einem Kerngebiet befinden, kann die Grenze von 60% ggf. überschritten werden. Dies muss von
                Ihnen aber selbst geprüft werden und wird von The Real Insight nicht automatisch angewendet.</Text>
            <Text style={styles.header3}>Tiefgaragenplätze</Text>
            <Text style={styles.paragraph}>Tragen Sie hier alle unterirdischen Stellplätze ein, die benötigt werden. Diese werden sowohl in den Kosten berücksichtigt, als
                auch in der Vermietung.
                The Real Insight gibt Ihnen einige Hilfestellungen in Form von Warnungen an.
                Hierbei werden folgende Regelungen angewendet, die von der tatsächlichen Stellplatz-Regelungen abweichen können:
                Angenommene Stellplatzgröße: 30 m²
                1/2 Stellplatz je Mieteinheit mit bis zu 50 m² Mietfläche
                1 Stellplatz je Mieteinheit mit bis zu 120 m² Mietfläche
                2 Stellplätze je Mieteinheit mit über 120 m² Mietfläche
                The Real Insight nimmt bei Kalkulationen, die mit einem Grundstück in The Real Insight verknüpft sind, obigen Regeln an, um die Anzahl der
                Stellplätze zu schätzen.
                Im Falle von Nachverdichtung werden keine Tiefgaragenplätze vorgeschlagen, da es schwierig sein kann, diese unter dem
                Bestand zu errichten.</Text>
            <Text style={styles.header2}>Kosten</Text>
            <Text style={styles.header3}>Grundstückskosten</Text>
            <Text style={styles.paragraph}>Hier sind die Grundstückskosten für den Ankauf einzutragen. Bei Kalkulationen, die mit einem Grundstück in The Real Insight verknüpft
                sind, wird hier der aktuelle Marktpreis automatisch vorausgefüllt. Im Gegenzug dazu wird die am Ende ausgewiesene
                Kostenprognose Grundstück durch den Residualwert berechnet und gibt den maximal möglichen Kaufpreis an, bis zu dem sich
                das Grundstück mit Zielrendite profitabel entwickeln lässt.
                Die automatisch ermittelten Marktpreise beziehen wir von unserem Partner PriceHubble. Diese werden ermittelt für eine
                Eigentumswohnung mit der oben angegebenen Wohnungsgröße (Mietfläche).
                Dabei wird eine normale Ausstattung für Bad, Küche, Bodenbelag und Fenster angenommen.
                Im Bestand wird ein gemäß des Gebäudealters gut gepflegter Zustand angenommen.
                Die sanierte Fläche wird als neuwertig saniert angenommen.
                Nebenkosten Grundstück
                Initial enthalten in den Grundstücksnebenkosten (Änderungen in der App werden nicht übernommen)
                Grunderwerbssteuer* 6,5 %
                Notar- und Gerichtskosten 1,0 %
                Grundbucheintrag 0,5 %
                *abhängig vom Bundesland</Text>
            <Text style={styles.header3}>Baunebenkosten</Text>
            <Text style={styles.paragraph}>Initial enthalten in den Baunebenkosten (Änderungen in der App werden nicht übernommen)
                Bauherrenaufgaben 5,0 %
                Projektsteuerung 3,0 %
                Architekten & Ingenieure 15,0 %
                Genehmigungsgebühren 1,5 %
                Gutachten, Rechts- & Steuerberatung 1,0 %
                Sonstiges 0,5 %</Text>
            <Text style={styles.header2}>Mieterträge</Text>
            <Text style={styles.header3}>Nettokaltmiete Bestand</Text>
            <Text style={styles.paragraph}>Hier ist die Nettokaltmiete für den Bestand einzutragen (Achtung, auch für Wohnen die Preise unbedingt Netto angeben).
                In der Spalte “Nachverdichtung und Sanierung” ist die Nettokaltmiete für den sanierten Bestand anzugeben.
                Die automatisch ermittelten Preise beziehen wir von unserem Partner PriceHubble. Diese werden ermittelt für eine 70 m²
                Eigentumswohnung.
                Dabei wird eine normale Ausstattung für Bad, Küche, Bodenbelag und Fenster angenommen. Im Bestand wird ein gemäß des
                Gebäudealters gut gepflegter Zustand angenommen.
                Die sanierte Fläche wird als neuwertig saniert angenommen.
                Nettokaltmiete Neubau
                Hier ist die Nettokaltmiete für den Neubau einzutragen (Achtung, auch für Wohnen die Preise unbedingt in netto angeben).
                In der Spalte “Nachverdichtung und Sanierung” ist die Nettokaltmiete für die Nachverdichtung oder Aufstockung anzugeben,
                nicht für den sanierten Bestand.
                Die automatisch ermittelten Preise beziehen wir von unserem Partner PriceHubble. Diese werden ermittelt für eine neue
                Eigentumswohnung mit der oben angegebenen Wohnungsgröße (Mietfläche).
                Dabei wird eine normale Ausstattung für Bad, Küche, Bodenbelag und Fenster angenommen.</Text>
            <Text style={styles.header2}>Förderungen</Text>
            <Text style={styles.header3}>Zielnutzung</Text>
            <Text style={styles.paragraph}>Die Zielnutzung Gewerbe hat andere Förderkonditionen als Wohnen.
                Energieeffizienzhaus (KfW 40 EE/NH)
                Die KfW-Förderungen für die Sanierung von Bestandsgebäuden unterscheiden sich je nach Nutzung.
                Wohnen - Haus und Wohnung energieeffizient sanieren
                Gefördert wird durch den KfW Kredit 261 die Sanierung zum Effizienzhauses durch einen zinsgünstigen Kredit mit
                Tilgungszuschuss.
                In dieser Kalkulation wird davon ausgegangen, dass die Sanierung zum Effizienzhaus 40 Erneuerbare-Energien-Klasse führt, für
                das der höchste Tilgungszuschuss von 25% von maximal 150.000€ Sanierungskosten je Wohneinheit gilt.
                Details zum Förderprogramm finden Sie auf der Website der KfW.
                Gewerbe - Nichtwohngebäude energieeffizient sanieren
                Gefördert wird durch den KfW Kredit 263 die Sanierung zum Effizienzgebäude durch einen zinsgünstigen Kredit mit
                Tilgungszuschuss.
                In dieser Kalkulation wird davon ausgegangen, dass die Sanierung zum Effizienzgebäude 40 Erneuerbare-Energien-Klasse oder
                Nachhaltigkeits-Klasse führt, für das der höchste Tilgungszuschuss von 25% der förderfähigen Kosten gilt.
                Die förderfähigen Kosten belaufen sich auf 2.000 Euro pro Quadratmeter Nettogrundfläche (die The Real Insight mit 85% der BGF
                schätzt) - insgesamt maximal 10 Mio. Euro pro Vorhaben.
                Details zum Förderprogramm finden Sie auf der Website der KfW.
                Zinsvergünstigungen werden aktuell nicht berücksichtigt.
                Worst performing building
                Als WPB gelten sowohl Wohn- als auch Nichtwohngebäude, die hinsichtlich des energetischen Sanierungszustands zu den
                schlechtesten 25 % der Gebäude in Deutschland gehören. Wenn das Gebäude 1957 oder früher gebaut wurde, rechnet The Real Insight
                den WPB-Bonus mit ein. Bitte beachten Sie, dass mindestens 75% der Außenfläche dabei nicht saniert sein dürfen.
                Der Worst performing building Bonus liefert einen zusätzlichen Tilgungszuschuss von 10%. Wird außerdem der Bonus für die
                serielle Sanierung gewährt, reduziert sich der Bonus für die serielle Sanierung auf 10%.
                Serielle Sanierung
                Als serielle Sanierungen werden energetische Gebäudesanierungen bezeichnet, die mithilfe von modular vorgefertigten
                Elementen durchgeführt werden.
                Das können Dämmelemente für Fassaden und Dächer sein – aber auch Teile der Anlagentechnik wie zum Beispiel
                Wärmepumpenmodule.
                Diese Elemente können im Vergleich zu herkömmlichen Baumaterialien vor Ort mit deutlich reduziertem zeitlichen Aufwand
                montiert werden.
                Das Wort „Seriell“ bezieht sich hier also nicht auf die Sanierung selbst, sondern auf das Herstellungsverfahren der
                verwendeten Materialien.
                Der Bonus Serielles Sanieren gilt nur für Wohngebäude und liefert einen zusätzlichen Tilgungszuschuss von 15% von maximal
                150.000€ Sanierungskosten je Wohneinheit.
                Wird zudem der Worst Performing Building Bonus gewährt, reduziert sich der Bonus für die serielle Sanierung auf 10%.
            </Text>
            <Text style={styles.header2}>Residualwert</Text>
            <Text style={styles.header3}>Verkaufspreis</Text>
            <Text style={styles.paragraph}>Hier ist der Netto Verkaufspreis einzutragen (Achtung, auch für Wohnen die Preise unbedingt Netto angeben).
                In der Spalte “Nachverdichtung und Sanierung” ist eine Mischkalkulation für die sanierte Fläche und die Nachverdichtung bzw.
                die Aufstockung anzusetzen.
                Die automatisch ermittelten Preise beziehen wir von unserem Partner PriceHubble. Diese werden ermittelt für eine 70 m²
                Eigentumswohnung.
                Dabei wird eine normale Ausstattung für Bad, Küche, Bodenbelag und Fenster angenommen.
                Im Bestand wird ein gemäß des Gebäudealters gut gepflegter Zustand angenommen.
                Die sanierte Fläche wird als neuwertig saniert angenommen.
                Verkaufspreis Stellplätze
                Der voreingestellte Verkaufspreis ist deutschlandweit einheitlich und nicht auf die lokale Marktsituation oder Lage angepasst.</Text>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
        </Page>
    </Document >
};

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
});

// @ts-ignore
export const ReportResource = ({ resource }) => {
    const styles = useStyles();
    const [settings] = useAtom(settingsAtom);
    const [expanded, setExpanded] = useState(false);
    const [report, setReport] = useState(true) as any;
    const [authentication] = useAtom(authenticationAtom);

    useEffect(() => {
        switch (resource.report) {
            case 'FireExtinguisherCompanyReport':
                setReport(<FireExtinguisherCompanyReport authentication={authentication} resource={resource} settings={settings} />);
                break;
            case 'FireExtinguisherReport': setReport(<FireExtinguisherReport authentication={authentication} resource={resource} settings={settings} />);
                break;
            case 'EnergyEfficiencyReport': setReport(<EnergyEfficiencyReport authentication={authentication} resource={resource} settings={settings} />);
                break;
            case 'CoolantReport':
            default: setReport(<CoolantReport authentication={authentication} resource={resource} settings={settings} />);
                break;
        }
    }, [resource, settings]);

    return <div className={styles.list}>
        <div className="displayFlex alignItemsCenter">
            <IconButton className="marginRightM" iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} onClick={() => setExpanded(!expanded)} />
            <FontIcon aria-label="Map" iconName="ReportDocument" className={iconClass} />
            <div className="marginLeftS flexGrow1 displayFlex textL fontWeightBold">{resource.title}</div>
            <PDFDownloadLink document={report} fileName="Feuerlöscherbedarfe Frankfurt.pdf">
                {({ blob, url, loading, error }) =>
                    loading
                        ?
                        'Loading document...'
                        :
                        <ActionButton iconProps={{ iconName: 'Download' }} allowDisabledFocus>
                            Download Report
                        </ActionButton>
                }
            </PDFDownloadLink>
        </div>
        {expanded
            ?
            <div className="marginTopL displayFlex justifyContentCenter">
                <PDFViewer width={600} height={1200}>
                    {report}
                </PDFViewer>
            </div>
            :
            <></>
        }
    </div>
}
