import { Text, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface Properties {
    open: boolean;
    setOpen: any,
    title: string;
    message: string;
    checkString?: string;
    onOK?: any;
    onCancel?: any;
}

export default function ConfirmationDialog(properties: Properties) {
    const { t } = useTranslation();
    const [confirmString, setConfirmString] = useState() as any;

    return (
        <Dialog
            hidden={!properties.open}
            onDismiss={() => {
                console.log('Enter dismiss');

                if (properties.onCancel) {
                    properties.onCancel();
                }

                properties.setOpen(false);
            }}
            maxWidth={500}
            minWidth={300}
            dialogContentProps={{
                type: DialogType.normal,
                title: properties.title,
            }}
            modalProps={{
                isBlocking: true,
            }}>
            <div className="paddingS">
                <div>
                    <Text variant="medium">{properties.message}</Text>
                </div>
                {properties.checkString
                    ?
                    <div className="marginTopL displayFlex">
                        <TextField value={confirmString} onChange={(e: any, value: any) => setConfirmString(value)} />
                    </div>
                    :
                    <></>}
            </div>
            <DialogFooter className="marginTopL">
                {properties.checkString
                    ?
                    <PrimaryButton style={{ backgroundColor: 'red', borderColor: 'red' }} disabled={properties.checkString !== confirmString} text="Delete" onClick={() => {
                        if (properties.onOK) {
                            properties.onOK();
                        }

                        properties.setOpen(false);
                    }} />
                    :
                    <PrimaryButton disabled={properties.checkString !== confirmString} text={t('global.apply')} onClick={() => {
                        if (properties.onOK) {
                            properties.onOK();
                        }

                        properties.setOpen(false);
                    }} />
                }
                <DefaultButton text={t('global.cancel')} onClick={() => {
                    if (properties.onCancel) {
                        properties.onCancel();
                    }

                    properties.setOpen(false);
                }} />
            </DialogFooter>
        </Dialog>
    );
}